import React, { Component } from "react";
import { cache } from '../../libs';

class QuestionText extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            choice: [],
            idQuiz: this.props.idQuiz,
            textareaDefVal:'',
            validate:{
                isPaste: false, //true is allowed
                maxChar: 2000, //zero (0) to no limits
                currChar: 0 //init 
            }
        };
    }

    //--- ---

    componentDidMount(){
        const textareaDefVal = this.isChecked(this.state.idQuiz, this.props.idQuestion);
        this.setState({
            textareaDefVal
        })
    }

    /** */
    //--- check if exists local value ---
    isChecked = (idQuiz, idQuestion) =>{
        let getValue = cache.get(`quiz-${idQuiz}-${idQuestion}`);
        let result = '';

        if(getValue !== null){
            let valAnswer = JSON.parse(JSON.stringify(getValue));
            setTimeout(() => {
                this.setMyState(idQuestion, valAnswer[0].label)                    
            }, 150); 
            result = valAnswer[0].label;
        }

        return result;
    }//isChecked

    /** */
    handleOnPaste = (e) =>{
        if(!this.state.validate.isPaste){
            e.preventDefault();
            return false;
        }
    }//handleOnPaste


    /** */
    setMyState = (idQuestion, answer) => {
        let data = {
            idQuestion: idQuestion,
            label: answer
        }

        //--- validate textarea content ---
        if(((this.state.validate.currChar >= this.state.validate.maxChar) || 
            (answer.length >= this.state.validate.maxChar)) 
            && this.state.validate.maxChar > 0){
            return false;
        }

        if(answer.length !== this.state.validate.currChar){
            this.setState({
                validate:{
                    ...this.state.validate,
                    currChar: answer.length
                }
            })

            this.setState(state => {    
                state.choice.pop(); 
                state.choice.push(data);
        
                //--- manage localStorage answer ---
                if(this.state.choice.length === 0){
                    cache.forget(`quiz-${this.state.idQuiz}-${idQuestion}`,this.state.choice);
                }else{
                    cache.set(`quiz-${this.state.idQuiz}-${idQuestion}`,this.state.choice);
                }
            });
        }
    }//setMyState


    render() {

        if(!this.state.type) return false;

        return (
            <div>
                <textarea 
                    id={`t${this.props.idQuestion}`}
                    name={`t${this.props.idQuestion}`}
                    rows="4"
                    maxLength={this.state.validate.maxChar}
                    onKeyUp={(e) => { this.setMyState(this.props.idQuestion, e.target.value); }}  
                    onPaste={(e) => { this.handleOnPaste(e) }}
                    defaultValue={this.state.textareaDefVal}
                ></textarea>
                <label htmlFor={`t${this.props.idQuestion}`}>Risposta aperta</label>
                {(this.state.validate.maxChar > 0) && (
                    <div>
                        <small>puoi scrivere un testo di massimo {this.state.validate.maxChar} caratteri. Ti rimangono ancora {this.state.validate.maxChar-this.state.validate.currChar} caratteri.</small>
                    </div>
                )}

                {(this.state.validate.currChar >= this.state.validate.maxChar && this.state.validate.maxChar > 0) && (
                    <p className="text-danger">Attenzione. Hai superato i {this.state.validate.maxChar} caratteri consentiti</p>
                )}
            </div>
        );
    }
}

export default QuestionText;