import {proxyBE} from './common';

export const userService = {
    
    register({ name, surname, email, password, statCookie }) {
        return proxyBE.post('register', { name, surname, email, password, statCookie });
    },

    login({ email, password }) {
        return proxyBE.post('login', { email, password });
    },

    loginByUserId(userId) {        
        return proxyBE.post('loginByUserId', { userId });
    },

    checkLogin(email, password ) {        
        return proxyBE.put('check-login', { email, password });
    },

    checkEmail(email) {        
        return proxyBE.put('check-email', { email});
    },

    checkCourseConstraints(email, courseCode, currentAA){
        return proxyBE.put('check-course-constraint', { email, courseCode, currentAA});
    },

    confirmEmail({key}){
        return proxyBE.post('me/confirm', { key });
    },
    
    resendEmail(){
        return proxyBE.post('me/confirm/resend');
    },
    
    setuserDuration(key){
        return proxyBE.post('learning/setDuration', key);
    },

    keepAlive(url, info,  registrationCode ) {        
        return proxyBE.put('keepalive', {url,  registrationCode, info });
    },

    me() {
        return proxyBE.get('me');
    },

    update(payload) {
        return proxyBE.put('me', payload);
    },

    profile() {
        return proxyBE.get('my/profile');
    },

    updateProfile(payload) {    
        return proxyBE.put('my/profile', payload);
    },

    addresses() {
        return proxyBE.get('my/profile/addresses');
    },

    address(type) {
        return proxyBE.get('my/profile/address/'+type);
    },

    updateAddress(type, address) {
        return proxyBE.put('my/profile/address', { type, ...address });
    },

    document() {
        return proxyBE.get('my/profile/document');
    },

    updateDocument(document) {
        return proxyBE.put('my/profile/document', { ...document });
    },

    qualifications() {
        return proxyBE.get('my/profile/qualifications');
    },

    insertQualification(payload) {
        return proxyBE.post('my/profile/qualifications', payload);
    },

    updateQualification(payload) {
        return proxyBE.put('my/profile/qualifications', payload);
    },

    noticeAlerts() {
        return proxyBE.get('my/profile/noticeAlerts');
    },

    invoiceData() {
        return proxyBE.get('my/invoice-data');
    },

    updateInvoiceData(payload) {
        return proxyBE.put('my/invoice-data', payload);
    },

    deleteInvoiceData() {
        return proxyBE.delete('my/invoice-data');
    },

    createInvoiceData(){
        return proxyBE.post('my/invoice-data');
    },

    resetPassword(payload) {
        return proxyBE.post('my/password-reset', payload);
    },

    resetPasswordByToken(payload) {
        return proxyBE.post('my/password-reset-token', payload);
    },

    resetPasswordOnLogged(payload) {
        return proxyBE.post('my/password-reset-untoken', payload);
    },

    manageUserTarget(payload) {
        return proxyBE.put('my/profile/usertarget', payload);
    },

    getUserTarget() {
        return proxyBE.get('my/profile/usertarget');
    },

    verifyIntegrityrCode(rCode) {
        return proxyBE.get('my/course/'+rCode);
    },
}