import React, { Component } from "react";


export class ReCaptchaV3 extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            siteKey: this.props.siteKey
        }
    }

    componentDidMount(){ 
        this.initReCaptcha();
    }

    /*TODO: calogero verificare come utilizzare il valore di siteKey
    passato al componente come props*/
    
    initReCaptcha = () =>{
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=load&onload=onRecaptchaLoadCallback&hl=it";
        script.async = true;
        document.body.appendChild(script);

        const onRecaptchaLoadCallback = () => {            
            window.grecaptcha.render(this.props.id, {
                sitekey: '6Lf2goEaAAAAAM2kL4zGAlaPGZYlBAocn9CIxIy0',
                badge: 'inline',
                theme : 'dark',
                callback : this.verifyCallback
            });
        }
        
        window.onRecaptchaLoadCallback = onRecaptchaLoadCallback;
    }//initReCaptcha
    
    verifyCallback = (result) =>{
        this.props.reCaptchaCallback(result);
    }//verifyCallback
    

    //--- ---

    render() {

        return (
            <>
                <div id={this.props.id}></div>
            </>
        );
    }

}