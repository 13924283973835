
export class CourseHelper {

    static isFree(course) {

        let result = false;
        if (course && course.courseType) {
            if (course.courseType.includes('FREE')) {
                result = true;
            }
        }

        return result;
    }

    static isCFU(courseCode){
        return (courseCode === 'CS24CFU' || courseCode === 'CSU24CFU' || courseCode === 'PFU5CFU')
    }

    static isUnicamillus(courseCode) {

        // TODO: aggiugnere un campo nella tabella course.company
        return (courseCode.startsWith('MU') || courseCode.startsWith('MDU')
            || courseCode.startsWith('MP') || courseCode.startsWith('BU')
            || courseCode.startsWith('CSU')    || courseCode.startsWith('PDU')  
            || (courseCode.startsWith('PCU') && !courseCode.startsWith('PCUM') )
            || (courseCode.startsWith('PLU') && !courseCode.startsWith('PLUM') )
            || courseCode.startsWith('UPD') || courseCode.startsWith('UPL')
            || courseCode.startsWith('PFU'));

    }

    static isDSII(courseCode) {

        let result = false;
        if (courseCode) {
            if (courseCode.startsWith('DS')) {
                let aa = process.env.REACT_APP_AA_YEAR;
                aa--;
                let ends = aa.toString().slice(2, 4)
                if (courseCode.endsWith(ends)) {
                    result = true;
                }
            }
        }

        return result;
    }

    static isBUII(courseCode) {

        let result = false;
        if (courseCode) {
            if (courseCode.startsWith('BU')) {
                let aa = process.env.REACT_APP_AA_YEAR;
                aa--;
                let ends = aa.toString().slice(2, 4)
                if (courseCode.endsWith(ends)) {
                    result = true;
                }
            }
        }

        return result;
    }

    /** */
    static calcDurationByLog(dataActivityLog) {
        let sumDiffTotal = 0;
        let sumDiffPartial = 0;
        let sDate = '';
        let eDate = '';
        let delta = 60 * 10;

        dataActivityLog.forEach((item) => {
            if (sDate === '' && eDate === '') { sDate = item.time; }
            if (sDate !== '' && eDate === '') { eDate = item.time; }

            if (sDate !== '' && eDate !== '') {
                let timeDifference = eDate - sDate;

                if (timeDifference <= delta) {
                    sumDiffPartial += timeDifference
                }
                else {
                    sumDiffTotal += sumDiffPartial;
                    sumDiffPartial = 0;
                }

                sDate = eDate;
                eDate = '';
            }
        });

        return sumDiffTotal;
    }//calcDurationByLog
}