import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import { courseService, dateHelper, networkErrorHelper } from "libs";
import { BoxDanger, MnemoLoading, SupportoTecnico } from "components/misc";
import { CourseShopper } from "components/enrollment";
import { Helmet } from "react-helmet";
import { CourseLandingPageGetRates } from "./CourseLandingPageGetRates";

class CourseFullLayoutPage extends Component {

    state = {
        courseCode: 0,
        courseModules: [],
        course: 0,
        loading: true,
        loadingError: false
    };

    tableConfig = {
        columns: [
            {
                id: 'title',
                Header: 'Titolo modulo',
                accessor: 'title',
            },
            {
                id: 'ssd',
                Header: 'SSD',
                accessor: 'ssd',
            },
            {
                id: 'cfu',
                Header: 'CFU',
                accessor: 'cfu',
            },
        ],
    }

    componentDidMount() {
        // get registration code from parameter
        let { courseCode } = this.props.match.params;
        courseCode = this.chekCourse(courseCode);
        this.setState({ courseCode });
        this.loadRemote(courseCode);
    }

    chekCourse(courseCode) {

        const currentAA = parseInt(process.env.REACT_APP_AA_YEAR.slice(-2));
        // get last two chars
        const courseYear = parseInt(courseCode.slice(-2));

        // verify if a number
        if (courseYear > 0) {

            if (courseYear < currentAA) {
                courseCode = courseCode.slice(0, -2) + currentAA.toString();
            }
        }

        return courseCode;
    }


    loadRemote(courseCode) {

        courseService.getCourseModules(courseCode)
            .then(({ data }) => {
                this.setState({
                    courseModules: data.payload,
                });
            })
            .catch(error => {
                console.log(error);
            });


        const currentAA = parseInt(process.env.REACT_APP_AA_YEAR);

        courseService
            .getByCodeAndAA(courseCode, currentAA)
            .then(({ data }) => {
                this.setState({
                    courseCode,
                    course: data.payload.course,
                    loading: false,
                    loadingError: false
                });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                } else {
                    this.setState({ loadingError: true });
                }

                this.setState({ loading: false });
            });
    }

    render() {

        const { loading, loadingError, course, courseCode } = this.state;

        if (loading) return (<MnemoLoading></MnemoLoading>);
        if (loadingError) return (<BoxDanger>Si è verificato un errore durante
            il caricamento dei dati del corso, se il problema persiste puoi <SupportoTecnico /></BoxDanger>);

        const furtherInfo = ReactHtmlParser(course.furtherInfo);
        const examInfo = ReactHtmlParser(course.examInfo);
        const enrollDeadline = dateHelper.toITDate(course.enrollDeadline);

        const { isLoggedIn } = this.props;

        return (
            <>
                <Helmet>
                    <title>{course.title}</title>
                    <meta property="og:title" content={course.title} />
                </Helmet>
                <h3 className="p-3">{ReactHtmlParser(course.title)} ({course.code})</h3>
                <hr />
                {(course.enrollEnabled) &&
                    (
                        <>
                            <div className="enroll-btn p-3">
                                <h3><a href="#enroll_section">Clicca qui per iscriverti</a></h3>
                            </div>
                        </>
                    )}
                <section className="course_section">
                  
                    <>
                        <div className="layout-2col">
                            <div className="layout-2col__content">
                                {furtherInfo}      
                                {examInfo}                          
                            </div>
                            <div className="layout-2col__sidebar">
                                <div className="layout-2col__sidebar-content">
                                    <div clasName="layout-2col__sidebar-items">
                                        <div className="layout-2col__sidebar-item">
                                            <div className="layout-2col__sidebar-item__label">
                                                <img
                                                    src="/img/icon/svg/iconAcademy.svg"
                                                    width={24}
                                                    height={24}
                                                    alt="Mnemosine"
                                                />
                                                <span>
                                                    CODICE CORSO
                                                </span>
                                            </div>
                                            <div className="layout-2col__sidebar-item__value">
                                                {course.code}
                                            </div>
                                        </div>
                                        <div className="layout-2col__sidebar-item">
                                            <div className="layout-2col__sidebar-item__label">
                                                <img
                                                    src="/img/icon/svg/iconEuro.svg"
                                                    width={24}
                                                    height={24}
                                                    alt="Mnemosine"
                                                />
                                                <span>
                                                    COSTO E RATEIZZAZIONE
                                                </span>
                                            </div>
                                            <div className="layout-2col__sidebar-item__value">
                                                <p>Da Euro 390</p>
                                                <p>Prima Rata di Euro 200 (Duecento/00)</p>
                                                <p>Saldo entro trenta giorni dall’immatricolazione </p>
                                            </div>
                                        </div>
                                        <div className="layout-2col__sidebar-item">
                                            <div className="layout-2col__sidebar-item__label">
                                                <img
                                                    src="/img/icon/svg/iconCalendar.svg"
                                                    width={24}
                                                    height={24}
                                                    alt="Mnemosine"
                                                />
                                                <span>
                                                    SCADENZA ISCRIZIONI
                                                </span>
                                            </div>
                                            <div className="layout-2col__sidebar-item__value">
                                            {enrollDeadline}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                  
                </section>

                {(course.enrollEnabled) &&
                    (<section name="enroll_section" id="enroll_section" className="course_section">
                        <CourseShopper plugin={course.plugin}
                            isLoggedIn={isLoggedIn}
                            enrollManifesto={course.enrollManifesto}
                            courseCode={courseCode}
                            courseTitle={course.title}
                        >
                        </CourseShopper>
                    </section>)}
            </>)
    }
}

export default withRouter(CourseFullLayoutPage);