import get from 'lodash.get';

const errorParser = error => {
    return get(error, 'response.data.errors', []);
};

export const invalidFieldsMapper = (errors, fields, localError = null) => {
    
    // get invalid fields from errors
    errors = errorParser(errors);    
    const newState = fields;
      
    // for each invalid field show validation info 
    errors.forEach(item => {
                
        const field = item.path;   
        
        // remote errors override local error
        const message = item.message  ? item.message  : (localError[field]);
        if ((newState[field] !== null)
            && (newState[field] instanceof Object)) {
                newState[field] = { isValid: false, value: newState[field].value, message: message  }; 
        } else {
            newState[field] = { isValid: false, value: newState[field], message: message }; 
        }        
    });
   
    return newState;
};

export const payloadBuilder = state => {
    
    const payload = {};

    const defaultValues = state.defaultValues;
    
    for (const field in defaultValues) {

        // if the user changed default value
        if (state[field] && (state[field] instanceof Object)) {
            payload[field] = state[field].value;
        } else if (payload[field]) {
            // from default value rendered in the form
            payload[field] = state[field];
        } else if (defaultValues[field]){
            // from default value
            payload[field] = defaultValues[field];
        }
    }
 
    return Object.assign(state.defaultValues || {}, payload);
};

export const notNullObjectBuilder = (...objects) => {

    let result = {};
    objects.forEach(obj => {

        const filtered = {};
        for (const [key, value] of Object.entries(obj)) {
            if (value != null) filtered[key] = value;
        }

        result = { ...result, ...filtered };
    });

    return result;
};


  //------
  
  export const isEmptyObject = (obj) => {
    let retValue = true;
    if (obj) {
      if (Object.keys(obj).length > 0) retValue = false;
    }
  
    return retValue;
  };
  
export * from './dateHelper';
export * from './cacheHelper';
export * from './courseHelper';
export * from './formHelper';
export * from './uriHelpers';
export * from './stringHelper';
export * from './networkErrorHelper';
export * from './keepAliveWorker';
