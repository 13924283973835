import React, { Component } from "react";
import {
    Title, MnemoLoading, BoxSuccess, BoxDanger, ModalHandler
} from "components/misc";
import { connect } from 'react-redux';

import { Row, Col, Container, Button } from 'design-react-kit';
import { getCurrentUser, loginByUserId } from "store/actions";

import {
    networkErrorHelper,
    adminService,
} from "libs";

import {
    UserDetailAccount, UserDetailTarget,
    UserDetailProfile, UserDetailAddress,
    UserDetailQualification, UserDetailEnrollment, UserDetailEnrollmentAbilitation,
    UserDetailCourses, UserDetailSMSList, UserDetailDocument
} from "./";

import { withRouter } from 'react-router-dom';
import { MInput } from "components/forms";

class UserDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            whereObj: {
                id: this.props.match.params.id,
                role: this.props.match.params.role
            },
            detail: {
                user: false,
                profile: false,
                target: false,
                address: false,
                qualification: false,
                enrollment: false,
                enrollmentAbilitation: false,
                courses: false,
                documents: false,
                sms: false
            },
            loading: false,
            collectAndSend: {
                state: null,
                message: ''
            },
            abilitationCourseCode: ''
        }
    }//constructor


    componentDidMount() {
        this.loadRemote(this.state.whereObj);
    }//componentDidMount


    loadRemote(whereObj) {
        adminService.userDetail(whereObj).then(({ data }) => {

            const { user, profile,
                target, address,
                qualification, enrollment, enrollmentAbilitation,
                documents, course, sms
            } = data.payload.resObj;

            this.setState({
                detail: {
                    user,
                    profile,
                    target,
                    address,
                    qualification,
                    enrollment,
                    enrollmentAbilitation,
                    documents,
                    courses: course,
                    sms
                }
            })
        })
        .catch(error => {
            if (!networkErrorHelper.is404(error)) {
                console.log(error);
                networkErrorHelper.notify(error);
            }

            this.setState({ loading: false });
        });
    }//loadRemote


    collectAndSend = (uid) =>{
        this.setState({ loading: true });

        adminService.collectAndSendTo(uid).then((result)=>{
            const { reportUserData } = result.data.payload;

            adminService.syncUserDataTo(reportUserData).then((res)=>{

                this.setState({
                    loading:false,
                    collectAndSend:{
                        state: res.data.payload.state,
                        message: res.data.payload.message,
                    }
                })

                //--- remove returned message ---
                setTimeout(() => {                        
                    this.setState({
                        collectAndSend:{
                            state: null,
                            message: '',
                        }
                    })                    
                }, 6000);
            })
            .catch(error => {
                if (!networkErrorHelper.is404(error)) {
                    console.log(error);
                    networkErrorHelper.notify(error);
                }
    
                this.setState({ loading: false });
            });
        })
        .catch(error => {
            if (!networkErrorHelper.is404(error)) {
                console.log(error);
                networkErrorHelper.notify(error);
            }

            this.setState({ loading: false });
        });
    }//sendUserData


    collectEnrollmentAndSend = (uid) =>{
        adminService.collectUserEnrollment(uid).then((res)=>{
            const result = res.data.payload.result;
                       
            adminService.syncEnrollmentDataTo(result).then((res)=>{
                //console.log(res)
            })
            .catch(error => {
                if (!networkErrorHelper.is404(error)) {
                    console.log(error);
                    networkErrorHelper.notify(error);
                }
            });
        })
        .catch(error => {
            if (!networkErrorHelper.is404(error)) {
                console.log(error);
                networkErrorHelper.notify(error);
            }
        });
    }//collectEnrollmentAndSend


    /** Personification */
    personification = (userId) =>{const code  =  <>
                        <p>Vuoi sloggarti e riloggarti con l'Account selezionato?</p>
                      </>
        ModalHandler.show(userId, 'Attenzione', code, null, this.runPersonification);
    }//personification

    runPersonification = (userId) =>{
        this.props.loginByUserId(userId, '/dashboard');
    }


    /** allow multiple enrollments */
    allowMultipleEnrollment = (e,userId) =>{
        const formInput = [
            {
                name: "courseCode",
                field: "courseCode",
                label: 'Codice Corso:',
                type: "text",
                className: "mt-3 mb-1"
            }
        ]

        const code  =  <>
                        <p>Questa procedura abilita il corsista all'iscrizione ad un ulteriore corso.
                        Vuoi davvero procedere?</p>
                        <div className="row mt-3">
                        <div className="col-md-6">
                            <MInput {...formInput[0]} onChange={this.getCourseCode}/>
                            <div className="small text-info">Codice corso d'abilitare.</div>
                        </div>
                        </div>
                      </>;
        ModalHandler.show(parseInt(userId), 'Attenzione', code, null, this.runallowMultipleEnrollment);
    }//allowMultipleEnrollment

    getCourseCode = (_, value) =>{
        this.setState({
            abilitationCourseCode: value
        })
    }//getCourseCode

    runallowMultipleEnrollment = (userId) =>{
        this.setState({ loading: true });
        adminService.insertUserEnrollmentAbilitation({userId, courseCode: this.state.abilitationCourseCode}).then(({ data }) => {
            console.log(data)
            this.setState({ loading: false });
        })
        .catch(error => {
            console.log(error);
            networkErrorHelper.notify(error);

            this.setState({ loading: false });
        });
    }


    //--- ---


    render() {

        const { user,
            profile, target,
            address, qualification, enrollmentAbilitation,
            enrollment, courses, sms, documents,
        } = this.state.detail;

        const { whereObj, loading, collectAndSend } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);
    
        const userLoggedInfo = getCurrentUser();


        return (
            <>
                <section className="my-4">
                    
                    <Title>
                        <Row>
                            <Col lg={{ size: 6 }}>Dettaglio Account</Col>
                            {<Col lg={{ size: 6 }} className="text-right">
                                {((userLoggedInfo.roles === 'admin' || userLoggedInfo.roles === 'segreteria' ) && user.roles === 'student') && (
                                    <>
                                        {/*<Button onClick={(e) => this.collectAndSend(whereObj.id)}>Invia Report alla Segreteria</Button>*/}
                                        {/*<Button onClick={(e) => this.collectEnrollmentAndSend(whereObj.id)}>TEST Send Enrollments</Button>*/}
                                        <Button color="btn btn-primary bg-dark m-2 p-3" onClick={(e) => this.allowMultipleEnrollment(e, whereObj.id)}>Abilita domanda d'iscrizione</Button>
                                        <Button color="btn btn-primary bg-danger m-2 p-3" onClick={(e) => this.personification(whereObj.id)}>Login come {user.name+' '+user.surname}</Button>
                                    
                                    </>
                                )}
                                </Col>}
                        </Row>
                    </Title>

                    {(collectAndSend.message !== '') && <Container className="mt-2">
                    <Row>
                        <Col>
                            {(!collectAndSend.state) && <BoxDanger>{collectAndSend.message}</BoxDanger>}
                            {(collectAndSend.state) && <BoxSuccess>{collectAndSend.message}</BoxSuccess>}
                        </Col>
                    </Row>
                    </Container>}

                    <UserDetailAccount data={user} />
                    <UserDetailProfile data={profile} />
                    <UserDetailTarget data={target} />
                    <UserDetailAddress data={address} />
                    <UserDetailQualification data={qualification} />
                    <UserDetailDocument data={documents} />
                    <UserDetailEnrollment data={enrollment} account={whereObj} />
                    <UserDetailEnrollmentAbilitation data={enrollmentAbilitation} account={whereObj} />
                    <UserDetailCourses data={courses} account={whereObj}/>
                    <UserDetailSMSList data={sms} account={whereObj}/>
                </section>
            </>
        );
    }

}

const stateToProps = ({ app }) => {
  return {
    msgLogin: app.error
  };
};

const dispatchToProps = dispatch => {
  return {
    loginByUserId(userId, redirectUrl) {
      dispatch(loginByUserId(userId, redirectUrl));
    }
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(withRouter(UserDetail));