import React, { Component } from "react";

import { Title, MSection } from "components/misc";
import { Helmet } from "react-helmet";
import { Row, Col } from "design-react-kit";

export class PageSediEsami extends Component {
  render() {
    const title = 'Sedi di esami Associazione Mnemosine';
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </Helmet>
        <MSection>
          <Row>
            <Col>
              <h1>Esami scritti e orali in più di 100 città italiane.</h1>
              <br/>
            </Col>
          </Row>
          <Row>
            <Col xs='12' md='6'>
            <p>L’Associazione Mnemosine, Ente Accreditato M.I.U.R., opera in tutto il territorio Nazionale ed Internazionale grazie ad una capillare rete in diverse regioni <strong>con oltre 100 sedi di esami in tutta Italia</strong>, 25 sedi periferiche, 10 sedi d’esame all’estero e più di 50 accordi con Università europee e internazionali.</p>
            <p>L’Associazione Mnemosine <strong>propone a tutti i suoi corsisti prestigiose sedi di sua proprietà</strong> distribuite uniformemente in più di 100 città italiane che si suddividono in sedi per gli esami nelle sessioni straordinarie, sessioni ordinarie o ulteriori sedi, anche all’estero, al raggiungimento di un numero minimo di 20 iscritti. <br/>Le sedi per le sessioni ordinarie e straordinarie sono attivate indipendentemente dal numero di iscritti.</p>
            <p>Di seguito troverai la lista delle sedi in oltre 100 città italiane divise per regioni:</p>
            </Col>
            <Col xs='12' md='6' className="wrap_image_sedi_mnemosine">
              <img className="image_sedi_mnemosine" src="/img/pages/sedi-mnemosine.png"  alt="sedi esami Associazione Mnemosine" />
            </Col>
          </Row>
            
          <Row>
            <Col>
            <hr/>
            <Row>
              <Col xs='12' md='2'>
                <h3>Abbruzzo</h3>
              </Col>
              <Col xs='12' md='10'>
                <div className="sedi-list">
                  <ul className="sedi-list_items">
                    <li>L’Aquila</li>
                    <li>Pescara</li>
                    <li>Teramo</li>
                  </ul>
                </div>
              </Col>
            </Row>
            <hr/>
            <br/>

            <Row>
            <Col xs='12' md='2'>
              <h3>Basilicata</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Matera</li>
                  <li>Potenza</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>

            <Row>
            <Col xs='12' md='2'>
              <h3>Calabria</h3>
            </Col>
            <Col xs='12' md='10'>
            <div className="sedi-list">
              <ul className="sedi-list_items">
                <li>Catanzaro</li>
                <li>Cosenza</li>
                <li>Crotone</li>
                <li>Reggio Calabria</li>
                <li>Vibo Valentia</li>
              </ul>
            </div>
            </Col>
            </Row>
            <hr/>
            <br/>
            
            <Row>
            <Col xs='12' md='2'>
              <h3>Campania</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Avellino</li>
                  <li>Benevento</li>
                  <li>Caserta</li>
                  <li>Ischia</li>
                  <li>Napoli</li>
                  <li>Salerno</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>

            <Row>
            <Col xs='12' md='2'>
              <h3>Elimia Romagna</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Bologna</li>
                  <li>Ferrara</li>
                  <li>Forlì</li>
                  <li>Modena</li>
                  <li>Parma</li>
                  <li>Piacenza</li>
                  <li>Ravenna</li>
                  <li>Reggio Emilia</li>
                  <li>Rimini</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>
            
            <Row>
            <Col xs='12' md='2'>
              <h3>Friuli Venezia Giulia</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Pordenone</li>
                  <li>Trieste</li>
                  <li>Udine</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>

            <Row>
            <Col xs='12' md='2'>
              <h3>Lazio</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Frosinone</li>
                  <li>Latina</li>
                  <li>Roma</li>
                  <li>Viterbo</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>

            <Row>
            <Col xs='12' md='2'>
              <h3>Liguria</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Genova</li>
                  <li>Imperia</li>
                  <li>La Spezia</li>
                  <li>Savona</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>
            
            
            <Row>
            <Col xs='12' md='2'>
              <h3>Lombardia</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Bergamo</li>
                  <li>Brescia</li>
                  <li>Como</li>
                  <li>Lodi</li>
                  <li>Mantova</li>
                  <li>Milano</li>
                  <li>Pavia</li>
                  <li>Varese</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>
            
            <Row>
            <Col xs='12' md='2'>
              <h3>Marche</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Ancona</li>
                  <li>Macerata/Ascoli Piceno</li>
                  <li>Pesaro Urbino</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>
            
            <Row>
            <Col xs='12' md='2'>
              <h3>Molise</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Campobasso</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>

            <Row>
            <Col xs='12' md='2'>
              <h3>Piemonte</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Alessandria</li>
                  <li>Cuneo</li>
                  <li>Novara</li>
                  <li>Torino</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>
            
            <Row>
            <Col xs='12' md='2'>
              <h3>Puglia</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Andria</li>
                  <li>Bari</li>
                  <li>Brindisi</li>
                  <li>Foggia</li>
                  <li>Lecce</li>
                  <li>Taranto</li>
                </ul>
              </div>
            </Col>
            </Row>  
            <hr/>
            <br/>
            
            <Row>
            <Col xs='12' md='2'>
              <h3>Sardegna</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Cagliari</li>
                  <li>Nuoro</li>
                  <li>Olbia</li>
                  <li>Oristano</li>
                  <li>Sassari</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>

            <Row>
            <Col xs='12' md='2'>
              <h3>Sicilia</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Agrigento</li>
                  <li>Caltanissetta</li>
                  <li>Canicattì</li>
                  <li>Castelvetrano</li>
                  <li>Catania</li>
                  <li>Enna</li>
                  <li>Marsala</li>
                  <li>Mazara del Vallo</li>
                  <li>Messina</li>
                  <li>Palermo</li>
                  <li>Ragusa</li>
                  <li>Sciacca</li>
                  <li>Siracusa</li>
                  <li>Termini Imerese</li>
                  <li>Trapani</li>
                  <li>Ucria</li>
                  <li>Vittoria/Ragusa</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>
           
            <Row>
            <Col xs='12' md='2'>
              <h3>Toscana</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Arezzo</li>
                  <li>Firenze</li>
                  <li>Grosseto</li>
                  <li>Livorno</li>
                  <li>Lucca</li>
                  <li>Pisa</li>
                  <li>Prato</li>
                  <li>Siena</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>

            <Row>
            <Col xs='12' md='2'>
             <h3>Trentino Alto Adige</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Bolzano</li>
                  <li>Trento</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>
            
            <Row>
            <Col xs='12' md='2'>
              <h3>Umbria</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Perugia</li>
                  <li>Terni</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>
            
            <Row>
            <Col xs='12' md='2'>
              <h3>Val D'Aosta</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Aosta</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>
            
            <Row>
            <Col xs='12' md='2'>
              <h3>Veneto</h3>
            </Col>
            <Col xs='12' md='10'>
              <div className="sedi-list">
                <ul className="sedi-list_items">
                  <li>Belluno</li>
                  <li>Padova</li>
                  <li>Rovigo</li>
                  <li>Treviso</li>
                  <li>Venezia</li>
                  <li>Verona</li>
                  <li>Vicenza</li>
                </ul>
              </div>
            </Col>
            </Row>
            <hr/>
            <br/>

            </Col>
          </Row>
        </MSection>
      </>
    );
  }
}
