import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ROUTES } from 'const';

const ProtectedRoute = ({ path, isLoggedIn, children, component }) => {

    return (
        <Route
            exact
            path={path}
            component={
                ({ location }) => {
                    return (
                        !isLoggedIn ?
                            (<Redirect
                                to={{
                                    pathname: ROUTES.LOGIN,
                                    state: { from: location }
                                }}
                            />)
                            : <>{children || component}</>
                    );
                }
            }
        />
    );
};


export default ProtectedRoute;