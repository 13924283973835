import React, { Component } from 'react';
import { Input, Label, FormGroup, FormText } from 'design-react-kit';
import { formHelper } from 'libs/helpers';

export class MCheckbox extends Component {

    state = {
        checked: null
    }
   
    onClick = (event) => {

        const checked = event.target.checked;
        this.setState({ checked: checked });

        const onChange = this.props.onChange;
        if (formHelper.isFunction(onChange)) {
            onChange(this.props.id, checked);
        }

    };

    componentDidMount() {
        this.setState({ checked: this.props.value });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            checked: nextProps.value
        })
    }
    
    /**
     * PROPS: 
     * id       : identifier 
     * label    : example text (brief description of fields)     
     * infoText : addtional text used when the input in invalid
     * disabled : insert for readonly      
     * readOnly : is readOnly
     * value    : the value
     */
    render() {

        // get properties
        const { id, name,
            disabled, readOnly,
            label, infoText } = this.props;
        
        const myProps = {
            disabled: disabled || readOnly,
            id, name
        };

        if (this.state.checked){
            myProps.defaultChecked = 'defaultChecked';
        }

        return (
            <>
                <FormGroup check >
                    <Input id={id} type="checkbox"
                        {...myProps}
                        onClick={this.onClick}
                    />
                    <Label for={id} check>{label} {this.props.children}</Label>
                    {infoText && (<FormText color="">{infoText}
                    </FormText>)}
                </FormGroup>
            </>
        );
    }
}