import React from 'react';
import { ValidatedForm } from '../ValidatedForm';
import { MInput } from 'components/forms';
import { MSelect } from './MSelect';
import { MTextArea } from './MTextArea';
import { MToggle } from './MToggle';
import { formHelper, invalidFieldsMapper, networkErrorHelper, notNullObjectBuilder, payloadBuilder, userAddressService, utilsService } from 'libs';

/**
     * PROPS: 
     * id       : identifier 
     * label    : example text (brief description of fields)
     * value    : the value
     * disabled : insert for readonly 
     * readOnly : is readOnly
     */
export class MAddress extends ValidatedForm {

    PAYLOADS = {
        provinces: [],
        cities: [],
        nations: [],
    }    

    setValidation(type, isForeignAddress) {

        if (type === 'born') {
            this.validation = (isForeignAddress) ? this.validation_born_foreign : this.validation_born_italian;
        } else {
            this.validation = (isForeignAddress) ? this.validation_foreign : this.validation_italian;
        }
    }

    changeForeign = (_, value) => {

        this.setValidation(this.props.type, value);
        this.setState({ isForeignAddress: value });      
        if (this.props.otherEvents){            
            this.props.otherEvents('isForeign', value);
        }
    }

    setProvince = (_, selectedProvince) => {

        // empty city list
        this.PAYLOADS.cities.length = 0;

        if (parseInt(selectedProvince) === -1) {
            this.setState({ province: null });
            return;
        }

        utilsService.cities(selectedProvince).then(({ data }) => {
            const { payload } = data;
            const cities = payload.map(({ name, id, postCode }) => ({ label: name, value: id, postCode }));

            this.PAYLOADS.cities.push({ label: 'Seleziona', value: -1 });
            this.PAYLOADS.cities.push(...cities);

            // force re-rendering of component (update select of cities)
            this.setState(prevState => {
                return { payloadChanges: prevState.payloadChanges + 1 }
            });
        });
    }

    setCityId = (_, cityId) => {

        cityId = parseInt(cityId);

        if (cityId !== -1) {
            const city = this.PAYLOADS.cities.find(c => c.value === cityId);
            const valueCity = { value: city.label, isValid: true };
            const valueCityId = { value: cityId, isValid: true };
            const valueCap = { value: city.postCode, isValid: true };
            this.setState({
                cityId: valueCityId,
                cap: valueCap,
                city: valueCity
            });
        }
    }

    setNationId = (_, nationId) => {
        nationId = parseInt(nationId);
        if (nationId !== -1) {
            const nation = this.PAYLOADS.nations.find(c => c.value === nationId);
            const valueNation = { value: nation.label, isValid: true };
            const valueNationId = { value: nationId, isValid: true };

            this.setState({
                nationId: valueNationId,
                nation: valueNation
            });
        }
    }

    FIELDS_GROUP_ITALIAN = [
        [
            {
                field: "isForeign",
                label: "Indirizzo Estero",
                component: MToggle,
                infoText: "Specificare se è un indirizzo estero o italiano",
                className: "col-md-6",
                onChange: this.changeForeign
            }
        ],
        [
            {
                field: "address",
                label: "Indirizzo",
                component: MInput,
                type: "text",
                className: "col-md-10"
            },
            {
                field: "houseNumber",
                label: "Num.civico",
                component: MInput,
                type: "text",
                className: "col-md-2"
            }
        ],
        [
            {
                field: "province",
                label: "Provincia",
                component: MSelect,
                payload: { options: this.PAYLOADS.provinces },
                className: "col-md-4",
                onChange: this.setProvince
            },
            {
                field: "cityId",
                label: "Città",
                component: MSelect,
                payload: { options: this.PAYLOADS.cities },
                className: "col-md-8",
                onChange: this.setCityId
            }
        ],
        [
            {
                field: "cap",
                label: "CAP",
                component: MInput,
                type: "text",
                className: "col-md-2"
            },
            {
                field: "locality",
                label: "Frazione",
                component: MInput,
                className: "col-md-10"
            }
        ],
        [
            {
                field: "note",
                label: "Annotazioni Indirizzo",
                component: MTextArea,
                className: "col-md-12 mb-4"
            }
        ]
    ];

    FIELDS_BORN_ITALIAN = [
        [
            {
                field: "isForeign",
                label: "Nato all'estero",
                component: MToggle,
                infoText: "Selezionare nel caso in cui è nato all'estero",
                className: "col-md-6",
                onChange: this.changeForeign
            }
        ],
        [
            {
                field: "province",
                label: "Provincia",
                component: MSelect,
                payload: { options: this.PAYLOADS.provinces },
                className: "col-md-4",
                onChange: this.setProvince
            },
            {
                field: "cityId",
                label: "Città",
                component: MSelect,
                payload: { options: this.PAYLOADS.cities },
                className: "col-md-8",
                onChange: this.setCityId
            }
        ]
    ];

    FIELDS_GROUP_FOREIGN = [
        [
            {
                field: "isForeign",
                label: "Indirizzo Estero",
                component: MToggle,
                infoText: "Selezionare nel caso di partita IVA non italiana",
                className: "col-md-6",
                onChange: this.changeForeign
            }
        ],
        [
            {
                field: "address",
                label: "Indirizzo",
                component: MInput,
                type: "text",
                className: "col-md-10"
            },
            {
                field: "houseNumber",
                label: "Num.civico",
                component: MInput,
                type: "text",
                className: "col-md-2"
            }
        ],
        [
            {
                field: "nationId",
                label: "Nazione",
                component: MSelect,
                payload: { options: this.PAYLOADS.nations },
                className: "col-md-6",
                onChange: this.setNationId
            },
            {
                field: "cityForeign",
                label: "Città",
                component: MInput,
                className: "col-md-6"
            }
        ],
        [
            {
                field: "zip",
                label: "ZIP",
                component: MInput,
                type: "text",
                className: "col-md-2"
            },
            {
                field: "locality",
                label: "Frazione",
                component: MInput,
                className: "col-md-10"
            }
        ],
        [
            {
                field: "note",
                label: "Annotazioni Indirizzo",
                component: MTextArea,
                className: "col-md-12"
            }
        ]
    ];

    FIELDS_BORN_FOREIGN = [
        [
            {
                field: "isForeign",
                label: "Nato all'estero",
                component: MToggle,
                infoText: "Specificare se è un indirizzo estero o italiano",
                className: "col-md-6",
                onChange: this.changeForeign
            }
        ],
        [
            {
                field: "nationId",
                label: "Nazione",
                component: MSelect,
                payload: { options: this.PAYLOADS.nations },
                className: "col-md-6",
                onChange: this.setNationId
            },
            {
                field: "cityForeign",
                label: "Città",
                component: MInput,
                className: "col-md-6"
            }
        ],
    ];

    emptyFields = {

        address: '',
        houseNumber: '',
        locality: '',
        note: '',

        province: '',
        city: '',
        cityId: '',
        cap: '',

        nation: '',
        nationId: '',
        cityForeign: '',
        zip: '',

        isForeign: false,
    };

    ERROR_MESSAGES = {
        address: "Indirizzo non valido",
        cityId: "Selezionare una città",
        province: "Selezionare una provincia",
        cap: "codice CAP non valido (5 cifre)",
        nationId: "Selezionare una nazione",
        cityForeign: "Specificare una città",
        zip: "codice ZIP non valido"
    };

    validation_italian = {
        address: value => value !== null && value.length > 2,
        cityId: value => value !== null && value > 0,
        province: value => value !== null && value !== '',
        cap: value => String(value).length === 5
    };

    validation_born_italian = {
        cityId: value => value !== null && value > 0,
        province: value => value !== null && value !== '',
    };

    validation_foreign = {
        address: value => value !== null && value.length > 2,
        nationId: value => value !== null && value > 0,
        cityForeign: value => value !== null && value.length > 2,
        zip: value => value !== null && value.length > 2,
    };

    validation_born_foreign = {
        nationId: value => value !== null && value > 0,
        cityForeign: value => value !== null && value.length > 2,
    };

    // default validation is italian default 
    validation = this.validation_italian;

    state = {
        isForeignAddress: false,
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields
        },
        payloadChanges: 0
    }

    loadRemote() {

        if (this.PAYLOADS.provinces.length === 0) {
            utilsService.provinces().then(({ data }) => {
                const { payload } = data;
                const provinces = payload.map((item) => ({ label: item.denUts, value: item.denUtsShort }));
                this.PAYLOADS.provinces.push({ label: 'Seleziona', value: '' });
                this.PAYLOADS.provinces.push(...provinces);
            });
        }

        if (this.PAYLOADS.nations.length === 0) {
            utilsService.nations().then(({ data }) => {
                const { payload } = data;
                const nations = payload.map(({ name, id }) => ({ label: name, value: id }));
                this.PAYLOADS.nations.push({ label: 'Seleziona una nazione', value: -1 });
                this.PAYLOADS.nations.push(...nations);
            });
        }
        
        // load address from id
        if (this.props.value) {
            this.loadAddress(this.props.value);
        }
    }

    saveRemote() {

        // normalize address information
        const payload = payloadBuilder(this.state);
        if (payload.isForeign) {
            payload.cityId = 0;
            payload.city = payload.cap = payload.province = '';
        } else {
            payload.nation = payload.cityForeign = payload.zip = '';
            payload.nationId = 0;
        }

        userAddressService.updateOrInsert(payload)
            .then(() => {

                const validations = {
                    companyName: null,
                    VATCode: null,
                    address: null,
                    nationId: null,
                    cityForeign: null,
                    zip: null,
                    cityId: null,
                    province: null,
                    cap: null
                }

                this.setState({ formActive: false, ...validations });
                const onChangeFunction = formHelper.isFunction(this.props.onChange) ? this.props.onChange : formHelper.emptyFunction;
                onChangeFunction('addressId', payload.id);
            })
            .catch(errors => {

                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );

                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });
    }

    loadAddress(id) {

        userAddressService.address(id)
            .then(({ data }) => {

                // merge not null values and format dates
                const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.payload.address,
                );

                if (defaultValues.cityId > 0) {
                    this.setProvince(0, defaultValues.province);
                }

                const newState = {
                    defaultValues,
                    isForeignAddress: defaultValues.isForeign
                };
                this.setState(newState);
            })
            .catch(error => {
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });;
    }

    getFields(type, isForeignAddress) {

        if (type === 'born') {
            if (isForeignAddress) {
                return this.FIELDS_BORN_FOREIGN;
            } else {
                return this.FIELDS_BORN_ITALIAN;
            }
        } else {
            if (isForeignAddress) {
                return this.FIELDS_GROUP_FOREIGN;
            } else {
                return this.FIELDS_GROUP_ITALIAN;
            }
        }
    }

    render() {

        // get properties
        const { readOnly, type } = this.props;

        const isForeignAddress = this.state.isForeignAddress;

        let fields = this.getFields(type, isForeignAddress);
        this.setValidation(type, isForeignAddress);

        const { code } = (isForeignAddress)
            ? this.renderFields(fields, this.state.defaultValues, !readOnly)
            : this.renderFields(fields, this.state.defaultValues, !readOnly);

        return (
            <>
                {code}
            </>
        );
    }

}