import { ROUTES } from 'const';
import { dateHelper } from 'libs';
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

import './BlogArticleItem.css';

export class BlogArticleItem extends Component {

    render() {

        const { title, summary, date, slug, image } = this.props.article;
        const link = ROUTES.BLOG_ARTICLE+'/'+slug;

        const urlImage = image || '/img/blog/corsimnemosine-notizie.jpg';

        return (<>
           <a className='articleitem-card__link' href={link} title={slug}>
                <div className="articleitem-card d-flex flex-row" >
                    <div className="articleitem-card__header">
                        <img className="articleitem-card__img" alt={title} src={urlImage} />
                    </div>
                    <div className="articleitem-card__body">
                        <h6>{ReactHtmlParser(title)}</h6>
                        <hr/>
                        <p className='articleitem-card__copy'>{ReactHtmlParser(summary)}</p>
                        <span className="articleitem-card__footer">{dateHelper.toITDate(date)}</span>
                    </div>
                </div>
            </a>
        </>)
    }
}