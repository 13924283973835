import React, { Component } from 'react';
import { Input } from 'design-react-kit';

export class MInput extends Component {

    /**
     * PROPS: 
     * id       : identifier 
     * label    : example text (brief description of fields)
     * type     : password, email, number, tel, time     
     * value    : the value
     * infoText : addtional text used when the input in invalid
     * disabled : insert for readonly 
     * readOnly : is readOnly
     * valid    : set  when the input is valid
     * invalid  : set  when the input is invalid
     * autoComplete : "off/on"
     */
    render() {

        // get properties
        const { id,
            disabled = false, readOnly = false,
            label, valid, invalid,
            infoText, type, name, autoComplete
        } = this.props;

        let value = this.props.value;
        if (value) {
            value = value.toString();
        }

        // map properites
        const myProps = {
            id, type, name,
            disabled, readOnly,
            label, infoText,
            value, autoComplete
        };

        let className = (valid) ? 'is-valid' : '';
        className += (invalid) ? 'is-invalid' : '';
        className += (this.props.className) ? this.props.className : '';

        return (
            <Input
                className={className}
                {...myProps}
                autoComplete = {autoComplete}
                onChange={event => this.onChange(event)}
                onKeyUp={event => this.onKeyUp(event)}
            />
        );
    }

    onChange = (event) => {
        let value = event.target.value;
        if (this.props.type === "number") {
            value = parseInt(value);
        }

        if (this.props.onChange) {
            this.props.onChange(this.props.name, value);
        }

        /** force label.active */
        if (value !== '') {
            const arr = Array.from(event.currentTarget.parentNode.childNodes).filter(res => {
                return (res.nodeName).toLowerCase() === 'label'
            })

            if (arr.length > 0) { arr[0].classList.add("active"); }
        }
    }

    onKeyUp = (event) => {
        let value = event.target.value;

        if (this.props.onKeyUp) {
            this.props.onKeyUp(this.props.name, value);
        }
    }
}