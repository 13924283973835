import React from "react";

import {
    FormEnabler,
    ValidatedForm,
    MInput,
    MTextArea
} from "components/forms";

import {
    payloadBuilder,
    invalidFieldsMapper,
    networkErrorHelper,
    notNullObjectBuilder,
    adminService,
} from "libs";

import dayjs from 'dayjs';

import {
    Card,
    CardBody
} from 'design-react-kit';
import { MToggle } from "../shared/MToggle";


export class AdminPromotionalForm extends ValidatedForm {


    ERROR_MESSAGES = {
        code: "Codice Promozione obbligatorio",
        courseCode: "Codice Corso obbligatorio",
        amount: "Prezzo obbligatorio",
        dateStart: "Data inizio Promozione obbligatoria",
        dateEnd: "Data fine Promozione obbligatoria",
    };

    emptyFields = {
        code: '',
        courseCode: '',
        description: '',
        amount: '',
        dateStart: '',
        dateEnd: '',
        onlyReferred: false,
        isProvider: false,
        skipFirstRate: false
    };

    validation = {
        code: value => /^[A-Za-z0-9 ]+$/i.test(value),
        courseCode: value => /^[A-Za-z0-9%]+$/i.test(value),
        amount: value => parseFloat(value) >= 0,
        dateStart: value => /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.test(value),
        dateEnd: value => /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.test(value),
    };


    FIELDS_GROUP = [
        [
            {
                id: "code",
                field: "code",
                label: "Codice Promozione",
                component: MInput,
                type: "text",
                className: "col-md-8"
            },
            {
                id: "courseCode",
                field: "courseCode",
                label: "Codice Corso",
                component: MInput,
                type: "text",
                className: "col-md-4"
            }
        ], [
            {
                id: "isProvider",
                field: "isProvider",
                label: "Codice provider",
                component: MToggle,
                className: "col-md-4"
            },
            {
                id: "onlyReferred",
                field: "onlyReferred",
                label: "Solo ultima rata",
                component: MToggle,
                className: "col-md-4"
            },
            {
                id: "skipFirstRate",
                field: "skipFirstRate",
                label: "Iscrizione (salta 1a Rata)",
                component: MToggle,
                className: "col-md-4"
            },
            {
                id: "amount",
                field: "amount",
                label: "Prezzo",
                component: MInput,
                type: "text",
                className: "col-md-6"
            }
        ],
        [
            {
                id: "dateStart",
                field: "dateStart",
                label: "Data inizio promozioni",
                component: MInput,
                type: "date",
                className: "col-md-6"
            },
            {
                id: "dateEnd",
                field: "dateEnd",
                label: "Data fine promozioni",
                component: MInput,
                type: "date",
                className: "col-md-6"
            }
        ],
        [
            {
                id: "description",
                field: "description",
                label: "Descrizione promozione",
                component: MTextArea,
                rows: 6,
                className: "col-md-12"
            }
        ]
    ];

    //--- ---

    constructor(props) {
        super(props);

        this.state = {
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: true,
            idPromotional: this.props.idPromotional
        }

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.loadRemote();
    }

    loadRemote(additionalState = {}) {
        //--- prevent loadRemote when not required ---
        if (parseInt(this.props.idPromotional) <= 0) {
            return false;
        }

        let filter = {
            searchFilter: {
                id: parseInt(this.props.idPromotional)
            }
        }

        adminService.getPromotional(filter)
            .then(({ data }) => {

                //--- force date format to date ---
                data.payload.promotional.dateStart = dayjs(data.payload.promotional.dateStart).format('YYYY-MM-DD');
                data.payload.promotional.dateEnd = dayjs(data.payload.promotional.dateEnd).format('YYYY-MM-DD');

                const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.payload.promotional
                );

                const newState = {
                    defaultValues,
                    ...additionalState,
                    loading: false
                };

                this.setState(newState);
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                console.log(error);
                this.setState({ loading: false });
            });
    }//loadremote


    onCancel = () => {
        this.resetForm();
    }//onCancel

    onBackPage = () => {
        window.history.back();
    }

    saveRemote = () => {
        const payload = payloadBuilder(this.state);

        if (parseInt(this.props.idPromotional) > 0) {
            adminService.updatePromotional(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
                .catch(errors => {
                    if (networkErrorHelper.is422(errors)) {
                        const newState = invalidFieldsMapper(
                            errors,
                            this.state,
                            this.ERROR_MESSAGES
                        );
                        this.setState({ ...newState });
                    } else {
                        networkErrorHelper.notify(errors);
                    }
                });

        } else {

            adminService.insertPromotional(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
                .catch(errors => {
                    if (networkErrorHelper.is422(errors)) {
                        const newState = invalidFieldsMapper(
                            errors,
                            this.state,
                            this.ERROR_MESSAGES
                        );
                        this.setState({ ...newState });
                    } else {
                        networkErrorHelper.notify(errors);
                    }
                });
        }
    };


    render() {

        let { formActive, defaultValues, idPromotional } = this.state;
        const { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <CardBody tag="div">
                        <form className="user" id="promotionalform">
                            {code}
                        </form>

                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={parseInt(idPromotional) > 0 ? this.onCancel : this.onBackPage}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                        </div>
                    </CardBody>
                </Card>
            </>
        );

    }

}