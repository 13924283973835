import React, { Component } from "react";
import { cache } from '../../libs';

import {Icon} from 'design-react-kit';

import ReactHtmlParser from 'react-html-parser';

class QuestionCheck extends Component {

    constructor(props) {
        super(props);
        this.state = {
            answers: this.props.answers,
            choice: []
        };
    }


    /** */
    //--- check if exists local value ---
    isChecked = (idQuiz, idQuestion, idAnswer, label) =>{
        let getValue = cache.get(`quiz-${idQuiz}-${idQuestion}`);
        let result   = false;
        
        if(getValue !== null){
            getValue.forEach((item)=>{
                if(item.idAnswer === idAnswer && item.idQuestion === idQuestion){

                    const exists = this.state.choice.filter((item)=>{
                        return (item.idAnswer === idAnswer && item.idQuestion === idQuestion)
                    })
                    if(exists.length === 0){
                        this.state.choice.push(item);
                    }

                    result = true;
                }
            })
        }else{
            result = false;            
        }

        /*this.setState({
            choice: (this.state.choice.filter((v,i,a)=>a.findIndex(t=>(t.idAnswer === v.idAnswer))===i))
        })*/
        
        return result;
    }//isChecked

    
    /** */
    setMyState = (idAnswer, idQuestion, label, idQuiz) => {

        let data = {
            idAnswer: idAnswer,
            idQuestion: idQuestion,
            label: ReactHtmlParser(label)
        }

        let result = false;

        //--- manage elements ---
        if(this.state.choice.length === 0){

            //write to state array and localstorage if 1st time array is empty
            this.setState(state => {
                state.choice.push(data);
                cache.set(`quiz-${idQuiz}-${idQuestion}`,this.state.choice);
            })

        }else{

            //check if elemet exists on current array value
            const exists = this.state.choice.filter((item)=>{
                return (item.idAnswer === idAnswer && item.idQuestion === idQuestion)
            })/*.map((item)=>{
                return item;
            })*/

            if(exists.length > 0){
                //if element exists into array get it position and remove it form array
                let idx = (this.state.choice).findIndex(item => (item.idAnswer === idAnswer && item.idQuestion === idQuestion));
                this.state.choice.splice(idx, 1);

                //check how many elements exists in current elements array if array is empty remove it from localstorage value
                //else update current value
                if(this.state.choice.length === 0){
                    cache.forget(`quiz-${idQuiz}-${idQuestion}`,this.state.choice);
                }else{
                    cache.set(`quiz-${idQuiz}-${idQuestion}`,this.state.choice);
                }
            }else{
                // add to current elements array and update localstorage value
                this.setState(state => {
                    state.choice.push(data);
                    cache.set(`quiz-${idQuiz}-${idQuestion}`,this.state.choice);
                })
            }
        }

        return result;
    }//setMyState


    //--- ---


    render() {

        if (!this.state.answers) return false;
        const { idQuiz, idQuestion } = this.props;

        return (
            <div>
                {this.state.answers.map((row, i) => (
                    <div key={`c${i + 1}_${idQuestion}`} className="form-check">
                        {(typeof row.userAnswer === 'undefined') ? (<input
                            id={`c${i + 1}_${idQuestion}`}
                            name={`c${i + 1}_${idQuestion}`}
                            type="checkbox"
                            defaultChecked={this.isChecked(idQuiz, idQuestion, row.id, row.label)}
                            onChange={(e) => { this.setMyState(row.id, idQuestion, row.label, idQuiz); }}
                        />) : (<input
                            id={`c${i + 1}_${idQuestion}`}
                            name={`c${i + 1}_${idQuestion}`}
                            type="checkbox"
                            disabled={true}
                            defaultChecked={row.userAnswer}
                        />)
                        }
                        
                        <label htmlFor={`c${i + 1}_${idQuestion}`}>
                            {
                                (typeof row.userAnswer !== 'undefined') && 
                                ((parseInt(row.grade)>0) ?
                                    (<Icon className="m-0 p-0 mr-1 mb-1" color="success" icon="it-check-circle" />) :
                                    (<Icon className="m-0 p-0 mr-1 mb-1" color="danger" icon="it-error" />))
                            }
                            
                            {ReactHtmlParser(row.label)}
                        </label>
                    </div>
                ))}
            </div>
        );
    }
}

export default QuestionCheck;