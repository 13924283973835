import React, { Component } from 'react';
import { formHelper } from 'libs/helpers';
import './MRadioButton.css';

import {
    Input,
    FormGroup,
    FormText,
    Label
} from 'design-react-kit';

export class MRadioButton extends Component {

    onChange(event) {

        if (formHelper.isFunction(this.props.onChange)) {
            this.props.onChange(this.props.name, event.target.id);
        }

    }

    /**
     * PROPS: 
     * id       : identifier 
     * label    : example text (brief description of fields)     
     * infoText : addtional text used when the input in invalid
     * disabled : insert for readonly 
     * valid    : set  when the input is valid
     * invalid  : set  when the input is invalid
     * infoText : addtional text used when the input in invalid    
     * payload  : json of list of options (label,  value, infoText)
     */
    render() {

        const {
            id, label, invalid = null, valid = null,
            infoText, name, horizontal = false,
            value = null,
            payload = {},
            disabled = false, readOnly = false
        } = this.props;

        const { options = [] } = payload;
        const disable = disabled || readOnly;

        let vclassName = (valid) ? 'is-valid' : '';
        vclassName += (invalid) ? 'is-invalid' : '';

        const hclassName = (horizontal) ? 'horizontal' : '';

        return (
            <>
                <fieldset>
                    <legend className={`radio ${vclassName}`} >{label}</legend>
                    {infoText && (<small >{infoText}</small>)}
                    {options.map((item, i) => {

                        const defaultChecked = (value === item.value) ? true : false;

                        return (
                            <FormGroup key={`${id}-${i}`} check className={`form-check-group ${hclassName}`}>
                                <Input
                                    id={item.value}
                                    name={name}
                                    field={name}
                                    type="radio"
                                   
                                    checked={defaultChecked}
                                    disabled={disable}
                                    value={item.value}
                                    onChange={(event) => { this.onChange(event) }}
                                />
                                <Label for={item.value} check>{item.label}</Label>
                                {item.infoText && (<FormText color="">
                                    {item.infoText}
                                </FormText>)}
                            </FormGroup>);
                    }
                    )}
                </fieldset>
            </>
        );
    }
}