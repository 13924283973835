import React, { Component } from "react";

import { Helmet } from "react-helmet";
import { CardSolidarity } from "components/cards/card-solidarity/CardSolidarity";
import { SOLIDARITY__ITEMS } from "../../../assets/images/json/Solidarity";

import "./PageSolidarity.scss";

export class PageSolidarity extends Component {
  render() {
    const title = "Impegno Sociale e Solidarietà";
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </Helmet>
        {/* <Title>{title}</Title> */}
        <div className="pageSolidarity">
          <div className="pageSolidarity__wrapper">
            <div className="pageSolidarity__title">
              <h1 className="title">{title}</h1>
            </div>
            {/* <div className="pageSolidarity__paragraph">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div> */}
            <ul className="pageSolidarity__list-items">
              {SOLIDARITY__ITEMS.map((item, index) => (
                <li key={index} className="pageSolidarity__list-item">
                  <CardSolidarity
                    linkUrl={item.linkUrl}
                    targetBlank={item.targetBlank}
                    imgSrc={item.imgSrc}
                    imgAlt={item.imgAlt}
                    title={item.title}
                    subtitle={item.subtitle}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* <MSection>
          <Row>
            <Col xs={12} sm={4} md={4} className="box-text-center mt-4">
              <a
                href="/assets/doc/viamaquedacitta_brochure.pdf"
                target="_blank"
              >
                <img
                  className="w-70"
                  alt="viamaquedacitta"
                  src="/img/pages/viamaquedacitta.png"
                  title="viamaquedacitta"
                />
              </a>
            </Col>
            <Col xs={12} sm={4} md={4} className="box-text-center mt-4">
              <a href={`${ROUTES.PAGE_UNICEF}`}>
                <img
                  className="w-70"
                  alt="unicef"
                  src="/img/pages/unicef.jpg"
                  title="unicef"
                />
              </a>
            </Col>
            <Col xs={12} sm={4} md={4} className="box-text-center mt-4">
              <a
                href="/assets/doc/pieghevole_responsabilita_merito_Palermo_febbraio2016.pdf"
                target="_blank"
              >
                <img
                  className="w-70"
                  alt="Locandina"
                  src="/img/pages/Locandina.png"
                  title="Locandina"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={4} md={4} className="box-text-center mt-4">
              <img
                className="w-70"
                alt="BANNER-LEGALITA_2"
                src="/img/pages/BANNER-LEGALITA_2.png"
                title="BANNER-LEGALITA_2"
              />
            </Col>
            <Col xs={12} sm={4} md={4} className="box-text-center mt-4">
              <img
                className="w-70"
                alt="onlus"
                src="/img/pages/onlus.png"
                title="onlus"
              />
            </Col>
            <Col xs={12} sm={4} md={4} className="box-text-center mt-4">
              <img
                className="w-70"
                alt="BANNER_PREMIO_2014"
                src="/img/pages/BANNER_PREMIO_2014.png"
                title="BANNER_PREMIO_2014"
              />
            </Col>
          </Row>
          <Row>
            <Col className="box-text-center mt-4 ">
              <a href={`${ROUTES.PAGE_LEONI_SICANI}`}>
                <img
                  alt="leonisicani"
                  src="/img/pages/leonisicani.jpg"
                  title="leonisicani"
                />
              </a>
            </Col>
          </Row>
        </MSection> */}
      </>
    );
  }
}
