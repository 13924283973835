import React, { Component } from 'react';
import { Button, Icon, Progress } from 'design-react-kit';
import { keepAliveWorker, dateHelper } from 'libs';

export class TimeWatcher extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            metadata: this.props.metadata,
            keepAliveDuration: 0,
            showData: {
              percentage: 0,
              time: ''
            }
        };
    }

    timerKeepAlive = null;

    componentDidMount(){

        if(this.props.rCode === undefined) return false;

        /** init keepAliveDuration */
        setTimeout(() => {
          this.setState({
            keepAliveDuration: keepAliveWorker.getDuration(this.props.rCode)
          })
        }, 250);

        /** get keepAliveDuration */
        this.timerKeepAlive = setInterval(() => {
          this.setState({
            keepAliveDuration: keepAliveWorker.getDuration(this.props.rCode)
          })
        }, process.env.REACT_APP_KEEPINTERVAL);
        
        this.elapsedTime();
    }

    componentDidUpdate(prevProps, prevState){      
        if (prevState.keepAliveDuration !== this.state.keepAliveDuration) {
          this.elapsedTime();
        }
    }

    componentWillUnmount(){
      clearInterval(this.timerKeepAlive)      
    }

    elapsedTime = () =>{      
      const { keepAliveDuration, metadata } = this.state;
      //console.log((keepAliveDuration/metadata.duration)*100)
      //console.log((keepAliveDuration*100)/metadata.duration)
      const percentage = Number(((keepAliveDuration/metadata.duration)*100).toFixed(2));
      const time       = dateHelper.secondsToTime(keepAliveDuration);

      this.setState({
        showData:{
          percentage,
          time
        }
      })
    }//elapsedTime


    //--- ---


    render () {
      const { showData } = this.state;      

      return (
            <Button
              className="btn-progress mt-3"
              color="secondary"
              disabled
              icon={false}
              tag="button"
            >
              tempo trascorso {`${showData.time} (${showData.percentage}%)`} 
              <Icon
                color="light"
                icon="it-clock"
                padding={false}
                size=""
                style={{
                  ariaHidden: true
                }}
                className="ml-2"
              />
              <span>
                <Progress
                  indeterminate={false}
                  role="progressbar"
                  tag="div"
                  value={showData.percentage}
                />
              </span>
            </Button>
      )
    }
}