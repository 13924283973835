import React from "react";

import {
    FormEnabler,
    ValidatedForm,
    MInput,
    MToggle
} from "components/forms";

import ReactHtmlParser from 'react-html-parser';

import {
    payloadBuilder,
    invalidFieldsMapper,
    networkErrorHelper,    
    adminService,
} from "libs";

import {
    Card, CardBody, Button
} from 'design-react-kit';

import _ from 'lodash';

import {
    ModalHandler, BoxWarning, BoxInfo
} from "components/misc";

export class AdminCourceFormAnswer extends ValidatedForm {

    ERROR_MESSAGES = {
        label: "titolo della risposta non valido",
        grade: "Punteggio non valido"
    };

    emptyFields = {
        label: "",
        grade: "",
        fraction: false,
        feedback: "",
    };

    validation = {
        label: value => value !== null && value.length > 2,
        grade: value => /^[0-9.]+$/i.test(value),
        //order: value => /^[0-9]+$/i.test(value)
    };

    FIELDS_GROUP = [
        [
            {
                field: "label",
                label: "Testo della risposta",
                component: MInput,
                type: "text",
                className: "col-md-12"
            }
        ],
        [
            /*{
                field: "order",
                label: "Ordine",
                component: MInput,
                type: "text",
                className: "col-md-3 mt-2"
            },*/
            {
                field: "grade",
                label: "Punteggio",
                component: MInput,
                type: "text",
                className: "col-md-4 mt-2"
            },
            {
                field: "fraction",
                label: "la risposta è corretta?",
                component: MToggle,
                className: "col-md-5"
            }
        ],
        [
            {
                field: "feedback",
                label: "Suggerisci approfondimento",
                component: MInput,
                type: "text",
                className: "col-md-12"
            }
        ]
    ];

    //--- ---
    
    constructor(props) {
        super(props);

        this.state = {
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: this.props.formActive,
            idQuestion: parseInt(this.props.idQuestion) > 0 ? this.props.idQuestion : 0,
            idAnswer: null,
            showFormAddAnswer: true,
            answerValue: this.props.answerValue,
            model: this.props.model
        };

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(){
        if(this.state.answerValue !== null){
            let omitData = _.omit(this.state.answerValue[0], ['createdAt', 'updatedAt']);
            if(omitData.fraction === 'F'){
                omitData.fraction = false;
            }else{
                omitData.fraction = true;
            }
            
            this.setState({
                defaultValues: {...omitData}
            })
        }
    }//componentDidMount

    onCancel = () => {
        if(this.state.idAnswer === null){            
            this.setState({
                showFormAddAnswer: false
            })

            this.resetForm();
            this.props.showList();
        }
    }//onCancel
    
    //--- start functions to delete ---
    deleteRecord(e, value) {
        e.stopPropagation();
        ModalHandler.show(value, 'Eliminazione del dato',
            'vuoi davvero eliminare?', null, this.deleteConfirmed);
    }


    deleteConfirmed = (value) => {
        let payload = {
            id: value
        };

        let resAPI = null;

        if (this.state.model === 'Course') {
            resAPI = adminService.deleteAnswer(payload.id).then(({ data }) => {
                this.props.loadAllAnswer(data.payload.answer.idQuestion, this.state.model);
                this.onCancel();
        })
        }//Course

        if (this.state.model === 'repoQuiz') {
            resAPI = adminService.deleteRepoAnswer(payload.id).then(({ data }) => {
                this.props.loadAllAnswer(data.payload.answer.idQuestion, this.state.model);
                this.onCancel();
            })
        }//repoQuiz

        resAPI.catch(error => {
                console.log(error);
                if (networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });
    }
    //---- end functions to delete ----

    //--- ---

    saveRemote = () => {
        if(this.state.model === 'Course'){
            this.saveRemoteCourse()
        }

        if(this.state.model === 'repoQuiz'){
            this.saveRemoteRepoQuiz()
        }
    }//saveRemote

    saveRemoteCourse = () => {
        const payload = payloadBuilder(this.state);
        
        if (payload.id > 0) {

            if(payload.fraction === false){
                payload.fraction = 'F';
            }else{
                payload.fraction = 'V';
            }

            payload.grade = parseFloat(payload.grade);

            adminService.updateQuestionsAnswer(payload).then(
                (result) => {
                    this.onCancel();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        Object.keys(this.validation)
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });
            
        }else{

            payload.idQuestion = this.state.idQuestion;
            
            if(payload.fraction === false){
                payload.fraction = 'F';
            }else{
                payload.fraction = 'V';
            }

            payload.grade = parseFloat(payload.grade);

            adminService.insertQuestionsAnswer(payload).then(
                (result) => {
                    this.onCancel();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        Object.keys(this.validation)
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });
        }
    };//saveRemoteCourse


    saveRemoteRepoQuiz = () => {
        const payload = payloadBuilder(this.state);
        
        if (payload.id > 0) {
            
            if(payload.fraction === false){
                payload.fraction = 'F';
            }else{
                payload.fraction = 'V';
            }

            payload.grade = parseFloat(payload.grade);

            adminService.updateRepoQuestionsAnswer(payload).then(
                (result) => {
                    this.onCancel();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        Object.keys(this.validation)
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });
            
        }else{

            payload.idQuestion = this.state.idQuestion;
            
            if(payload.fraction === false){
                payload.fraction = 'F';
            }else{
                payload.fraction = 'V';
            }

            payload.grade = parseFloat(payload.grade);

            adminService.insertRepoQuestionsAnswer(payload).then(
                (result) => {
                    this.onCancel();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        Object.keys(this.validation)
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });
        }
    };//saveRemoteRepoQuiz



    //--- ---
    renderMode = (formActive, defaultValues) => {

        var { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);

        return (
            <>                
                {(this.state.showFormAddAnswer) && (
                <Card tag="div" className="card-big mt-0" >
                    <CardBody tag="div" className="card-body-inAccordion mt-0">
                        <form className="user">
                            {code}
                        </form>

                        <BoxInfo className="text-left mt-3">
                            <div className="font-weight-bold">Anteprima risposta:</div>
                            {ReactHtmlParser(defaultValues.label)}
                        </BoxInfo>

                        <BoxWarning className="text-left">
                        {ReactHtmlParser(`<b>NOTA su attribuzione del punteggio</b>:
                                            <ul>
                                            <li><b>domande a risposta singola o risposta aperta:</b>  il punteggio della risposta corretta deve essere uguale al punteggio della domanda;</li>
                                            <li><b>domande a risposta multipla:</b> il punteggio della domanda deve essere ripartito uniformemente tra le risposte corrette. 
                                            (esempio: Punteggio domanda 10, se 2 risposte corrette entrambi avranno punteggio 5);</li>
                                            </ul>`)}
                        </BoxWarning>

                        <div className="mt-0">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={this.onCancel}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />

                            {(parseInt(this.state.defaultValues.id) > 0) && (
                                <>
                                    <Button color="primary bg-dark" className="m-2"
                                        onClick={(e) => this.deleteRecord(e, this.state.defaultValues.id)}>
                                        Elimina
                                    </Button>

                                    <Button color="primary bg-dark" className="m-2"
                                        onClick={() => this.onCancel()}>
                                        Annulla
                                    </Button>
                                </>
                            )}
                        </div>
                    </CardBody>
                </Card>)}
            </>
        );
    }//renderMode


    //--- ---

    
    render() {

        let { formActive, defaultValues } = this.state;        
        let code = this.renderMode(formActive, defaultValues);

        return (
            <>
                {code}
            </>
        );
    }
}
