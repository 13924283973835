import { ROUTES } from "const";
export const COURSES_LIST = [
  {
    link: `/corso-online/CSU24CFU`,
    image: `/img/courses/new/24cfu.jpg`,
    title: "Corsi Singoli 24CFU",
    subTitle: "24CFU Corsi per l'insegnamento",
    highlights: "Da € 490",
  },
  {
    link: `/corso-online/MUMC24`,
    image: `/img/courses/new/master-24cfu2.jpeg`,
    title: "Master 24CFU",
    subTitle: "Comprensivo dei 24CFU per l'insegnamento",
    highlights: "",
  },
  {
    link: ROUTES.COURSE_SSD_BROWSE,
    image: `/img/courses/new/mr.jpg`,
    title: "Corsi Singoli e Master",
    subTitle: "A11, A18, A28, A21, A26, A46...",
    highlights: "Da €440 ed Esami Gratuiti in 100 città",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/UCLIL`,
    image: `/img/courses/new/clil.jpg`,
    title: "Perfezionamento CLIL",
    subTitle: "1500 ore e 60 CFU",
    highlights: "A soli € 498 con esami gratuiti in 100 città!",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/MU`,
    image: `/img/courses/new/mu.jpg`,
    title: "Master di I livello",
    subTitle: "1500 ore e 60 CFU",
    highlights: "Da €440 ed Esami Gratuiti in 100 città",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/BU-I`,
    image: `/img/courses/new/bu-i.jpg`,
    title: "Diplomi di perfezionamento biennale",
    // subTitle: "1500 ore e 60 CFU per ogni annualità",
    highlights: "Docenti di ruolo: punti 5 nei trasferimenti <br/>Precari: punti 2 (oltre ai 3 corsi annuali)",
  },

  {
    link: `${ROUTES.COURSE_BROWSE}/PDU`,
    image: `/img/courses/new/pd.jpg`,
    title: "Diplomi di perfezionamento post diploma",
    subTitle: "1500 ore e 60 CFU",
    highlights: "Da € 389 con esami gratuiti in 100 città!",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/PLU`,
    image: `/img/courses/new/plu.jpg`,
    title: "Diplomi di perfezionamento post laurea",
    subTitle: "1500 ore e 60 CFU",
    highlights: "Da € 389 con esami gratuiti in 100 città!",
  },
  {
    link: `${ROUTES.PAGE_CORSIDILAUREA}`,
    image: `/img/courses/new/corsi-di-laurea.jpg`,
    title: "Corsi di Laurea",
    subTitle:
      "<br/>Lauree VERE per un futuro lavorativo REALE: da Scienze Infermieristiche a Medicina!",
  },

  {
    link: `${ROUTES.COURSE_BROWSE}/ENG`,
    image: `/img/courses/new/eng.jpg`,
    title: "Inglese B2-C1",
    subTitle: "English Speaking Board (ESB)",
    highlights: "Da € 269",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/INFO`,
    image: `/img/courses/new/info.jpg`,
    title: "Certificazioni Informatiche",
    highlights: "Da € 98",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/MDU`,
    image: `/img/courses/new/mdu.jpg`,
    title: "Master di II livello",
    subTitle: "1500 ore e 60 CFU",
    highlights: "Esami Gratuiti in 100 città",
  },

  // {
  //   link: "https://www.asuniver.it/corso-di-sostegno-allestero/",
  //   image: `/img/courses/new/sostegno_spagna.jpg`,
  //   title: "Corso di Sostegno all’estero",
  // },
];

export const COURSE_SECONDARY_LIST = [
  {
    link: `/corso-online/ABA`,
    image: `/img/courses/new/aba-v2.jpg`,
    title: "ABA",
    subTitle: "Applied Behavior Analysis",
  },
  {
    link: `/corso-online/TDIDEM22`,
    image: `/img/courses/new/didattica-delle-emozioni.jpg`,
    title: "Didattica delle emozioni",
    subTitle: `Il metodo dell'educazione emotiva a scuola`,
  },
  {
    link: `/corsi-mnemosine/FC`,
    image: `/img/courses/new/formazione-continua-v2.jpg`,
    title: "Formazione continua",
    subTitle: "Valorizza il buono scuola con i nostri corsi",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/ATA`,
    image: `/img/courses/new/ata.jpg`,
    title: "Dattilografia e ICDL IT-Security",
  },
];
