import React, { Component } from "react";

import {
    Card,
    CardBody,
    Icon
} from 'design-react-kit';

import {
    dateHelper
} from "libs";


export class UserDetailProfile extends Component {


    render() {

        if (!this.props.data) return false;
        const { gender, dob, nationality, fiscalCode, phone, mobile, note } = this.props.data;

        const genderLabel = (gender !== null) ?
            (((gender.toLowerCase()) === 'm') ? 'Uomo' : 'Donna')
            : '';

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded border-bottom-card mb-4">
                    <div className="flag-icon" />
                    <div className="etichetta">
                        <Icon icon="it-user" padding={false} />
                        <span>Dati Anagrafici</span>
                    </div>
                    <CardBody>
                        <div><b>Genere:</b> {genderLabel}</div>
                        <div><b>Data di Nascita:</b> {(dob !== null) ? dateHelper.toITDate(dob) : ''}</div>
                        <div><b>Nazionalità:</b> {nationality}</div>
                        <div><b>Codice Fiscale:</b> {fiscalCode}</div>
                        <div><b>Telefono:</b> {phone}</div>
                        <div><b>Cellulare:</b> {mobile}</div>
                        <div><b>Note:</b> {note}</div>
                    </CardBody>
                </Card>
            </>
        );
    }

}
