import React, { Component } from "react";
import { ROUTES } from "const";
import { withRouter, NavLink } from "react-router-dom";
import { Button, Icon } from "design-react-kit";
import {
    BoxDanger,
    BoxSuccess,
    ErrorNotification,
    MnemoCallout,
    MnemoLoading,
    SupportoTecnico,
    Title
} from "components/misc";
import { certificationService, networkErrorHelper } from "libs";

class CourseSelfDeclaration extends Component {

    state = {
        loading: true,
        loadingError: false,
        rCode: '',
        request: false,
        requestError: '',
        requestResult: ''
    };

    componentDidMount() {

        const { rCode } = this.props.match.params;

        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        this.setState({ rCode: rCode, loading: false });
    }

    printCertificate = () => {

        this.setState({ loading: true });
        certificationService.gompGetDeclaration(this.state.rCode)
            .then(({ data }) => {

                if (data.payload && data.payload.isOK) {
                    const { response } = data.payload;

                    if (response.declarationFile && response.declarationFile.$value) {
                        const requestResult = `data:application/pdf;base64,${response.declarationFile.$value}`;
                        const filename = 'autodichiarazione_' + this.state.rCode + '.pdf';

                        let a = document.createElement('a');
                        a.href = requestResult;
                        a.download = filename;
                        a.click();
                        this.setState({ requestError: '', request: true });
                    } else {
                        const requestError = "Si è verificato un errore durante l'elaborazione della richeista. Contattare il supporto tecnico. Dettagli errore: " + response.errorDescription;
                        ErrorNotification.render(requestError, 'errore');
                        this.setState({ requestError, requestResult: '', request: true });
                    }
                }

                this.setState({ loading: false });
            })
            .catch(error => {
                console.log(error);
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
                const requestError = "Si è verificato un errore durante l'elaborazione della richeista. Contattare il supporto tecnico. Dettagli errore: " + networkErrorHelper.getErrorMessage(error);
                this.setState({ loading: false, requestError, requestResult: '', request: true });
            });
    }

    render() {

        const { loading, loadingError, rCode, request, requestError } = this.state;

        if (loadingError) return (<BoxDanger className="m-5">Si è verificato un errore durante l'elaborazione della richiesta,
            se il problema persiste puoi  <SupportoTecnico /></BoxDanger>);

        return (<>
            {(loading) && (<MnemoLoading />)}
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
            <Title>Autocertificazione d'iscrizione</Title>
            <img className="unicamillus-banner" alt="Unicamillus" src="https://www.formazionedocenti.it/files/images/Unicamillus_logo.png" />
            <MnemoCallout >
                <h5>Nota importante sui certificati</h5>
                <hr />
                A seguito della legge n. 183/2011 i certificati possono essere presentati solo a enti privati, e non possono essere richiesti da pubbliche amministrazioni o enti privati gestori di pubblici servizi.
                Pertanto, qualora si renda necessario dover attestare atti o fatti relativi alla propria carriera universitaria a un ente pubblico o a privati gestori di pubblici servizi, lo studente è tenuto a presentare un'autocertificazione, che sostituisce il certificato a tutti gli effetti di legge.
                <hr />
                I certificati restano validi e utilizzabili nei rapporti con i privati in Italia. La firma autografa è omessa ai sensi dell'art. 3 co. 2 del Decreto Legislativo 12 febbraio 1993 n. 39.
                I certificati uso estero, in italiano e in inglese, devono invece essere stampati dalla segreteria studenti e firmati in originale da appositi funzionari con firma depositata in Prefettura.
            </MnemoCallout>
            {(!request) ? (<>
                <Button color="primary" className="m-5 bg-dark" onClick={this.printCertificate}>Scarica autodichiarazione d'iscrzione</Button>
            </>) : (<>
                {(requestError) ? (<>
                    <BoxDanger>{requestError}</BoxDanger>
                </>) : (<>
                    <BoxSuccess>File correttamente generato e scaricato, controlla nei tuoi download</BoxSuccess>
                </>)}
            </>)}
        </>)
    }
}

export default withRouter(CourseSelfDeclaration);