import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { blogService, networkErrorHelper } from 'libs';
import { BlogArticleItem, BlogPager, BlogSidebar } from 'components/blog';
import { MnemoLoading, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { Helmet } from 'react-helmet';

class BlogHome extends Component {

    state = {
        slug: 0,
        category: 0,
        articles: [],
        loading: true,
        loadingError: false,

        currentPage: 1,
        totalPages: 1,
        dimPage: 10
    };

    getTotalPages(count) {

        const totalPages = (count % this.state.dimPage) ? (Math.floor(count / this.state.dimPage) + 1) : (count / this.state.dimPage);
        return totalPages;
    }

    componentDidMount() {
        // get slug code from parameter
        const { slug, page = 1 } = this.props.match.params;
        this.setState({ slug });
        if (slug) {
            this.loadRemoteBySlug(slug, page);
        } else {
            this.loadRemote(page);
        }
    }

    componentDidUpdate(){
        const { slug, page = 1 } = this.props.match.params;
        if(slug !== this.state.slug){
            this.setState({ slug });
            this.loadRemote(page);
        }
    }

    //--- ---

    loadRemote(page) {

        blogService.articleCount(0)
            .then(({ data }) => {

                const totalPages = this.getTotalPages(data.payload.count);
                this.setState({
                    totalPages
                });

                this.loadArticles(page);
            })
            .catch(error => {
                console.log(error);
                // TODO !?
            });
    }

    loadRemoteBySlug(slug, page) {

        blogService.category(slug)
            .then(({ data }) => {

                const { category } = data.payload;
                const totalPages = this.getTotalPages(category.count);
                this.setState({
                    category,
                    totalPages
                });

                this.loadArticlesByCat(slug, page);

            })
            .catch(error => {
                console.log(error);

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });
    }

    loadArticles(currentPage) {

        this.setState({
            loading: true,
            loadingError: false
        });

        blogService.lastArticles(currentPage, this.state.dimPage)
            .then(({ data }) => {

                const { articles } = data.payload;

                this.setState({
                    currentPage,
                    articles,
                    loading: false,
                    loadingError: false
                });
            })
            .catch(error => {
                console.log(error);

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                } else {
                    // TODO: handle 404 error
                }

                this.setState({ loading: false, loadingError: true });
            });
    }

    loadArticlesByCat(slug, currentPage) {

        this.setState({
            loading: true,
            loadingError: false
        });

        blogService.lastArticlesByCat(slug, currentPage, this.state.dimPage)
            .then(({ data }) => {

                const { articles } = data.payload;
                this.setState({
                    currentPage,
                    articles,
                    loading: false,
                });
            })
            .catch(error => {
                console.log(error);

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });
    }

    onChangePage = (newPage) => {

        if (newPage > 0 && newPage <= this.state.totalPages) {
            this.setState({ currentPage: newPage });
        }
    }

    render() {

        const { loading, slug, category, articles, totalPages, currentPage } = this.state;

        if (loading) return (<MnemoLoading></MnemoLoading>);

        const title = slug ? ReactHtmlParser(category.title) : 'Blog';
        const found = (articles && articles.length > 0) ? true : false;

        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <Title>{title}</Title>
                <section className="m-3 clearfix">
                    <Row>
                        <Col md={8}>
                            {(found > 0)
                                ?
                                (<>
                                    <div className='articles'>
                                        {articles.map((article, i) => (
                                            <BlogArticleItem key={`article-${i}`}
                                                article={article}
                                            ></BlogArticleItem>
                                        ))}
                                    </div>
                                    <div>
                                        <BlogPager
                                            slug={slug}
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onChangePage={this.onChangePage}
                                        /></div>
                                </>)
                                : (<><p>TODO: Non ci sono articoli</p></>)}
                        </Col>
                        <Col md={4}>
                            <BlogSidebar />
                        </Col>
                    </Row>
                </section>
            </>);
    }
}

export default withRouter(BlogHome);