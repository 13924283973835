import React from "react";
import { ROUTES, TITLEREQUEST_STEPS } from 'const';
import { Row, Col, Callout, CalloutTitle, CalloutText, Button } from 'design-react-kit';
import { ErrorNotification } from "components/misc";
import { dateHelper, networkErrorHelper, certificationRequestService } from "libs";

export class CertificationRequestPay {

    title = 'Pagamento';
    iconName = 'it-card';
    userProfile = null;
    certificationRequest = null;
    certificationRequestMNEMO = null;
    paymentMNEMO = null;
    paymentGOMP = null;
    course = null;
    canNext = false;
    _formRef = null;

    constructor() {

        this._formRef = React.createRef();
    }

    setPayload(payload) {
        this.certificationRequest = payload.certificationRequest;
        this.certificationRequestMNEMO = payload.certificationRequestMNEMO;
        this.user = payload.user;
        this.userProfile = payload.userProfile;
        this.paymentMNEMO = payload.paymentMNEMO;
        this.paymentGOMP = payload.paymentGOMP;
    }

    goLink = (link) => {
        window.location = link;
    }

    async onNextStep() {

        this.canNext = false;
        if ((this.paymentMNEMO && this.paymentMNEMO.status === 'Approvato')
            && (this.paymentGOMP && this.paymentGOMP.paymentReceipt)) {
            this.canNext = await this.updateRemote();
        } else {
            ErrorNotification.render('E\' necessario che i due pagamenti risultino approvati prima di poter procedere con il download della certificazione', 'Impossible continuare');
        }

        return this.canNext;
    }


    async updateRemote() {


        let retCode = false;

        this.certificationRequest.currentStep = TITLEREQUEST_STEPS.DOWNLOAD;

        await certificationRequestService.updateRequestStep(this.certificationRequest)
            .then(() => {

                retCode = true;
            })
            .catch(errors => {
                console.log(errors);
                retCode= false;
                networkErrorHelper.notify(errors);
            });

        return retCode;
    };

    renderPaymentMNEMO() {

        const item = this.paymentMNEMO;

        if (item) {

            const approved = (item.status === 'Approvato' || item.status === 'InApprovazione');

            return (<Row>
                <Col size="12">
                    <Callout className="border border-light rounded my-2 p-3" tag="div">
                        <CalloutTitle tag="h6">
                            {(item.status === 'Approvato') ? (<>
                                <span className='badge m-0 p-2 badge-success'>{item.status}</span>
                            </>) : (<>
                                <span className='badge m-0 p-2 badge-warning'>{item.status}</span>
                            </>)
                            }
                        </CalloutTitle>

                        <CalloutText bigText={false} tag="div">
                            {(approved) ?
                                (<>
                                    <p><b>Importo pagato:</b> {item.importPaied}</p>
                                    <p><b>Data:</b> {dateHelper.toITDate(item.datePaied)}</p>
                                    <p><b>modalità:</b> {item.paymentType}</p>
                                </>) : (<>
                                    <p><b>Importo da pagare:</b> {item.importToPay}</p>
                                    <Button color="primary bg-dark" className="ml-2 mt-2"
                                        onClick={() => this.goLink(`${ROUTES.MU_PAYMENT_REQUEST}/Certification/${this.certificationRequest.registrationCode}`)} >
                                        Procedi col pagamento
                                    </Button>
                                </>)}
                        </CalloutText>
                    </Callout>
                </Col>
            </Row>);
        }
    }

    renderPaymentGOMP() {

        const item = this.paymentGOMP;

        if (item) {
            const approved = item.paymentReceipt;

            return (<Row>
                <Col size="12">
                    <Callout className="border border-light rounded my-2 p-3" tag="div">
                        <CalloutTitle tag="h6">
                            {(approved) ? (<>
                                <span className='badge m-0 p-2 badge-success'>Approvato</span>
                            </>) : (<>
                                <span className='badge m-0 p-2 badge-warning'>Da pagare</span>
                            </>)
                            }

                            Bolli virtuali
                        </CalloutTitle>

                        <CalloutText bigText={false} tag="div">
                            {(approved) ?
                                (<>
                                    <Button color="primary bg-dark" className="ml-2 mt-2" >
                                        <a download={`ricevuta_pagamento-bolli-virtuali.pdf`}
                                            href={`data:application/pdf;base64,${item.paymentReceipt}`}
                                            className="link-white" title='Scarica ricevuta di pagamento' >Scarica la ricevuta</a>
                                    </Button>
                                </>) : (<>
                                    <Button color="primary bg-dark" className="ml-2 mt-2" >
                                        <a download={`avviso_pagamento-bolli-virtuali.pdf`}
                                            href={`data:application/pdf;base64,${item.invoice64}`}
                                            className="link-white" title='Scarica avviso di pagamento' >Scarica avviso di pagamento</a>
                                    </Button>
                                    <Button color="primary bg-dark" className="ml-2 mt-2"
                                        onClick={() => this.goLink(item.paymentLink)} >
                                        Avvia il pagamento
                                    </Button>
                                </>)}
                        </CalloutText>
                    </Callout>
                </Col>
            </Row>);
        }
    }

    render() {

        const paymentMnemo = this.renderPaymentMNEMO();
        const paymentGOMP = this.renderPaymentGOMP();

        return (
            <>
                <div className="pt-5">
                    <p>Per proseguire è necessario che i seguenti pagamenti siano entrambi approvati.</p>
                    {paymentMnemo}
                    {paymentGOMP}
                </div>

            </>);

    }
}