import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import { courseService, dateHelper, networkErrorHelper } from "libs";
import { BoxDanger, MnemoLoading, MSection, SupportoTecnico } from "components/misc";
import { Row, Col } from 'design-react-kit';
import { CourseShopper } from "components/enrollment";
import { CourseLandingPageGetRates } from './CourseLandingPageGetRates';
import { MTable } from "components/table";
import { Helmet } from "react-helmet";

class CourseLandingPage extends Component {

    state = {
        courseCode: 0,
        courseModules: [],
        course: 0,
        loading: true,
        loadingError: false
    };

    tableConfig = {
        columns: [
            {
                id: 'title',
                Header: 'Titolo modulo',
                accessor: 'title',
            },
            {
                id: 'ssd',
                Header: 'SSD',
                accessor: 'ssd',
            },
            {
                id: 'cfu',
                Header: 'CFU',
                accessor: 'cfu',
            },
        ],
    }

    componentDidMount() {
        // get registration code from parameter
        let { courseCode } = this.props.match.params;
        courseCode = this.chekCourse(courseCode);
        this.setState({ courseCode });
        this.loadRemote(courseCode);
    }

    chekCourse(courseCode) {

        const currentAA = parseInt(process.env.REACT_APP_AA_YEAR.slice(-2));
        // get last two chars
        const courseYear = parseInt(courseCode.slice(-2));

        // verify if a number
        if (courseYear > 0) {

            if (courseYear < currentAA) {
                courseCode = courseCode.slice(0, -2) + currentAA.toString();
            }
        }

        return courseCode;
    }


    loadRemote(courseCode) {

        courseService.getCourseModules(courseCode)
            .then(({ data }) => {
                this.setState({
                    courseModules: data.payload,
                });
            })
            .catch(error => {
                console.log(error);
            });


        const currentAA = parseInt(process.env.REACT_APP_AA_YEAR);

        courseService
            .getByCodeAndAA(courseCode, currentAA)
            .then(({ data }) => {
                this.setState({
                    courseCode,
                    course: data.payload.course,
                    loading: false,
                    loadingError: false
                });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                } else {
                    this.setState({ loadingError: true });
                }

                this.setState({ loading: false });
            });
    }

    getTitleRequired(course) {

        let titleRequired = '';
        let contentRequired = '';

        if (course.laurea && (course.courseType === 'MD' || course.courseType === 'MDU')) {
            titleRequired = "REQUISITO MINIMO PER L'ACCESSO: LAUREA";
            contentRequired = "L'accesso a questi corsi è riservato a chi è in possesso di una  di una qualsiasi Laurea almeno Quadriennale (vecchio ordinamento) o Laurea Specialistica (3 + 2) o titoli Equipollenti.";

        } else if (course.laurea && course.courseType === 'MU') {
            titleRequired = "REQUISITO MINIMO PER L'ACCESSO: LAUREA TRIENNALE";
            contentRequired = "L'accesso ai Master Annuali di I livello è riservato a chi è in possesso di Laurea triennale, quadriennale, quinquennale o equiparate (con opportuna documentazione che ne comprovi l'equiparazione), nonché coloro che sono in possesso delle Lauree Specialistiche o titoli equipollenti nel rispetto della normativa vigente.";

        } else if (course.laurea && course.courseType === 'BU-I') {
            titleRequired = "REQUISITO MINIMO PER L'ACCESSO: LAUREA TRIENNALE";
            contentRequired = "L'accesso è riservato a chi è in possesso di Laurea triennale, quadriennale, quinquennale o equiparate (con opportuna documentazione che ne comprovi l'equiparazione).";

        } else if (course.laurea) {
            titleRequired = "REQUISITO MINIMO PER L'ACCESSO: LAUREA TRIENNALE";
            contentRequired = "L'accesso a questi corsi è riservato a chi è in possesso di una Laurea Triennale, Quadriennale, Quinquennale o equiparate (con opportuna documentazione che ne comprovi l'equiparazione).";

        } else if (course.diploma) {
            titleRequired = "REQUISITO MINIMO PER L'ACCESSO: DIPLOMA FINALE DI SCUOLA SUPERIORE";
            contentRequired = "L'accesso a questi corsi è riservato a chi è in possesso di un diploma finale di scuola superiore o equiparati (con opportuna documentazione che ne comprovi l'equiparazione).";
        } else {
            titleRequired = "REQUISITO MINIMO PER L'ACCESSO: NESSUN TITOLO DI STUDIO ";
            contentRequired = "L'accesso a questi corsi non richiede un titolo di studio";
        }

        return { titleRequired, contentRequired }
    }

    render() {

        const { loading, loadingError, course, courseCode, courseModules } = this.state;

        if (loading) return (<MnemoLoading></MnemoLoading>);
        if (loadingError) return (<BoxDanger>Si è verificato un errore durante
            il caricamento dei dati del corso, se il problema persiste puoi <SupportoTecnico /></BoxDanger>);

        const scoring = ReactHtmlParser(course.scoring);
        const summary = ReactHtmlParser(course.summary);
        const furtherInfo = ReactHtmlParser(course.furtherInfo);
        const examInfo = ReactHtmlParser(course.examInfo);
        const enrollDeadline = dateHelper.toITDate(course.enrollDeadline);

        const institute = ReactHtmlParser(course.institute);
        const { titleRequired, contentRequired } = this.getTitleRequired(course);

        const { isLoggedIn } = this.props;

        return (
            <>
                <Helmet>
                    <title>{course.title}</title>
                    <meta property="og:title" content={course.title} />
                </Helmet>
                <h3 className="p-3">{ReactHtmlParser(course.title)} ({course.code})</h3>
                <hr />
                {(course.enrollEnabled) &&
                    (
                        <>
                            <div className="enroll-btn p-3">
                                <h3><a href="#enroll_section">Clicca qui per iscriverti</a></h3>
                            </div>
                        </>
                    )}
                <section className="course_section">
                    <Row>
                        <Col lg={4} md={6} xs={12}>
                            <div className="box-title">
                                <img
                                    src="/img/icon/icon_sheet.png"
                                    alt="Mnemosine"
                                />
                                <h6>{course.code}</h6>
                            </div>
                            <div>
                                {summary}
                            </div>
                        </Col>

                        <Col lg={4} md={6} xs={12}>
                            <div className="box-title">
                                <img
                                    src="/img/icon/icon_istitute.png"
                                    alt="Mnemosine"
                                />
                                <h6>CORSI EROGATI DA</h6>
                            </div>
                            <div >
                                {institute}
                                <p><b><span className="text-danger">Scadenza Iscrizione:  {enrollDeadline}</span></b></p>
                            </div>
                        </Col>
                        <Col lg={4} md={6} xs={12}>
                            <div className="box-title">
                                <img
                                    src="/img/icon/icon_certificate.png"
                                    alt="Mnemosine"
                                />
                                <h6>{titleRequired}</h6>
                            </div>
                            <div>
                                {contentRequired}
                            </div>
                        </Col>
                        {(course.price) && (
                            <Col lg={4} md={6} xs={12}>
                                <CourseLandingPageGetRates price={course.price} courseCode={courseCode} showRate={course.showRate} />
                            </Col>
                        )}
                        {(scoring.length > 0) && (
                            <Col lg={4} md={6} xs={12}>
                                <div className="box-title">
                                    <img
                                        src="/img/icon/icon_center.png"
                                        alt="Mnemosine"
                                    />
                                    <h6>VALUTAZIONE e PUNTEGGIO</h6>
                                </div>
                                <div className="box-content-left">
                                    {scoring}
                                </div>
                            </Col>
                        )}
                        <Col lg={4} md={6} xs={12}>
                            <div className="box-title">
                                <img
                                    src="/img/icon/icon_monitor.png"
                                    alt="Mnemosine"
                                />
                                <h6>INFORMAZIONI UTILI</h6>
                            </div>
                            <div className="box-content">
                                <ul className="list-left">
                                    <li><b>Scadenza Iscrizione: <span className="text-danger"> {enrollDeadline}</span></b></li>
                                    {(course.enrollSheet) && (<li><a className="link-dark" href={course.enrollSheet} rel="noopener noreferrer" target="_blank">Scheda Sintetica</a></li>)}
                                    {(course.enrollManifesto) && (<li><a className="link-dark" href={course.enrollManifesto} rel="noopener noreferrer" target="_blank">Bando di Partecipazione</a></li>)}
                                    {(course.enrollModality) && (<li><a className="link-dark" href={course.enrollModality} rel="noopener noreferrer" target="_blank">Modalità di iscrizione</a></li>)}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    {(course.showStudyPlan && courseModules && courseModules.length > 0) && (
                        <MSection className="mb-2">
                            <h6>PIANO DI STUDI</h6><hr />
                            <MTable tableData={courseModules} tableConfig={this.tableConfig} />
                        </MSection>
                    )}
                    {(furtherInfo.length > 0) && (<><MSection className="mb-2"> {furtherInfo}</MSection></>)}
                    {(examInfo.length > 0) && (<><MSection className="mb-2"> <h6>SEDE ESAMI</h6><hr />{examInfo}</MSection></>)}
                </section>
                {(course.enrollEnabled) &&
                    (<section name="enroll_section" id="enroll_section" className="course_section">
                        <CourseShopper plugin={course.plugin}
                            isLoggedIn={isLoggedIn}
                            enrollManifesto={course.enrollManifesto}
                            courseCode={courseCode}
                            courseTitle={course.title}
                        >
                        </CourseShopper>
                    </section>)}
            </>)
    }
}

export default withRouter(CourseLandingPage);