import React from 'react';
import { formHelper } from 'libs/helpers';
import { Button } from 'design-react-kit';
import { SupportoTecnico } from 'components/misc';

export const FormEnabler = ({ isFormActive, onToggle, onCancel = null, onSubmit = null,
    hideSave = false, hideEdit = false, disableSave = false, isNew = false }) => {

    onCancel = formHelper.isFunction(onCancel) ? onCancel : formHelper.emptyFunction;
    onSubmit = formHelper.isFunction(onSubmit) ? onSubmit : formHelper.emptyFunction;

    return isFormActive ? (
        <>
            {!hideSave &&
                <Button disabled={disableSave} color="primary bg-dark" className="mx-2 mt-6 float-right" onClick={() => onSubmit()}>Salva</Button>
            }
            <Button color="primary" outline className="mx-2 mt-6 float-right" onClick={() => onCancel()}>
                Annulla
            </Button>
        </>
    ) : (
        <>
            {(hideEdit && !isNew) ? 
            (
                <><hr />
                    <div>Dati non modificabili. Se hai riscontrato degli errori nei dati inseriti <SupportoTecnico /></div>
                </>
            ) : (
                <Button color="primary bg-dark" className="mx-2 mt-6 float-right" onClick={() => onToggle()}>{isNew ? (<>Inserisci</>) : (<>Modifica</>)} </Button>
            )  
            }
        </>
    );
}