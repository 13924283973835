import { ROUTES } from "const";

export const SOLIDARITY__ITEMS = [
  {
    linkUrl: `${ROUTES.PAGE_LEONI_SICANI}`,
    targetBlank: false,
    imgSrc: "/img/pages/leonisicani.jpg",
    imgAlt: "A.S.D. Leoni Sicani",
    title: "Associazione A.S.D. LEONI SICANI - ONLUS",
    subtitle: "Regalare un sorriso",
  },
  {
    linkUrl: "/assets/doc/viamaquedacitta_brochure.pdf",
    targetBlank: true,
    imgSrc: "/img/pages/viamaquedacitta.png",
    imgAlt: "unicef dalla parte dei bambini in pace e in guerra",
    title: "I Palazzi di via Maqueda in festa",
    subtitle: "Si aprono alla città le porte della bellezza e della storia",
  },
  {
    linkUrl: `${ROUTES.PAGE_UNICEF}`,
    targetBlank: false,
    imgSrc: "/img/pages/unicef.jpg",
    imgAlt: "unicef dalla parte dei bambini in pace e in guerra",
    title: "Unicef",
    subtitle: "Dalla parte dei bambini in pace e in guerra",
  },
  {
    linkUrl:
      "/assets/doc/pieghevole_responsabilita_merito_Palermo_febbraio2016.pdf",
    targetBlank: true,
    imgSrc: "/img/pages/Locandina.png",
    imgAlt:
      "Responsabilità e merito nel mondo antico. Retorica, giustizia, società",
    title: "Convegno - Responsabilità e merito nel mondo antico",
    subtitle: "Retorica, giustizia, società",
  },
  {
    linkUrl: "",
    targetBlank: false,
    imgSrc: "/img/pages/BANNER-LEGALITA_2.png",
    imgAlt: "l'alba della legalità assieme all'ass. libera",
    title: "L'Alba della Legalità assieme all'Ass. Libera",
    subtitle: "la 3° edizione della manifestazione",
  },
  {
    linkUrl: "",
    targetBlank: false,
    imgSrc: "/img/pages/onlus.png",
    imgAlt: "Meter onlus di don fortunato di noto",
    title: "Meter Onlus di Don Fortunato di Noto",
    subtitle: "A Tutela dell'Infanzia",
  },
  {
    linkUrl: "",
    targetBlank: false,
    imgSrc: "/img/pages/BANNER_PREMIO_2014.png",
    imgAlt: "XI Edizione del premio letterario G. Tomasi da Lampedusa",
    title: "G. Tomasi Di Lampedusa",
    subtitle: "XI Edizione del Premio Letterario",
  },
];
