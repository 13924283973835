import React, { Component } from 'react';

import { BoxSuccess, MSection, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { Helmet } from 'react-helmet';


export class BenefitsClil extends Component {

    render() {
        const title = 'AGEVOLAZIONI CLIL';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title>{title}</Title>
                            <hr />
                            <h6><b>Costi Corsi CLIL e Certificazione lingua Inglese</b></h6>
                            <ul>
                                <li>Solo Corso di Perfezionamento CLIL: € 498</li>
                                <li>Solo Esame B2: € 329</li>
                                <li>Corso ed Esame B2: € 449</li>
                                <li>Solo Esame C1: € 389</li>
                                <li>Corso ed Esame C1: €549</li>
                                <li>Solo Esame C2: Non proponiamo il corso e la certificazione livello C2 poiché altamente ardua e richiede delle competenze da madrelingua.</li>
                            </ul>
                            <hr />
                            <h6><b>Agevolazioni</b></h6>
                            <ol type="a">
                                <li>Corso Perfezionamento CLIL + solo Esame B2 da 827 a <b>€ 712</b> (Sconto 35%)</li>
                                <li>Corso Perfezionamento CLIL + Corso ed Esame B2 da 947 a <b>€ 812</b> (Sconto 30%)</li>
                                <li>Corso Perfezionamento CLIL + solo Esame C1 da 887 a <b>€ 751</b> (Sconto 35%)</li>
                                <li>Corso Perfezionamento CLIL + Corso ed Esame C1 da 1.047 a <b>€ 882</b> (Sconto 30%)</li>
                            </ol>
                            <hr />
                            <p>
                                Per usufruire dell'agevolazione economica (percentuale di sconto sulla certificazione)
                                riferita alla contemporanea iscrizione al "Corso di Perfezionamento Universitario sulle metodologie CLIL" e
                                alla "Certificazione lingua Inglese B2 o C1" inviare email a <a
                                    href="mailto:info@formazionedocenti.it">Info@formazionedocenti.it</a> nella quale si deve
                                specificare a quale soluzione si è interessati.
                            </p>
                            <hr />
                            <h6><b>Valutazione</b></h6>
                            <ul>
                                <li>Punti 6,00 nelle GPS se Corso di Perfezionamento CLIL è congiunto a certificazione B2;</li>
                                <li>Punti 7,00 nelle GPS se Corso di Perfezionamento CLIL è congiunto a certificazione C1;</li>
                                <li>Punti 9,00 nelle GPS se Corso di Perfezionamento CLIL è congiunto a certificazione C2;</li>
                                <li>Punti 3,00 per il Corso di Perfezionamento CLIL nelle Graduatorie ad Esaurimento di Terza e Quarta Fascia;</li>
                                <li>Punti 1,00 per il Corso di Perfezionamento CLIL nelle GPS, nei Trasferimenti/Mobilità docenti di Ruolo, nelle Graduatorie perdenti posto docenti di Ruolo;</li>
                                <li>Punti 1,00 per il Corso di Perfezionamento CLIL negli Incarichi Dirigenti Scolastici;</li>
                                <li>Da punti 0,50 a punti 3,00 Concorso Dirigenti Scolastici.</li>
                            </ul>
                            <hr />
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}