import React, { Component } from "react";

import {
    MnemoLoading,
    ModalHandler
} from 'components/misc';

import {
    Row,
    Accordion,
    AccordionHeader,
    AccordionBody,
    Col
} from 'design-react-kit';

import {
    adminService,
    learningService,
} from "libs";

import ReactHtmlParser from 'react-html-parser';

import { AdminCourseFormQuizQuestion } from "components/forms/admin/AdminCourseFormQuizQuestion";
import { AdminCourseFormQuizListAnswer } from "components/forms/admin/AdminCourseFormQuizListAnswer";
import "./AdminCourseFormQuiz.css";


export class AdminCourseFormQuiz extends Component{
    
    constructor(props) {
        super(props);

        this.state = { 
            idResource: parseInt(this.props.uriParams.idresource) > 0 ? this.props.uriParams.idresource : 0,
            idQuiz: 0,
            dataQuizQuestion: null,
            defaultAccordionState: {},
            idQuestion: null,
            rndKey: 0,
            loading:true,
            model: this.props.model,
            idRepoQuiz: parseInt(this.props.uriParams.idRepoQuiz) > 0 ? this.props.uriParams.idRepoQuiz : 0,
        };
    }

    componentDidMount(){
        if(this.state.model === 'Course' && this.state.idResource > 0){
            //--- get idQuiz if exists by idResource ---
            learningService.quizByIDResource(this.state.idResource).then(({ data }) => {
                if(!data){return false;}
                this.setState({idQuiz: data.payload.id});
                this.loadQuestions(data.payload.id);;
            })
        }
        
        if(this.state.model === 'repoQuiz' && this.state.idRepoQuiz > 0){
            this.loadQuestions(this.state.idRepoQuiz);
        }
    }//componentDidMount

    //--- start mounter ---
    loadQuestions = (idQuiz) => {
        let resAPI = null;

        if(this.state.model === 'Course'){
            resAPI = adminService.quizAllQuestion(idQuiz).then(({ data }) => {
                    this.setState({
                        dataQuizQuestion: data.payload.quizQuestion,
                        loading:false
                    })
                    
                    //--- update UI ---
                    this.props.reloadCounter(data.payload.quizQuestion.length);
            })
        }//Course

        if(this.state.model === 'repoQuiz'){
            resAPI = adminService.quizRepoAllQuestion(idQuiz).then(({ data }) => {                
                this.setState({
                    dataQuizQuestion: data.payload.repoQuizQuestions,
                    loading:false
                })
                
                //--- update UI ---
                this.props.reloadCounter(data.payload.repoQuizQuestions.length)
            })
        }//repoQuiz

        resAPI.catch(error => {
            //networkErrorHelper.notify(error);
            //console.log(error);
            this.setState({loading:false});
        });
    }//loadQuestions
    //--- end mounter ---

    accordionToggle = (idRow, idQuestion, idQuiz) => {        
        this.setState(prevState => {
            let defaultAccordionState = { ...prevState.defaultAccordionState }; //creating copy of state variable
            defaultAccordionState[`collapse${idQuestion}`] = !this.state.defaultAccordionState[`collapse${idQuestion}`]; //update the name property, assign a new value
            return { defaultAccordionState }; //return new full object
        })

        this.setState({
            idQuestion: idQuestion,
            rndKey: Math.floor((Math.random() * 1000) + 1)
        })
    }//accordionToggle   

    //--- start functions to delete procedure ---
    deleteRecord(e, value) {
        e.stopPropagation();
        ModalHandler.show(value, 'Eliminazione del corso', 'vuoi davvero eliminare il corso?', null, this.deleteConfirmed);
    }

    deleteConfirmed = (value) => {
        let payload = {
            id: value
        };

        let resAPI = null;
        
        if(this.state.model === 'Course'){
            resAPI = adminService.deleteQuestion(payload.id).then(({ data }) => {
                return data.payload.question.idQuiz;
            })
        }//Course
        
        if(this.state.model === 'repoQuiz'){
            resAPI = adminService.deleteRepoQuestion(payload.id).then(({ data }) => {
                return data.payload.repoQuiz.idQuiz;
            })
        }//repoQuiz

        resAPI
        .then((idQuiz)=>{
            this.setState({ loading: true });
            this.loadQuestions(idQuiz);
        }).catch(error => {
            console.log(error);
            /*if (networkErrorHelper.is404(error)) {
                networkErrorHelper.notify(error);
            }*/
        });
    }//deleteConfirmed
    //--- end functions to delete procedure ---
    

    renderQuestion = () =>{

        const {loading, dataQuizQuestion, defaultAccordionState, model   } = this.state;

        return (
            <>
            {loading ? (<MnemoLoading></MnemoLoading>) : (
            <Accordion className="mt-4">
            {
                (dataQuizQuestion).map((item, x) => (
                    <div key={`div-${x}`}>
                    <AccordionHeader key={`acc-h-${x}`} className="collapse-header-inAccordion" active={defaultAccordionState[`collapse${x}`]} onToggle={() => this.accordionToggle(x, item.id, item.idQuiz)}>
                        <b>{ReactHtmlParser(item.label)}</b>
                    </AccordionHeader>

                    <AccordionBody key={`acc-b-${x}`} active={(parseInt(this.props.idQuestion) === item.id) ? !defaultAccordionState[`collapse${item.id}`] : defaultAccordionState[`collapse${item.id}`]}>
                        <Row>
                            <Col sm={12} lg={12}>
                                <AdminCourseFormQuizQuestion 
                                    key={`acfqq-${item.id}-${x}`} 
                                    model={model} idQuestion={item.id} 
                                    questionValue={item} formActive={false} 
                                    loadQuestions={this.loadQuestions}
                                    deleteRecord={(e) => this.deleteRecord(e, item.id)}
                                />
                            </Col>
                            <Col sm={12} lg={12} className="header-answer-card-wrapper text-center">
                                <h4>Risposte</h4>
                                <AdminCourseFormQuizListAnswer model={model} idQuestion={item.id} answerType={item.type}/>
                            </Col>
                        </Row>
                    </AccordionBody>
                    </div>
                ))                   
            }
            </Accordion>)
        }
        </>
        )
    }//renderQuestion


    
    render() {

        if(this.state.dataQuizQuestion === null){return false;}
        let  accordion = this.renderQuestion();

        return (
            <>
                {accordion}
            </>
        );
    }

}

