import React from "react";
import { TitleRequestDownloadForm } from "./";

export class TitleRequestDownload {

    title = 'DONWLOAD';
    iconName = 'it-file';
    titleRequest = null;
    
    setPayload(payload) {

        this.titleRequest = payload.titleRequest;
        this.titleRequestMNEMO = payload.titleRequestMNEMO;
        this.titleRequestGOMP = payload.titleRequestGOMP;
    }    

    async onNextStep() {
        return false;
    }

    render() {

        return (<>
           
           <TitleRequestDownloadForm
                registrationCode={this.titleRequestMNEMO.registrationCode}
                requestID={this.titleRequest.id}
            />

        </>);
    }
}