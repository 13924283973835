import React, { Component } from "react";

import { MobileValidation } from "components/tools";
import { MCheckbox } from "components/forms";
import { BoxDanger, MnemoLoading, ModalHandler, PDFView, SupportoTecnico } from "components/misc";
import { networkErrorHelper, titleRequestService } from "libs";

export class TitleRequestSignForm extends Component {

    state = {
        documentError: false,
        documentLoad: true,
        loading: true,
        confirmedMobile: false,
        accept: true,
        accept_service: true,
        alertMessages: [],
    };

    componentDidMount() {

        this.loadDocument(this.props.requestData.titleRequest.id);
    }

    loadDocument(requestId) {

        titleRequestService.prepareSign(requestId)
            .then(({ data }) => {

                const { titleRequestFile } = data.payload;
                //update after 3 seconds: because the document in S3 isn't istantly available 
                setTimeout(() => { this.setState({ loading: false, loadingError: false, titleRequestFile }) }, 3000);
            })
            .catch(errors => {
                console.log(errors);
                networkErrorHelper.notify(errors);
                this.setState({ loading: false, loadingError: true });
            });
    }

    validate() {

        const { accept, accept_service, confirmedMobile } = this.state;
        let alertMessages = [];

        if (!accept_service) {
            alertMessages.push('è necessario prestare il  consenso all\'utilizzo del servizio di Firma Elettronica Avanzata');
        }
        if (!accept) {
            alertMessages.push('è necessario acconsentire a sottoscrivere il documento');
        }
        if (!confirmedMobile) {
            alertMessages.push('è necessario premere il tasto "Invia codice", inserire il codice ricevuto via SMS e premere il tasto "Conferma codice"');
        }

        this.setState({ alertMessages });

        let isValid = accept && accept_service && confirmedMobile;
        return isValid;
    }

    onChangeAccept = (_, value) => {

        this.setState({ accept: value });
    }

    onChangeAccept_service = (_, value) => {

        this.setState({ accept_service: value });
    }

    textFEA() {

        return (
            <>
                <h6>Condizioni di utilizzo del servizio di firma elettronica Avanzata</h6>
                <p>Per la sottoscrizione della documentazione  Mnemosine propone l'utilizzo del nuovo servizio di firma elettronica Avanzata
                    (il <b>Servizio</b>). Nel rispetto della normativa in materia di formazione di documenti informatici mediante Firma Elettronica Avanzata
                    La informiamo di quanto segue:</p>
                <ul>
                    <li>prestando il Suo consenso all'utilizzo del Servizio, nei rapporti con Mnemosine, Lei acconsente a sottoscrivere la domanda di iscrizione mediante firma elettronica avanzata tramite l'inserimento di un codice di sblocco (di seguito "One Time Password"/OTP) inviato via SMS al Suo cellulare;</li>
                    <li>la connessione univoca della Sua firma ed al controllo esclusivo sul sistema di generazione della stessa sono garantiti dal codice OTP inviato via SMS al suo cellulare fornito in fase di registrazione al servizio. L'uso dell'OTP è condizione necessaria per l'apposizione della Sua firma. </li>
                    <li>a sottoscrizione avvenuta, Le verrà inviato via posta elettronica all'indirizzo da Lei indicato un file in formato pdf contenente il documento da Lei (e un timbro digitale) sottoscritto; </li>
                    <li>le modalità di firma così apposte avranno valore ad ogni effetto di legge; </li>
                    <li>le caratteristiche tecniche del Servizio sono in seguito riportate; </li>
                    <li>in caso di perdita dei documenti informatici sottoscritti e consegnati, Lei le potrà richiederle a Mnemosine; </li>
                    <li>Lei potrà in qualsiasi momento revocare il consenso all'utilizzo del Servizio mediante la compilazione dell'apposita modulistica messa a disposizione; </li>
                    <li>Mnemosine adotterà tutte le misure necessarie ad assicurare la massima protezione dei Suoi dati utilizzati per la firma; </li>
                </ul>
                <p>Ai sensi del Codice dell'Amministrazione Digitale (D.lgs. n. 235 del 30 dicembre2010), il documento informatico sottoscritto con le predette modalità e che presenti i requisiti richiesti dalla normativa di riferimento, ha l'efficacia prevista dall'art.2702 cod. civ. (Efficacia della scrittura privata).</p>
                <h6>Caratteristiche tecniche del Servizio</h6>
                <p>La FEA è l'equivalente digitale di una tradizionale firma autografa ed ha lo stesso valore legale. Con la FEA è possibile sottoscrivere i contratti dal proprio smartphone, tablet o PC senza la necessità di stamparli, firmarli ed inviarli tramite posta, semplificando la gestione documentale.  Il servizio di FEA è attuato nel rispetto delle vigenti normative in materia.</p>
                <p>L'apposizione della firma elettronica sul documento informatico avviene con l'inserimento di un Codice Dispositivo di 6 cifre (One Time Password), che avvia il processo di firma crittografica del documento, necessario a rendere il contratto elettronico integro, non modificabile, leggibile ed autentico.</p>
                <p>Al fine di garantire l'integrità dei documenti informatici, in termini di non modificabilità ed inalterabilità del loro contenuto, dopo la sottoscrizione da parte del Cliente, vengono convertiti in formato PDF. Il processo di FEA può ritenersi concluso solo quando il Cliente visualizza il messaggio informativo di completamento dell'operazione. Il codice Dispositivo ottenuto di cui solo il cliente dispone, garantisce il controllo esclusivo da parte dello stesso processo di generazione della FEA. <br />
                    Il processo di firma è il seguente:</p>
                <ul>
                    <li>il Cliente viene identificato attraverso il caricamento del documento d'identità in corso di validità;</li>
                    <li>il Cliente riceverà sul proprio numero di cellulare un codice dispositivo identificativo univoco (One Time Password) da inserire per poter completare in totale sicurezza il processo di firma.</li>
                </ul>
                <p>Il Cliente dopo il completamento della firma elettronica potrà effettuare il download della documentazione contrattuale firmata che riceverà per email. In ogni caso potrà sempre reperire la documentazione contrattuale nella propria Area Riservata.<br />
                    La FEA garantisce: </p>
                <ul>
                    <li>la connessione univoca della firma al firmatario: calcola per il documento generato dalla transazione, prima dell'apposizione sullo stesso della firma, una stringa univoca di numeri;</li>
                    <li>il controllo esclusivo del firmatario del sistema di generazione della firma;</li>
                    <li>l'immodificabilità del documento informatico sottoscritto con l'apposizione della firma;</li>
                    <li>la connessione univoca della firma al documento sottoscritto. </li>
                </ul>
            </>);
    }

    showFEA = (e) => {
        e.stopPropagation();
        ModalHandler.show(null, 'Firma Elettronica Avanzata', this.textFEA(), null, null, 'Chiudi', null, true, false);
    }

    onConfirmed = () => {
        this.setState({ confirmedMobile: true });
    }

    onDocumentError = () => {
        this.setState({ documentError: true, documentLoad: false });
    }

    render() {

        const { validatorProps, user, userProfile } = this.props.requestData;
        const { alertMessages, loading, loadingError, titleRequestFile } = this.state;

        if (loading) return (<MnemoLoading />);
        if (loadingError) return (<BoxDanger>Si è verificato un errore durante i caricamento del modulo, <SupportoTecnico /></BoxDanger>);

        const file = { url: process.env.REACT_APP_API_URL + '/titleRequest/download/' + titleRequestFile.id };

        return (<>
            <h5>Modulo di richiesta Certificati finali</h5>
            <hr />
            <PDFView file={file.url} />
            <h5>Conferma la tua identità</h5>
            <hr />
            <MobileValidation {...validatorProps} onConfirmed={this.onConfirmed} ></MobileValidation>
            <h5>La tua firma include</h5>
            <hr />
            Nome firmatario: <b>{user.name} {user.surname}</b>
            <br />Codice fiscale: <b>{userProfile.fiscalCode}</b>
            <br />E-mail: <b>{user.email}</b>
            <br />Numero di cellulare: <b>{userProfile.mobile}</b>           
            <hr />
            <MCheckbox onChange={this.onChangeAccept_service}
                id="accept_service" name="accept_service" readOnly={false} value={true} disabled={false} >
                <p>Presto il mio consenso all'utilizzo del servizio di Firma Elettronica Avanzata e di accettare le
                    <span className="text-green" onClick={(e) => this.showFEA(e)}><b> condizioni del servizio</b></span>
                </p>
            </MCheckbox>
            <MCheckbox onChange={this.onChangeAccept}
                id="accept" name="accept" readOnly={false} value={true} disabled={false} >
                <p>Cliccando su <b>Firma</b> acconsente a sottoscrivere in formato elettronico il documento
                </p>
            </MCheckbox>
            {(alertMessages.length > 0) && (<>
                <BoxDanger>
                    <ul>
                        {alertMessages.map((message, i) => (
                            <li key={i} >{message}</li>)
                        )}
                    </ul>
                </BoxDanger>
            </>)}

        </>);
    }
}
