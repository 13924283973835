import React, { Component } from "react";
import { ROUTES } from "const";
import { Row, Col } from "design-react-kit";
import "./HomeCourses.css";
import { ButtonPrimary } from "components/ui/buttons/primary/ButtonPrimary";
// import { HomeCoursesGrid } from "components/grids/home-courses/HomeCoursesGrid";
// import { SlideMoreCourses } from "components/sliders/slider-more-courses/SliderMoreCourses";
// import { FEATURED_HOME_COURSES } from "assets/images/json/FeaturedHomeCourses";
// import { MORE_HOME_COURSES } from "assets/images/json/MoreHomeCourses";

const COURSE_BOXES = [
  {
    link: `/corso-online/CSU24CFU`,
    image: `/img/courses/new/24cfu.jpg`,
    title: "Corsi Singoli 24CFU",
    subTitle: "24CFU Corsi per l'insegnamento",
    highlights: "Da € 490",
  },
  {
    link: `/corso-online/MUMC24`,
    image: `/img/courses/new/master-24cfu2.jpeg`,
    title: "Master 24CFU",
    subTitle: "Comprensivo dei 24CFU per l'insegnamento",
    highlights: "",
  },
  {
    link: ROUTES.COURSE_SSD_BROWSE,
    image: `/img/courses/new/mr.jpg`,
    title: "Corsi Singoli e Master",
    subTitle: "A11, A18, A28, A21, A26, A46...",
    highlights: "Da €440 ed Esami Gratuiti in 100 città",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/UCLIL`,
    image: `/img/courses/new/clil.jpg`,
    title: "Perfezionamento CLIL",
    subTitle: "1500 ore e 60 CFU",
    highlights: "A soli € 498 con esami gratuiti in 100 città!",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/MU`,
    image: `/img/courses/new/mu.jpg`,
    title: "Master di I livello",
    subTitle: "1500 ore e 60 CFU",
    highlights: "Da €440 ed Esami Gratuiti in 100 città",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/BU-I`,
    image: `/img/courses/new/bu-i.jpg`,
    title: "Diplomi di perfezionamento biennale",
    // subTitle: "1500 ore e 60 CFU per ogni annualità",
    highlights:
      "Docenti di ruolo: punti 5 nei trasferimenti <br/>Precari: punti 2 (oltre ai 3 corsi annuali)",
  },

  {
    link: `${ROUTES.COURSE_BROWSE}/PDU`,
    image: `/img/courses/new/pd.jpg`,
    title: "Diplomi di perfezionamento post diploma",
    subTitle: "1500 ore e 60 CFU",
    highlights: "Da € 389 con esami gratuiti in 100 città!",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/PLU`,
    image: `/img/courses/new/plu.jpg`,
    title: "Diplomi di perfezionamento post laurea",
    subTitle: "1500 ore e 60 CFU",
    highlights: "Da € 389 con esami gratuiti in 100 città!",
  },

  {
    link: `${ROUTES.PAGE_CORSIDILAUREA}`,
    image: `/img/courses/new/corsi-di-laurea.jpg`,
    title: "Corsi di Laurea",
    subTitle:
      "<br/>Lauree VERE per un futuro lavorativo REALE: da Scienze Infermieristiche a Medicina!",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/ENG`,
    image: `/img/courses/new/eng.jpg`,
    title: "Inglese B2-C1",
    subTitle: "English Speaking Board (ESB)",
    highlights: "Da € 269",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/INFO`,
    image: `/img/courses/new/info.jpg`,
    title: "Certificazioni Informatiche",
    highlights: "Da € 98",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/MDU`,
    image: `/img/courses/new/mdu.jpg`,
    title: "Master di II livello",
    subTitle: "1500 ore e 60 CFU",
    highlights: "Esami Gratuiti in 100 città",
  },

  // {
  //   link: "https://www.asuniver.it/corso-di-sostegno-allestero/",
  //   image: `/img/courses/new/sostegno_spagna.jpg`,
  //   title: "Corso di Sostegno all’estero",
  // },
];

const COURSE_BOXES_SMALL = [
  {
    link: `/corso-online/ABA`,
    image: `/img/courses/new/aba-v2.jpg`,
    title: "ABA",
    subTitle: "Applied Behavior Analysis",
  },
  {
    link: `/corso-online/TDIDEM22`,
    image: `/img/courses/new/didattica-delle-emozioni.jpg`,
    title: "Didattica delle emozioni",
    subTitle: `Il metodo dell'educazione emotiva a scuola`,
  },
  {
    link: `/corsi-mnemosine/FC`,
    image: `/img/courses/new/formazione-continua-v2.jpg`,
    title: "Formazione continua",
    subTitle: "Valorizza il buono scuola con i nostri corsi",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/ATA`,
    image: `/img/courses/new/ata.jpg`,
    title: "Dattilografia e ICDL IT-Security",
  },
];
export class HomeCourses extends Component {
  componentDidMount() {}

  render() {
    return (
      <>
        {/* <HomeCoursesGrid courses={FEATURED_HOME_COURSES} />
        <SlideMoreCourses courses={MORE_HOME_COURSES} /> */}

        <section className="programmes-single">
          {/*<a href="/corso-online-formazione/PFU5CFU" className="programmes-single__content">*/}
            <div className="programmes-single__wrapper">
              <h2 className="programmes-single__title">
                Percorso di Formazione di 5 CFU DM 108/2022 - <span className="text-red text-hightlights span-price"> € 150</span>
              </h2>
              <h6 className="text-highlights">
                Riservato ai Docenti vincitori del "Concorso straordinario Bis"{" "}
                <span className="small">
                - esame in presenza in <strong>100 città</strong> (ripetibile gratuitamente) come previsto dall’art. 18 comma 6 del DM 108/22.
                Corso erogato dall’Università UniCamillus L. R. ed autorizzato con Decreto del 28/11/2017 pubblicato in Gazzetta Ufficiale n. 3 del 04/01/2018.<br/>
                </span>
                <span className="small">
                Il corso, erogato su tutto il territorio nazionale, non avrà avvio prima del mese di gennaio 2023 e si concluderà, come previsto dalla norma, non oltre il 15 giugno 2023.<br/>
                </span>
                <span className="small">
                  Il costo massimo di iscrizione (tasse incluse) non supererà € 150 per ciascun/a docente che vi prenderà parte (prezzo calmierato secondo quanto stabilito dal decreto).
                </span>
              </h6>
              {/* <h6 className="text-red text-highlights">da € 390</h6> */}
              {/*<div className="programmes-single__button">
                <ButtonPrimary
                  color="blue"
                  label="Maggiori informazioni"
                  href="/corso-online-formazione/PFU5CFU"
                />
      </div>*/}
            {/* <a
              href="https://www.usrlazio.it/index.php?s=1052&wid=12038"
              target="_blank"
              ><b>Vedi Nota</b></a> */}
            <a
              href="/adesioni-5cfu"
              ><b>Se Interessato/a, aderisci alla Newsletter sul corso e ti forniremo tutte le informazioni</b></a>
            </div>
            {/* <div className="programmes-single__background">
              <img src="/img/courses/new/5cfu.jpeg" />
            </div> */}
          {/*</a>*/}
        </section>

        <section className="programmes">
          <div className="swiper-container">
            <div className="swiper-wrapper">
              <Row>
                {COURSE_BOXES.map((course, i) => (
                  <Col xs={12} sm={6} md={4} lg={4} key={`course-${i}`}>
                    <div className="swiper-slide">
                      <div className="programme-card">
                        <a className="programme-card__link" href={course.link}>
                          <div className="programme-card__header">
                            <img
                              className="programme-card__img"
                              alt={course.title}
                              src={course.image}
                            />
                          </div>
                          <div className="programme-card__body">
                            <h4 className="programme-card__title">
                              {course.title}
                              {course.subTitle && (
                                <>
                                  <br />
                                  {/* <span className="small">
                                    {course.subTitle}
                                  </span> */}
                                  <span
                                    className="small"
                                    dangerouslySetInnerHTML={{
                                      __html: course.subTitle,
                                    }}
                                  />
                                </>
                              )}
                            </h4>
                            {course.highlights && (
                              <>
                                <h6
                                  className="text-red  text-highlights"
                                  dangerouslySetInnerHTML={{
                                    __html: course.highlights,
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </a>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </section>
        <section className="programmes">
          <h2 className="programmes-title">Altri Corsi</h2>
          <div className="swiper-container">
            <div className="swiper-wrapper">
              <Row>
                {COURSE_BOXES_SMALL.map((course, i) => (
                  <Col xs={12} sm={6} md={4} lg={3} key={`course-${i}`}>
                    <div className="swiper-slide">
                      <div className="programme-card">
                        <a className="programme-card__link" href={course.link}>
                          <div className="programme-card__header">
                            <img
                              className="programme-card__img"
                              alt={course.title}
                              src={course.image}
                            />
                          </div>
                          <div className="programme-card__body">
                            <h4 className="programme-card__title">
                              {course.title}
                              {course.subTitle && (
                                <>
                                  <br />
                                  <span className="small">
                                    {course.subTitle}
                                  </span>
                                </>
                              )}
                            </h4>
                            {course.highlights && (
                              <>
                                <h6 className="text-red">
                                  {course.highlights}
                                </h6>
                              </>
                            )}
                          </div>
                        </a>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </section>
      </>
    );
  }
}
