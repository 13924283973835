import React, { Component } from "react";

import { ButtonPrimary } from "components/ui/buttons/primary/ButtonPrimary";

import "./CardNews.scss";

export class CardNews extends Component {
  /* 
        props:
        image
        date
        text
        link
    */

  render() {
    return (
      <a className="cardNews" href={this.props.link}>
        <div className="cardNews__image">
          <img src={this.props.image} alt={this.props.text} />
        </div>
        <div className="cardNews__content">
          <p className="date">{this.props.date}</p>
          <div className="text__caption">
            <p className="text">{this.props.text}</p>
          </div>
        </div>
        <div className="cardNews__action">
          <ButtonPrimary label="Leggi" />
        </div>
      </a>
    );
  }
}
