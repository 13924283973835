import React, { Component } from 'react';

import { BoxSuccess, MSection, Title } from 'components/misc';
import {
    Row, Col, Icon
} from 'design-react-kit';
import { Helmet } from 'react-helmet';


export class Doctorate extends Component {

    render() {
        const title = 'DOTTORATI DI RICERCA UNIVERSITARIO';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title >{title}</Title>

                            <h5><Icon icon="it-check" />accesso alla carriera Universitaria</h5>
                            <h5><Icon icon="it-check" />3 anni di congedo retribuito</h5>

                            <h3>Adesioni Aperte</h3>
                            <hr />
                            <p className='mt-0 mb-0'>Per il nono anno consecutivo, l’Associazione Mnemosine ed Asuniver s.r.l. propongono Dottorati di Ricerca all’estero
                                ed in Italia validi ai sensi delle norme vigenti.</p>
                            <p className='mt-0 mb-0'><b>Complessivamente sono previsti 20 posti.</b></p>
                            <p className='mt-0 mb-0'>Tali percorsi scaturiscono da specifici accordi con varie università Statali Spagnole.</p>
                            <p className='mt-0'>Tutti gli interessati possono inviare il <a href="/assets/doc/modulo_adesione_dottorato_di_ricerca.pdf" target="_blank">“Modulo di Adesione”</a> al seguente indirizzo email: <a href="mailto:adesioni@formazionedocenti.it">adesioni@formazionedocenti.it</a>.</p>

                            <h3>Faq inerenti il Dottorato di ricerca all’estero</h3>
                            <hr />
                            <p className='mt-0 mb-0'><b>D1.</b> Il Dottorato di ricerca proposto è valido?</p>
                            <p className='mt-0'><b>R.</b> Noi proponiamo solo Dottorati di Ricerca validi. Moltissimi iscritti hanno conseguito il titolo che è stato già dichiarato equipollente dal MIUR al titolo di Dottore di Ricerca dell’ordinamento Universitario Italiano.</p>

                            <p className='mt-0 mb-0'><b>D2.</b> Quali sono le linee di investigazione seguite dai Dottorati di ricerca nei percorsi da voi proposti?</p>
                            <p className='mt-0'><b>R.</b> Vi sono parecchie linee investigative: “Educazione”, “Immigrazione”, “Psicologia”, “Educazione Motoria”, ecc…</p>

                            <p className='mt-0 mb-0'><b>D3.</b> Il Dottorato di ricerca all’estero consente di usufruire del <b>Congedo Straordinario?</b></p>
                            <p className='mt-0 mb-0'><b>R.</b> Può usufruire di 3 anni di congedo straordinario retribuito
                                solo se tale percorso è già riconosciuto dal MIUR. Se non vi è il parere favorevole MIUR allora il Dirigente Scolastico non
                                può decretare il congedo.</p>
                            <p className='mt-0 mb-0'>Ad esempio, riportiamo la posizione del MIUR ai percorsi proposti da una <b>Multinazionale di Malta:</b></p>
                            <p className='mt-0 mb-0'>Oggetto: Richiesta parere su applicazione dell’art. 2 della legge 476/1984
                                per frequenza dottorato di ricerca presso la ……………<b>International (Malta)</b></p>
                            <p className='mt-0 mb-0'>“In riferimento alla richiesta pervenuta con prot. ……… del ……… Luglio 2017, relativa a informazioni
                                sul titolo di dottore di ricerca rilasciato da ………….International con sede nella Repubblica di Malta, si comunica quanto segue.</p>
                            <p className='mt-0'>Allo stato attuale, appare difficile a questa Amministrazione
                                esprimere un parere riguardo ad un corso di dottorato erogato in tele-didattica, offerto da una istituzione
                                straniera di nuova creazione, a fronte della mancanza almeno di una coorte di un primo ciclo dottorale.
                                Premesso quanto sopra, questa Amministrazione ritiene di non poter esprimere alcun preventivo parere sul futuro riconoscimento di titoli conseguiti presso la suindicata istituzione straniera e si riserva di chiedere sul titolo finale il previsto parere al CUN ed eventualmente all’ANVUR per gli aspetti di rispettiva competenza…..”</p>

                            <p className='mt-0 mb-0'><b>D4.</b> Anche senza parere può essere concesso il congedo straordinario?</p>
                            <p className='mt-0'><b>R.</b> No. Come da recenti Ordinanze del Tribunale e come da nota MIUR Ufficio Scolastico Regionale per l’Emilia Romagna (<a href="#attachment">vedasi allegati</a>), per la <b>concessione del congedo straordinario il parere MIUR deve essere “favorevole”</b>.</p>

                            <p className='mt-0 mb-0'><b>D5.</b> E se erroneamente il Dirigente decreta il congedo senza parere favorevole del MIUR cosa succede?</p>
                            <p className='mt-0'><b>R.</b> Potrebbe configurarsi come abuso d’ufficio perseguibile per legge oltre ovviamente al danno erariale di cui ne risponderà economicamente e penalmente nelle opportune sedi.
                                Pensiamo ai Docenti di un determinato Istituto che “non vedono di buon occhio” l’operato del Dirigente Scolastico e che, venuti a conoscenza di tale situazione, denunciano il capo d’Istituto sia al Dirigente Ambito Territoriale sia alla Procura della Repubblica ed alla Corte dei Conti ciascuno per gli accertamenti del caso. Il Dirigente Scolastico potrebbe subire conseguenze inimmaginabili.</p>

                            <p className='mt-0 mb-0'><b>D6.</b> I Dottorati di ricerca proposti chi li eroga?</p>
                            <p className='mt-0'><b>R.</b> I Dottorati sono erogati solo da Università Statali della Spagna.</p>

                            <p className='mt-0 mb-0'><b>D7.</b> Essi sono riconosciuti in Italia?</p>
                            <p className='mt-0'><b>R.</b> Si, anche a seguito della “Dichiarazione Congiunta della Conferenza dei Rettori delle Università Italiane (CRUI) e Spagnola (CRUE)”. I Dottorati già conseguiti sono stati dichiarati dal MIUR equipollenti al titolo di Dottore di Ricerca dell’ordinamento Universitario Italiano.</p>

                            <p className='mt-0 mb-0'><b>D8.</b> Con il Dottorato di ricerca è possibile accedere ai PAS?</p>
                            <p className='mt-0'><b>R.</b> Secondo l’intesa MIUR-Sindacati dell’11 Giugno 2019 è possibile accedere direttamente ai PAS (<a href="/assets/doc/Intesa MIUR-Sindacati.pdf" target="_blank">vedasi nota</a>).</p>

                            <p className='mt-0 mb-0'><b>D9.</b> Da quando li proponete? Hanno il parere preventivo favorevole dal MIUR?</p>
                            <p className='mt-0'><b>R.</b> Li proponiamo da oltre 6 anni ed i percorsi hanno ottenuto il preventivo parere favorevole MIUR.</p>

                            <p className='mt-0 mb-0'><b>D10.</b> Cosa si deve fare per accedere al Dottorato di Ricerca da voi proposto.</p>
                            <p className='mt-0'><b>R.</b> Deve inoltrare il modulo di adesione compilato in tutte le sue parti e successivamente le invieremo le modalità di accesso e di immatricolazione al Dottorato di Ricerca.</p>

                            <p className='mt-0 mb-0'><b>D11.</b> Quanti posti sono disponibili?</p>
                            <p className='mt-0'><b>R.</b> Mediamente 20 posti e l’accesso è a numero chiuso.</p>
                            <a name="attachment"></a>
                            <BoxSuccess>
                                <h6>Allegati inerenti la concessione del Congedo Straordinario come da nota M.I.U.R. e varie Ordinanze del Tribunale</h6>
                                <hr />
                                <p><a href="/assets/doc/Nota-MIUR.pdf" target='_blank'><Icon icon="it-check" />Nota MIUR</a></p>
                                <p><a href="/assets/doc/Decreto_di_rigetto_se_non_parere_favorevole_MIUR.pdf" target='_blank'><Icon icon="it-check" />Decreto di rigetto se non c’è parere favorevole MIUR</a></p>
                                <p><a href="/assets/doc/Ordinanza_collegiale_su_dottorato_di_ricerca_Malta_e_Slovenia.pdf" target='_blank'><Icon icon="it-check" />Ordinanza collegiale su dottorato di ricerca Malta e Slovenia</a></p>
                            </BoxSuccess>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}