import React, { Component } from "react";
import { Provider } from "react-redux";
import { ConnectedRouter as Router } from "connected-react-router";

import { initStore, history } from "./store";
import Main from "./Main";

import MessengerCustomerChat from "react-messenger-customer-chat";

import { statCookie } from "libs/helpers/cookieHelper";

const store = initStore();

export class App extends Component {
  render() {
    statCookie();
    return (
      <Provider store={store}>
        <Router history={history}>
          <Main />
        </Router>
        <div id="modal-notification"></div>
        <MessengerCustomerChat
          pageId="245574945460633"
          appId="289740609936792"
          htmlRef={window.location.pathname}
          language="it_IT"
        />
      </Provider>
    );
  }
}
