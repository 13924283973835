import React from "react";

import {
    FormEnabler,
    ValidatedForm,
    MTextArea,
    MInput
} from "components/forms";

import {
    payloadBuilder,
    invalidFieldsMapper,
    networkErrorHelper,
    notNullObjectBuilder,
    adminService,
} from "libs";

import { BoxSuccess, ErrorNotification } from 'components/misc';

import {
    Card,
    CardBody,
    Row, Col
} from 'design-react-kit';

import { PRESET_METADATA_QUIZ } from '../../../const';

const FIELDS_GROUP = [
    [
        {
            id:"metadata",
            field: "metadata",
            label: "Metadata - Configurazione Quiz/Test",
            component: MTextArea,
            rows: 6,
            className: "col-md-12 mb-4"
        }
    ],
    [
        {
            id:"maxGrade",
            field: "maxGrade",
            label: "Punteggio Massimo",
            component: MInput,
            type: "number",
            value: 100,
            className: "col-md-6"
        },
        {
            id:"minRate",
            field: "minRate",
            label: "Punteggio Minimo",
            component: MInput,
            type: "number",
            value: 60,
            className: "col-md-6"
        }
    ]
];

export class AdminCourseFormQuizMetadata extends ValidatedForm {

    ERROR_MESSAGES = {
        metadata: "La configurazione presenta degli errori.",
        maxGrade: "Necessario indicare un Punteggio Massimo valido.",
        minRate: "Necessario indicare un Punteggio Minimo valido."
    };

    emptyFields = {
        metadata: '',
        maxGrade: 100,
        minRate: 60
    };

    validation = {
        /*metadata: value => {
            return JSON.parse(value) ? true : false
        }*/
        metadata: value => value.length > 3,
        maxGrade: value => /^[0-9.]+$/i.test(value),
        minRate: value => /^[0-9.]+$/i.test(value),
    };

    //--- ---
    constructor(props) {
        super(props);

        this.state = {
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formValid: false,
            formActive: true,
            idResource: this.props.idResource,
            showSuccess: false
        };

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }


    loadRemote(additionalState = {}) {
        adminService.quizByIDParent(this.state.idResource)
            .then(( data ) => {
                
                if(!data.data.payload.quiz){return false;}
                data.data.payload.quiz.metadata = JSON.stringify(data.data.payload.quiz.metadata, undefined, 4);

                const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.data.payload.quiz
                );
               
                const newState = {
                    defaultValues,
                    ...additionalState
                };

                this.setState(newState);
            })
            .catch(error => {
                //networkErrorHelper.notify(error);
                console.error(error);
            });
    }//loadRemote


    saveRemote = () => {
        const payload  = payloadBuilder(this.state);
        let jpMetaData = '';

        try {
            jpMetaData = JSON.parse(payload.metadata);
        } catch(e) {
            //networkErrorHelper.notify();
            const title   = "Errore durante l'elaborazione della richiesta";
            const message = "Configurazione metaData errata.";
            ErrorNotification.render(message, title);
            return false
        }

        payload.metadata  = JSON.stringify(jpMetaData);
        delete payload.createdAt
        delete payload.updatedAt
        delete payload.questions

        if(!payload.hasOwnProperty('id')){            
            payload.idParent = parseInt(this.state.idResource);

            adminService.insertQuiz(payload).then(
                (result) => {
                    this.setState({
                        formActive: !this.state.formActive,
                        showSuccess: true
                    })

                    //set current idQuiz
                    payload.id = result.data.payload.quiz.id;

                    setTimeout(() => {
                        this.setState({
                            showSuccess: false
                        })                    
                    }, 6000);
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            }); 
        }else{
            adminService.updateQuiz(payload).then(
                (result) => {
                    this.setState({
                        formActive: !this.state.formActive,
                        showSuccess: true
                    })

                    setTimeout(() => {
                        this.setState({
                            showSuccess: false
                        })                    
                    }, 6000);
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });            
        }
    };

    setMetadataPreset = (preset) =>{
        this.setState({
            ...this.emptyFields,
        })
        this.setState({
            formValid: true,
            formActive: true,
            defaultValues:{
                ...this.state.defaultValues,
                metadata: preset
            }
        })
    }//setMetadataPreset

    //--- ---

    render() {

        let { formActive, defaultValues } = this.state;        
        let { formValid, code } = this.renderFields(FIELDS_GROUP, defaultValues, formActive);

        if(this.state.formValid){
            formValid = true;
        }

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <CardBody tag="div">

                        {(this.state.showSuccess) && <BoxSuccess>Configurazione salvata</BoxSuccess>}

                        <form className="user" id="quizMetadataForm">
                            <Row key="mainRow">
                                <Col sm={12} md={9} lg={9}>
                                    {code}
                                </Col>
                                <Col sm={12} md={3} lg={3}>
                                    <div>Preset Metadata:</div>
                                    <ul className="list-preset-Metdata">
                                        {PRESET_METADATA_QUIZ.map((item, idx) => {
                                            return <li key={`preset-${idx}`} onClick={()=>this.setMetadataPreset(item.preset)}>{item.label}</li>
                                        })}
                                    </ul>
                                </Col>
                            </Row>
                        </form>

                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={this.onCancel}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                        </div>
                    </CardBody>
                </Card>               
            </>
        );
    }
}
