import React, { Component } from "react";
import { Button } from "design-react-kit";
import {
    BoxDanger,
    BoxSuccess,
    ErrorNotification,    
    MnemoLoading,
    SupportoTecnico,    
} from "components/misc";
import { networkErrorHelper, titleRequestService } from "libs";

export class TitleRequestDownloadForm extends Component {

    state = {
        loading: false,
        loadingError: false,
        request: false,
        requestError: '',
        requestResult: '',        
    };

    componentDidMount() {

             
    }

    downloadCertification = () => {

        const { registrationCode, requestID} = this.props;

        this.setState({ loading: true });
        titleRequestService.downloadTitle(registrationCode, requestID)
            .then(({ data }) => {

                if (data.payload && data.payload.success) {                   
                    if (data.payload.fileData) {
                        const requestResult = `data:application/pdf;base64,${data.payload.fileData.$value}`;
                        const filename = 'conseguimentoTitoloConEsami_' + registrationCode + '.pdf';

                        let a = document.createElement('a');
                        a.href = requestResult;
                        a.download = filename;
                        a.click();           
                        this.setState({ requestError: '', request: true });         
                    } else {
                        const requestError = "Si è verificato un errore durante l'elaborazione della richeista. Contattare il supporto tecnico. Dettagli errore: "
                            + data.payload.errors;
                        ErrorNotification.render(requestError, 'errore');                        
                    }
                }

                this.setState({ loading: false });
            })
            .catch(error => {
                const requestError = "Si è verificato un errore durante l'elaborazione della richeista. Contattare il supporto tecnico. Dettagli errore: " + networkErrorHelper.getErrorMessage(error);
                this.setState({ loading: false, requestError, requestResult: '', request: true });
            });
    }   

    render() {

        const { loading, loadingError, request, requestError } = this.state;

        if (loadingError) return (<BoxDanger className="m-5">Si è verificato un errore durante l'elaborazione della richiesta,
            se il problema persiste puoi  <SupportoTecnico /></BoxDanger>);

        return (<>
            {(loading) && (<MnemoLoading />)}
            <img className="unicamillus-banner" alt="Unicamillus" src="https://www.formazionedocenti.it/files/images/Unicamillus_logo.png" />
            {(!request) ? (<>
                <p>La Sua richiesta è andata a buon fine. Il titolo conseguito è pronto per essere scaricato</p>
                <Button color="primary bg-dark" className="mt-4 d-block" onClick={this.downloadCertification}>Scarica il Titolo conseguito</Button>
            </>) : (<>
                {(requestError) ? (<>
                    <BoxDanger>{requestError}</BoxDanger>
                </>) : (<>
                    <BoxSuccess>File correttamente generato e scaricato, controlla nei tuoi download</BoxSuccess>
                </>)}
            </>)}
        </>)
    }
}