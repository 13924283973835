import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';

import {
    adminService,   
    learningService,
    networkErrorHelper
} from "libs";

import {
    MnemoLoading,
    ModalHandler
} from "components/misc";

import { ROUTES } from 'const';

import { MTable } from "components/table";
import { Button, Container } from 'design-react-kit';

import "./CourseManagerListLesson.css";


export class CourseManagerChildListLesson extends Component {

    constructor(props) {
        super(props);

        this.state = {
            idLesson: this.props.data.idLesson,
            dataLessons: null,
            loading: false,
            rndKey: 0,
            payload: [],
            currentLevel: this.props.data.currentLevel,
        };
    }

    //--- ---
    tableConfig = {    
        columns: [
            { 
                id: 'label',
                Header: 'Titolo',
                accessor: 'label'
            }
        ],
        actions: [
            {
                id:'detail',
                accessor:'id', 
                label: 'Dettagli',
                icon: 'it-file',
                className: 'MTable-actions',
                onClick: (e,value) => {
                    window.location.href = ROUTES.LESSON_FORM + '/' + this.props.data.idCourse + '/' + this.props.data.idSection + '/' + value + '/read/' + this.props.data.currentLevel;                    
                }
            },
            {
                id:'delete',
                accessor:'id', // field value received in the onclick event
                label: 'Elimina',
                icon: 'it-delete',
                className: 'MTable-actions',
                onClick: (e,value) => {
                    this.deleteRecord(e, value)
                }
            }
        ]
    }
    //--- ---

    componentDidMount(){
        this.loadAllLesson(this.state.idLesson, this.state.currentLevel);
    }//componentDidMount

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            idLesson: nextProps.idLeson
        })

        this.loadAllLesson(nextProps.idLesson, nextProps.currentLevel);
    }//componentWillReceiveProps


    loadAllLesson(idLesson, currentLevel) {
        if(parseInt(idLesson)<=0 || idLesson === null){return false;}

        learningService.loadIndexLesson(idLesson, currentLevel).then(({ data }) => {
            this.setState({
                loading: false,
                dataLessons: this.writeLessonList(data.payload.lessons),
                rndKey: Math.floor((Math.random() * 1000) + 1)
            })
        })
        .catch(error => {
            //networkErrorHelper.notify(error);
            //console.log(error);        
            this.setState({
                payload: [],
                rndKey: Math.floor((Math.random() * 1000) + 1)
            });
            console.log(`Don't warry about previus 404 error, idSection: ${idLesson} not have any lesson child`)
        });
    }//loadAllLesson

    writeLessonList = (data) =>{
        //--- start: prepare reactTable ---
        const items = [];
        Object.entries(data).forEach(([key, val]) => {
            val = {
                ...val,
                label: ReactHtmlParser(val.label)
            }
            items.push(val);
        });

        //--- sort by id ---
        items.sort(function (a, b) {
            return a.orderNumber - b.orderNumber;
        });
        
        this.setState({
            payload: items,
            currentLevel:items[0].level,
            rndKey: Math.floor((Math.random() * 1000) + 1)
        });
    }//writeLessonList

    //--- start functions to delete procedure ---
    deleteRecord(e, value) {
        e.stopPropagation();
        ModalHandler.show(value, 'Eliminazione del dato',
            'vuoi davvero eliminare?', null, this.deleteConfirmed);
    }

    deleteConfirmed = (value) => {
        let payload = {
            id: value
        };

        adminService.deleteLesson(payload.id).then(({ data }) => {
            this.loadAllLesson(this.state.idLesson);
        })
        .catch(error => {
            console.log(error);
            if (networkErrorHelper.is404(error)) {
                networkErrorHelper.notify(error);
            }
        });
    }
    //---- end functions to delete procedure ----

    addNewLesson = (idCourse, idSection, idLesson, currentLevel) =>{
        window.location.href = ROUTES.LESSON_FORM+'/'+idCourse+'/'+idSection+'/'+idLesson+'/newlesson/'+currentLevel;
    }//addNewLesson


    //--- ---


    render() {
        
        if(this.state.idLesson === undefined){return false;}
        const { loading, idLesson, currentLevel } = this.state;

        return (
            <>
            {loading ? (<MnemoLoading></MnemoLoading>) : (
            <Container className="container-card-body-ListLesson">
                <MTable
                key={this.state.rndKey}
                tableData={this.state.payload}
                tableConfig={this.tableConfig}
                />

                <div>
                    <Button color="primary bg-dark"
                    onClick={() => this.addNewLesson(this.props.data.idCourse, this.props.data.idSection, idLesson, currentLevel)}>
                    Nuova lezione
                    </Button>
                </div>
            </Container>
            )}
            </>
        )
    }

}

