import { TITLEREQUEST_STEPS } from "const";
import dayjs from "dayjs";
import { networkErrorHelper, certificationRequestService } from "libs";
import React from "react";
import { CertificationRequestSignForm } from ".";

export class CertificationRequestSign {

    title = 'Firma';
    iconName = 'it-pencil';  
    certificationRequest = null;    
    userProfile = null;
    user = null;
    action = 'Firma richiesta certificazione 24CFU';
    tableName = 'certificationRequest';
    _formRef = null;

    constructor() {
        this._formRef = React.createRef();
    }

    setPayload(payload) {
        this.certificationRequest = payload.certificationRequest;  
        this.userProfile = payload.userProfile;  
        this.user = payload.user;  
    }

    async onNextStep (){                           
        let canNext = this.canNext = this._formRef.current.validate();

        if (canNext) {
            canNext = await this.updateRemote();
        }

        // ricari la pagina cosi forza il caricamento dei dati da  mnemosine-api e mostra eventuali errori di creazione del titolo 
        if (canNext){
            window.location.reload();
        }

        return canNext;
    }

    async updateRemote() {

        let retValue = true;
        this.certificationRequest.currentStep = TITLEREQUEST_STEPS.PAY;
        this.certificationRequest.dateSign = dayjs;

        await certificationRequestService.postSign(this.certificationRequest)
            .then(() => {
                retValue = true;
            })
            .catch(errors => {
                console.log(errors);
                retValue = false;
                networkErrorHelper.notify(errors);
            });

        return retValue;
    };

    onPrevStep = () => {

        return false;
    }

    render() {

        const validatorProps = {
            action: this.action,
            tableName: this.tableName,
            idParent: this.certificationRequest.id,
            phoneNumber: this.userProfile.mobile,
        };

        const requestData = {
            certificationRequest: this.certificationRequest,
            user: this.user,
            userProfile: this.userProfile,
            validatorProps
        };

        return (
            <>
                <CertificationRequestSignForm
                    ref={this._formRef}
                    requestData={requestData} />
            </>
        );
    }
}