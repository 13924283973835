import React, { Component } from "react";
//import { Link, NavLink } from 'react-router-dom';

import { connect } from "react-redux";
import { loggedOut } from "../../store/actions";

import { ROUTES, STRINGS } from "../../const";

import Headroom from "react-headroom";
import "./MainHeaderStyle.scss";
import { NavHeader } from ".";
import { NavMainMenu } from ".";

// import {
//   Header,
//   HeaderContent,
//   HeaderLinkZone,
//   Collapse,
//   Icon,
//   LinkList,
//   LinkListItem,
//   HeaderRightZone,
//   Button,
//   Row, Col,
//   DropdownToggle,
//   DropdownMenu,
//   UncontrolledDropdown
// } from 'design-react-kit';

// icons
import IconSupportoTecnico from "../../icons/IconSupportoTecnico";
import IconClose from "../../icons/IconClose";
import { BannerTop } from "./BannerTop";

class SlimHeader extends Component {
  state = {
    isMenuOpen: false,
  };

  render() {
    const toggleMenu = () => {
      this.setState({ isMenuOpen: !this.state.isMenuOpen });
    };
    const { isLoggedIn, user, roles } = this.props;

    const bannerTop = true;

    return (
      <>
        <Headroom className={bannerTop ? "bannerTop" : ""}>
          <div className="mainHeader">
            {/* banner top */}
            {bannerTop ? (
              <BannerTop />
            ):null}
            {/* header top */}
            <div className="mainHeader__top">
              <div className="mainHeader__wrapper">
                <div className="mainHeader__top__links">
                  <a className="link" href="mailto:info@formazionedocenti.it">
                    <span className="text">info@formazionedocenti.it</span>
                  </a>
                  <a className="link" href={ROUTES.PAGE_SUPPORT}>
                    <IconSupportoTecnico
                      fill="none"
                      stroke="#ffffff"
                      width={24}
                      height={24}
                    />
                    <span className="text">Supporto tecnico</span>
                  </a>
                </div>
              </div>
            </div>
            {/* end header top */}

            {/* header content */}
            <div className="mainHeader__content">
              <div className="mainHeader__content__wrapper">
                <a
                  className="mainHeader__content__logo"
                  href={ROUTES.ROOT}
                  title="vai alla homepage"
                >
                  <img
                    className="logo_site"
                    src="/img/mnemo/logo_main.png"
                    alt={STRINGS.TITLE}
                  />
                </a>
                <div className="mainHeader__content__navigation">
                  <NavHeader
                    isLoggedIn={isLoggedIn}
                    user={user}
                    roles={roles}
                    logout={this.props.logout}
                  />
                  <div
                    className={
                      "mainMenuContainer__burger " +
                      (this.state.isMenuOpen ? "open" : "")
                    }
                    onClick={toggleMenu}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
            {/* end header content */}
          </div>
        </Headroom>

        <div
          className={"mainNavigation " + (this.state.isMenuOpen ? "open" : "")}
        >
          <div className="mainNavigation__overlay" onClick={toggleMenu}></div>
          <div className="mainNavigation__wrapper">
            <div className="mainNavigation__closeButton" onClick={toggleMenu}>
              <IconClose />
            </div>
            <div className="mainNavigation__content">
              <NavMainMenu
                isLoggedIn={isLoggedIn}
                roles={roles}
                user={user}
                logout={this.props.logout}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

// called when store changed (react redux trigger)
const stateToProps = ({ app }) => {
  const { user } = app;
  return {
    isLoggedIn: user !== null,
    name: user !== null ? user.name : null,
    roles: user !== null ? user.roles : null,
  };
};

// called to change the store (react redux Action)
const dispatchToProps = (dispatch) => {
  return {
    logout() {
      dispatch(loggedOut());
    },
  };
};

export default connect(stateToProps, dispatchToProps)(SlimHeader);
