import { BoxDanger, MnemoLoading, MSection, SupportoTecnico, Title } from "components/misc";
import { ROUTES, TITLEREQUEST_STEPS } from "const";
import { Icon } from "design-react-kit";
import { networkErrorHelper } from "libs";
import { titleRequestService } from "libs/api";
import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';

import { MnemoStepper } from "components/misc/MnemoStepper";

import {
    TitleRequestStart,
    TitleRequestSign,
    TitleRequestPay,
    TitleRequestDownload
} from "components/titleRequest/";

const STEP_START = {
    stepName: TITLEREQUEST_STEPS.START,
    handler: new TitleRequestStart(),
    progress: 0
};

const STEP_SIGN = {
    stepName: TITLEREQUEST_STEPS.SIGN,
    handler: new TitleRequestSign(),
    progress: 1,
    nextButton: 'Firma la richiesta'
};

const STEP_PAY = {
    stepName: TITLEREQUEST_STEPS.PAY,
    handler: new TitleRequestPay(),
    progress: 2,
};

const STEP_DOWNLOAD = {
    stepName: TITLEREQUEST_STEPS.DOWNLOAD,
    handler: new TitleRequestDownload(),
    progress: 3
}

class MUCourseTitleRequest extends Component {

    state = {
        titleRequest: null,
        titleRequestMNEMO: null,
        titleRequestGOMP: null,
        user: null,
        userProfile: null,
        loading: true,
        ladingError: false,
        requestError: '',
        rCode: '',
        prevDisabled: true,
        currentProgress: 0,
        steps: []
    };

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        titleRequestService
            .getPendingRequest(rCode)
            .then(({ data }) => {

                const { titleRequest, user, userProfile } = data.payload;
                if (titleRequest) {
                    this.setState({ titleRequest });
                    this.loadRequestMNEMO(data.payload, rCode);
                } else {
                    const { steps, currentProgress } = this.createSteps(data.payload);
                    this.setState({ steps, currentProgress, rCode, user, userProfile });
                }
            })
            .catch(error => {

                networkErrorHelper.notify(error);

                console.log(error);
                this.setState({ loadingError: true, loading: false, rCode });
            });
    }

    loadRequestMNEMO(payload, rCode) {

        // verify if the request exists
        titleRequestService
            .getRequestMNEMO(rCode)
            .then(({ data }) => {

                const { titleRequestMNEMO, titleRequestGOMP, paymentMNEMO, paymentGOMP } = data.payload;
                const { steps, currentProgress } = this.createSteps({...payload, ...data.payload});

                // verify if there was an error during the request                
                const requestError = (titleRequestGOMP && titleRequestGOMP.error) ? titleRequestGOMP.error : '';
                const loadingError= (requestError.length>0) ? true : false;
                
                this.setState({
                    steps, currentProgress, rCode,
                    titleRequestMNEMO: titleRequestMNEMO,
                    paymentMNEMO, paymentGOMP,
                    titleRequestGOMP,
                    loading: false, 
                    loadingError: loadingError,
                    requestError 
                });

            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
                console.log(error);
                this.setState({ loadingError: true, loading: false });
            });
    }

    createSteps(payload) {

        const steps = [];
        STEP_START.handler.setPayload(payload);
        steps.push(STEP_START);
        STEP_SIGN.handler.setPayload(payload);
        steps.push(STEP_SIGN);
        STEP_PAY.handler.setPayload(payload);
        steps.push(STEP_PAY);
        STEP_DOWNLOAD.handler.setPayload(payload);
        steps.push(STEP_DOWNLOAD);

        // get currentProgress
        let currentProgress = 0;
        if (payload && payload.titleRequest) {
            const items = steps.filter(item => item.stepName === payload.titleRequest.currentStep);         
            if ((items && items.length > 0)) {

                currentProgress = items[0].progress;
            }
        }

        return { steps, currentProgress };
    }

    render() {

        const { loading, loadingError, requestError, rCode } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);

        return (<>
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right">
                <Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
            <Title>Richiesta certificati finali</Title>
            <MSection>
                {(loadingError)
                    ? <>
                        <BoxDanger className="mt-4">
                            Si è verificato un errore durante il caricamento della pagina, se il problema persiste puoi <SupportoTecnico />
                            {(requestError.length>0) && (<p>Codice Errore: {requestError}</p>)}
                        </BoxDanger>
                    </>
                    : <>
                        <MnemoStepper {...this.state} />
                    </>
                }

            </MSection>
        </>);
    }
}

export default withRouter(MUCourseTitleRequest);