import React from "react";

import {
    FormEnabler,
    ValidatedForm,
    MInput,
    MToggle,
    MRadioButton,
    MTextArea
} from "components/forms";

import {
    payloadBuilder,
    invalidFieldsMapper,
    networkErrorHelper,
    notNullObjectBuilder,
    adminService,
} from "libs";

import {
    Card,
    CardBody,
    Row
} from 'design-react-kit';

import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../../const';
import _ from 'lodash';
import { MFile, MCE } from "../";

export class AdminCourseFormLesson extends ValidatedForm {

    ERROR_MESSAGES = {
        orderNumber: "Ordine non valido",
        label: "Label non valida",
        title: "Titolo non valido",
        summary: "Minimo 5 caratteri",
        content: "contenuto non valido (minimo 5 caratteri)"
    };

    emptyFields = {
        inIndex: false,
        orderNumber: 1,
        label: '',
        idParent: 0,
        idResource: 0,
        level: 1,
        type: '',
        title: '',
        summary: '',
        content: '',
        document:0
    };

    //--- ---
    //TODO: i validatori content sono stati commentati per comodità, presentano un errore
    // che deve essere FIX-ato
    // remove placeholder default value from emptyFilds.content
    validation = {};

    validation_quiz = {
        orderNumber: value => /^\d*$/.test(value),
        label:  value => value !== null && value.length > 2,
        type: value => value !== null && value !== '',
        title: value => value !== null && value.length > 2,
       // summary: value => /^.{5,}$/i.test(value),
    };

    validation_file = {
        orderNumber: value => /^\d*$/.test(value),
        label: value =>value !== null && value.length > 2,
        type: value => value !== null && value !== '',
        title: value => value !== null && value.length > 2,
        summary: value => /^.{5,}$/i.test(value),       
        //content: value => /^.{5,}$/i.test(value),
    };

    validation_page = {
        orderNumber: value => /^\d*$/.test(value),
        label: value => value !== null && value.length > 2,
        type: value => value !== null && value !== '',
        title: value =>value !== null && value.length > 2,
        //summary: value => /^.{5,}$/i.test(value),
        //content: value => value !== null && value.length > 10,
    };

    validation_marker = {
        orderNumber: value => /^\d*$/.test(value),
        label: value =>value !== null && value.length > 2,
        type: value => value !== null && value !== '',
        title: value => value !== null && value.length > 2,
       // summary: value => /^.{5,}$/i.test(value),
    };

    //--- ---

    //-- START FORM OBJ ---
    RADIO_RESOURCE = [
        { value: "page", label: "Lezione/Pagina", infoText: 'Lezione in una pagina web' },
        { value: "marker", label: "Marker", infoText: 'Etichetta nel menu' },
        { value: "file", label: "File PDF", infoText: 'Visualizzazione File PDF' },
    /*    { value: "url", label: "File", infoText: 'Visualizzazione URL' }, */
        { value: "quiz", label: "Quiz", infoText: 'Test intermedio/Prova Finale/Esami' },
        { value: "finalPaper", label: "finalPaper", infoText: 'Invio Materiale' },
    ];

    FIELDS_GROUP = [];

    FIELDS_GROUP_COMMON = [
        [
            {
                id: "title",
                name: "title",
                field: "title",
                label: "Titolo",
                component: MInput,
                type: "text",
                className: "col-md-12"
            }
        ],
        [
            {
                id: "inIndex",
                name: "inIndex",
                field: "inIndex",
                label: "Visibile sull'indice",
                component: MToggle,
                className: "col-md-7 pr-4"
            },
            {
                id: "orderNumber",
                name: "orderNumber",
                field:"orderNumber",
                label: "Ordine",
                component: MInput,
                type: "number",
                className: "col-md-5"
            }
        ],
        [
            {
                id: "label",
                name: "label",
                field: "label",
                label: "Label",
                component: MInput,
                type: "text",
                className: "col-md-12"
            }
        ],
        [
            {
                id: "type",
                name: "type",
                field: "type",               
                payload: { options: this.RADIO_RESOURCE },
                component: MRadioButton,
                type: "radio",
                className: "resourceType",
                label: "Seleziona un tipo di risorsa per questa lezione",
                onChange: (e, value) => {
                    this.manageResourceType(e, value)
                }
            }
        ],      
        [
            {
                id: "summary",
                name: "summary",
                field: "summary",
                label: "Descrizione",
                component: MTextArea,
                rows: 6,
                type: "textarea",
                className: "col-md-12"
            }
        ],
    ];

    FIELDS_GROUP_FILE = [
        [
            {
                field: "content",
                label: "URL page/file [da moodle]",
                component: MInput,
                type: "text",
                className: "col-md-12 mt-5"
            }
        ],
         [{
            id: "document",
            field: "document",
            label: "File PDF",
            infoText: "Caricare il file PDF",
            component: MFile,
            config: { endPoint: '/admin/resourceFile', mandatory:true, fieldName: 'document', maxFiles: 1 },
            className: "col-md-12"
        }]
    ];

    FIELDS_GROUP_FINALPAPER = [
        [
            {
                field: "content",
                label: "Final-paper",
                component: MInput,
                type: "text",
                className: "col-md-12 mt-5"
            }
        ]
    ];

    FIELDS_GROUP_PAGE = [
        [
            {
                field: "content",
                label: "Page editor",
                component: MCE,
                className: "col-md-12 mt-5",
                infoText: "Specificare il testo"
            }
        ]
    ];

    //-- END FORM OBJ ---


    //--- ---
    constructor(props) {
        super(props);

        let idParent = parseInt(this.props.idSection);
        if(parseInt(this.props.idLesson) > 0){
            idParent = parseInt(this.props.idLesson);
        }

        this.state = {
            resourceShow: 'page',
            ...this.emptyFields,
            defaultValues: {
                ...this.emptyFields,
                idParent,
                level: parseInt(this.props.currentLevel)
            },
            formActive: parseInt(this.props.idLesson) > 0 ? false : true,
            idResource: '',
            idCourse: this.props.idCourse,
            idSection: this.props.idSection,
            idLesson: this.props.idLesson
        };

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }


    manageResourceType = (e, value) => {
        switch (value) {
            case ("page"):
                this.validation = this.validation_page;
                break;

            case ("files"):
                this.validation = this.validation_file;
                value = 'file';
                break;

            case ("quiz"):
                this.validation = this.validation_quiz;
                break;

            case ("marker"):
                this.validation = this.validation_marker;
                break;

            default:
        }

        this.setState({ resourceShow: value });
    }


    loadRemote(additionalState = {}) {

        //--- prevent loadRemote when not required ---
        if (parseInt(this.state.idLesson) <= 0 || (typeof this.props.action !== 'undefined' && this.props.action !== 'read')) {
            return false;
        }

        const id = parseInt(this.state.idLesson);

        adminService.loadLessonAndResource(id)
            .then(({ data }) => {
                const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.payload.lessonAndResource,
                    {document: data.payload.lessonAndResource.idResource}
                )

                const newState = {
                    defaultValues,
                    idResource: data.payload.lessonAndResource.idResource,                    
                    ...additionalState
                };

                this.setState(newState);
                this.manageResourceType(null, data.payload.lessonAndResource.type)
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                console.log(error);
            });
    }
    

    onBackPage = () => {
        if(typeof this.props.action !== 'undefined' && this.state.idLesson){
            window.location = ROUTES.LESSON_FORM + '/' + this.state.idCourse + '/' + this.state.idSection+'/'+this.state.idLesson+'/read/'+this.props.currentLevel; 
            return false;                       
        }

        if(this.state.idLesson && this.state.defaultValues.idParent > 0){
            window.location = ROUTES.LESSON_FORM + '/' + this.state.idCourse + '/' + this.state.idSection+'/'+this.state.idLesson;
            //window.location = ROUTES.LESSON_FORM + '/' + this.state.idCourse + '/' + this.state.idSection+'/'+this.state.defaultValues.idParent; 
            return false;
        }
        
        window.location = ROUTES.COURSE_FORM + '/' + this.state.idCourse + '/' + this.state.idSection;
    }//onBackPage


    saveRemote = () => {
        let payload = payloadBuilder(this.state);

        //--- ---
        let payloadLesson     = _.omit(payload, ['type', 'title', 'summary']);
        let payloadResource   = _.omit(payload, ['index', 'orderNumber', 'label', 'idParent', 'idResource', 'level', 'inIndex']);
        payloadResource['id'] = payloadLesson.idResource;

        payload = {
            lesson: payloadLesson,
            resource: payloadResource
        }

        if (parseInt(this.state.idLesson) > 0 &&( this.props.action === 'read')) {
            adminService.updatetLessonAndResource(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
                .catch(errors => {
                    if (networkErrorHelper.is422(errors)) {
                        const newState = invalidFieldsMapper(
                            errors,
                            this.state,
                            this.ERROR_MESSAGES
                        );
                        this.setState({ ...newState });
                    } else {
                        networkErrorHelper.notify(errors);
                    }
                });

        } else {

            adminService.insertLessonAndResource(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
                .catch(errors => {
                    if (networkErrorHelper.is422(errors)) {
                        const newState = invalidFieldsMapper(
                            errors,
                            this.state,
                            this.ERROR_MESSAGES
                        );
                        this.setState({ ...newState });
                    } else {
                        networkErrorHelper.notify(errors);
                    }
                });
        }
    };


    renderPage = (formActive, defaultValues) => {

        var { formValid, code } = this.renderFields(this.FIELDS_GROUP_COMMON.concat(this.FIELDS_GROUP_PAGE), defaultValues, formActive);

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <CardBody tag="div" className="card-body-lesson-form">
                        <form id="courseform" className="user">
                            <Row>
                                <div >{code}</div>
                            </Row>
                        </form>

                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={parseInt(this.state.idLesson) > 0 ? this.onCancel : this.onBackPage}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                        </div>
                    </CardBody>
                </Card>
            </>
        );
    }

    renderMarker = (formActive, defaultValues) => {

        var { formValid, code } = this.renderFields(this.FIELDS_GROUP_COMMON, defaultValues, formActive);

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <CardBody tag="div" className="card-body-lesson-form">
                        <form id="courseform" className="user">
                            <Row>
                                <div >{code}</div>
                            </Row>
                        </form>

                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={parseInt(this.state.idLesson) > 0 ? this.onCancel : this.onBackPage}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                        </div>
                    </CardBody>
                </Card>
            </>
        );
    }

    renderFile = (formActive, defaultValues) => {

        var { formValid, code } = this.renderFields(this.FIELDS_GROUP_COMMON.concat(this.FIELDS_GROUP_FILE), defaultValues, formActive);

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <CardBody tag="div" className="card-body-lesson-form">
                        <form id="courseform" className="user">
                            <Row>
                                <div className="px-3 col-lg-12 col-md-12">{code}</div>
                            </Row>
                        </form>

                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={parseInt(this.state.idLesson) > 0 ? this.onCancel : this.onBackPage}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                        </div>
                    </CardBody>
                </Card>
            </>
        );
    }

    renderFinalpaper = (formActive, defaultValues) => {

        var { formValid, code } = this.renderFields(this.FIELDS_GROUP_COMMON.concat(this.FIELDS_GROUP_FINALPAPER), defaultValues, formActive);

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <CardBody tag="div" className="card-body-lesson-form">
                        <form id="courseform" className="user">
                            <Row>
                                <div className="px-3 col-lg-12 col-md-12">{code}</div>
                            </Row>
                        </form>

                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={parseInt(this.state.idLesson) > 0 ? this.onCancel : this.onBackPage}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                        </div>
                    </CardBody>
                </Card>
            </>
        );
    }

    renderQuiz = (formActive, defaultValues) => {

        var { formValid, code } = this.renderFields(this.FIELDS_GROUP_COMMON, defaultValues, formActive);
        
        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <CardBody tag="div" className="card-body-lesson-form">
                        <form id="courseform" className="user">
                            <Row>
                                <div className="px-3 col-lg-12 col-md-12">{code}</div>
                            </Row>
                        </form>

                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={parseInt(this.state.idLesson) > 0 ? this.onCancel : this.onBackPage}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                            {(parseInt(this.props.idLesson) > 0) && 
                                <NavLink to={ROUTES.QUIZ_FORM + '/' + this.props.idCourse + '/' + this.props.idSection + '/' + this.props.idLesson + '/' + this.state.idResource}
                                className="btn btn-primary bg-dark">
                                    Gestione Quiz
                                </NavLink>}
                        </div>
                    </CardBody>
                </Card>
            </>
        );
    }

    //--- ---
    code = (formActive, defaultValues) => {
        let code = '';

        switch (this.state.resourceShow) {
            case "page":
                code = this.renderPage(formActive, defaultValues);
                break;

            case "file":
                code = this.renderFile(formActive, defaultValues);
                break;

            case "quiz":
                code = this.renderQuiz(formActive, defaultValues);
                break;

            case "marker":
                code = this.renderMarker(formActive, defaultValues);
                break;

            case "finalPaper":
                code = this.renderFinalpaper(formActive, defaultValues);
                break;

            default:
                code = this.renderMarker(formActive, defaultValues);
                break;
        }

        return code;
    }//code


    render() {

        let { formActive, defaultValues } = this.state;

        if ((parseInt(this.state.idLesson) === 0 || this.props.action === 'newlesson')) {
            formActive = true;
        }

        return (
            <>
                {this.code(formActive, defaultValues)}
            </>
        );
    }
}
