import React from "react";
import { USER_QUALIFICATIONS } from "const";

import { FormEnabler, ValidatedForm, MInput, MToggle } from "components/forms";

import {
  payloadBuilder,
  invalidFieldsMapper,
  networkErrorHelper,
  userService,
  dateHelper,
} from "libs";

import { Card, CardBody, Button } from "design-react-kit";

export class UserQualificationUniversityForm extends ValidatedForm {
  ERROR_MESSAGES = {
    aa: "Anno Accademico non valido (AAAA/AAAA)",
    istitution: "Istituto non valido",
    city: "Città non valida",
    vote: "Voto non valido",
    description: "Titolo conseguito non valido",
    laureaType: "Tipo di Laurea non valido",
    dateAchievement: "Data conseguimento non valida GG/MM/AAAA",
    //dateSignup: "Data immatricolazione non valida GG/MM/AAAA",
  };

  emptyFields = {
    aa: "",
    istitution: "",
    city: "",
    vote: "",
    description: "",
    dateAchievement: "",
    //dateSignup: "",
    lode: false,
    nation: "",
    laureaType: "",
    type: USER_QUALIFICATIONS.LAUREA,
    voteNotNumber: false,
    schoolCode: null
  };

  validation = {
    istitution: (value) => /^[a-zA-Z0-9.,-/"àèé’'ìòù,/()\s"]+$/i.test(value),
    city: (value) => /^[a-zA-Z0-9'àè’'éìòù,/\s"]+$/i.test(value),
    description: (value) => value !== null && value.length > 3,
    laureaType: (value) => /^[a-zA-Z0-9'.-/"àèéì’'òù,/()\s"]+$/i.test(value),
    aa: (value) => this.validateAA(value),
    vote: (value) => this.validateVote(value, this.state.voteNotNumber.value),
    voteNotNumber: (value) => this.resetVoteField(),
    dateAchievement: (value, aa, defaultValuesAA) =>
      this.validateDateRange(
        value,
        this.state.aa.value,
        this.state.defaultValues.aa
      ),
    //dateSignup: (value) => /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.test(value),
  };

  //--- start: more Validate func ---
  resetVoteField = () => {
    this.setState((state) => {
      state.vote = "";
    });

    return true;
  }; //resetVoteField

  validateVote = (value, voteNotNumber) => {
    if (value === "") {
      return false;
    }

    if (!voteNotNumber === true) {
      let defRule = /^[1-9]\d{0,2}?$/.test(value);
      return defRule;
    } else {
      return true;
    }
  }; //validateVote

  validateAA = (value) => {
    let aa = /^(\d{4})\/(\d{4})$/.test(value);

    if (!aa) {
      return false;
    } else {
      aa = value.split("/");
      if (parseInt(aa[1]) === parseInt(aa[0]) + 1) {
        return true;
      } else {
        return false;
      }
    }
  }; //validateAA

  validateDateRange = (value, aa, defaultValuesAA) => {
    let result = true;

    // validate dateAchievement
    const dateAchievement = dateHelper.noFuture(value);
    if (!dateAchievement) result = false;

    // validate AA on dateAchievement
    if (aa === undefined) {
      aa = defaultValuesAA;
    }
    if (defaultValuesAA !== "" && defaultValuesAA === aa) {
      aa = defaultValuesAA;
    }

    if (aa !== "" && value !== "") {
      const aaEnd = aa.split("/")[1];
      let dateAchievementYear = value.split("-")[0];
      let aaEnd2 = aaEnd;
      try {
        aaEnd2 = (parseInt(aaEnd) + 1).toString();
      } catch (error) {
        console.log(error);
      }

      if (dateAchievementYear === aaEnd || dateAchievementYear === aaEnd2) {
        result = true;
      } else {
        result = false;
      }
    }

    return result;
  }; //validateDateRange
  //--- end: more Validation func ---

  FIELDS_GROUP = [
    [
      {
        id: "laureaType",
        field: "laureaType",
        label: "Tipo di Laurea",
        component: MInput,
        type: "text",
        className: "col-md-12",
        readOnly: true,
      },
    ],
    [
      {
        id: "istitution",
        field: "istitution",
        label: "Ateneo",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
      {
        id: "city",
        field: "city",
        label: "Città",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
    ],
    [
      {
        id: "description",
        field: "description",
        label: "Titolo conseguito",
        component: MInput,
        type: "text",
        className: "col-md-12",
        readOnly: true,
      },
    ],
    [
      {
        id: "aa",
        field: "aa",
        label: "Anno accademico",
        component: MInput,
        type: "text",
        className: "col-md-4",
      },
      {
        id: "dateAchievement",
        field: "dateAchievement",
        label: "Data conseguimento",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
    ],
    [
      {
        id: "vote",
        field: "vote",
        label: "Voto",
        component: MInput,
        type: "text",
        className: "col-md-4",
      },
      {
        id: "lode",
        field: "lode",
        label: "Lode",
        component: MToggle,
        className: "col-md-2",
      },
      {
        id: "voteNotNumber",
        field: "voteNotNumber",
        label: "Voto non numerico",
        component: MToggle,
        className: "col-md-4",
      },
    ],
    [
      /*
            * Desirè dice che non è necessaria per Esse3 e momentaneamente la togliamo: saverio 11/6/21
            {
                id: "dateSignup",
                field: "dateSignup",
                label: "Data Immatricolazione",
                component: MInput,
                type: "date",
                className: "col-md-6"
            },*/
    ],
  ];

  //--- ---

  constructor(props) {
    super(props);

    this.state = {
      ...this.emptyFields,
      defaultValues: { ...this.emptyFields },
      formActive: true,
    };

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (typeof this.props.university !== "undefined") {
      this.setState((state) => {
        state.defaultValues = { ...this.props.university };
        return true;
      });
    }
  } //componentDidMount

  nextUniversity = () => {
    const payload = payloadBuilder(this.state);
    this.props.popolateDataFromChildUniversityForm(payload);
  }; //nextUniversity

  onCancel = () => {
    this.props.showFormUniversity(false);
  }; //onCancel

  saveRemote = () => {
    const payload = payloadBuilder(this.state);

    //--- normalize dataset to update ---
    delete payload.dateSignup;
    delete payload.createdAt;
    delete payload.updatedAt;

    if (payload.id > 0) {
      userService
        .updateQualification(payload)
        .then((result) => {
          this.onCancel();
        })
        .catch((errors) => {
          if (networkErrorHelper.is422(errors)) {
            const newState = invalidFieldsMapper(
              errors,
              this.state,
              Object.keys(this.validation)
            );
            this.setState({ ...newState });
          } else {
            networkErrorHelper.notify(errors);
          }
        });
    } else {
      userService
        .insertQualification(payload)
        .then((result) => {
          this.onCancel();
        })
        .catch((errors) => {
          if (networkErrorHelper.is422(errors)) {
            const newState = invalidFieldsMapper(
              errors,
              this.state,
              Object.keys(this.validation)
            );
            this.setState({ ...newState });
          } else {
            networkErrorHelper.notify(errors);
          }
        });
    }
  }; //saveRemote

  //--- ---
  render() {
    const { formActive, defaultValues } = this.state;
    const { formValid, code } = this.renderFields(
      this.FIELDS_GROUP,
      defaultValues,
      formActive,
      true
    );

    return (
      <Card tag="div" className="card-big">
        <CardBody tag="div" className="card-body-inAccordion">
          <Button
            color="primary bg-dark"
            onClick={() => {
              this.nextUniversity();
            }}
          >
            Cerca Ateneo
          </Button>

          <form className="user mt-5">{code}</form>

          <div className="m-0 text-right">
            <FormEnabler
              onSubmit={this.onSubmit}
              onCancel={this.onCancel}
              onToggle={this.toggleForm}
              isFormActive={formActive}
              disableSave={!formValid}
            />
          </div>
        </CardBody>
      </Card>
    );
  }
}
