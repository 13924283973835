import { MSection, Title } from 'components/misc';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class PageContactInfo extends Component {

    renderMilano() {

        return (<>
            <div >
                <p><b>Milano</b>: Piazza Firenze n. 14 - 20154 Milano</p>
                <ul className="list-left m-3">

                    <li className='py-4' ><i>Dalla <b>Stazione Centrale Fs</b></i>: <br />
                        <p>prendere la metropolitana linea <strong>M2 (direzione Abbiategrasso)</strong>, scendere a Cadorna, dirigersi all'uscita, percorrere il piazzale antistante per 50 metri, prendere la Linea 1 (Greco-Castelli) e scendere alla fermata <strong>Piazza Firenze;</strong></p>
                        <p>oppure</p>
                        <p  >percorrere 550 metri, recarsi alla <b>fermata P.zza Luigi Savoia</b> e prendere la <b>Linea 91 (Isonzo - Lotto circolare sinistra)</b> per 13 fermate, scendere alla <b>fermata V.le Certosa - V.le Serra</b>, prendere la <b>linea 19&nbsp; (Roserio -24 Maggio)</b> per 2 fermate e scendere alla <b>fermata Piazza Firenze</b>.</p>
                        <p  >oppure</p>
                        <p  >prendere la metropolitana <b>linea M2</b> (<b>Cascina- Gobba- Abbiategrasso</b>) o (<b>Cologno Nord - Assago Forum</b>),scendere alla fermata <b>Garibaldi Fs</b>, prendere la <b>linea M5 (Bignami - San Siro Stadio</b>) per 1 fermata, scendere alla fermata <b>Domodossola</b>,&nbsp;percorrere 50 metri, prendere le <b>linea 19</b> <b>( 24 Maggio-&nbsp;Roserio</b> )&nbsp; in alternativa&nbsp;la <b>Linea 1</b> (<b>Greco- Castelli</b>) per tre fermate e scendere a Piazza Firenze;</p>
                    </li>
                    <li className='py-4' ><i>Dalla <b>Stazione Porta Garibaldi</b> FS</i>: <br />
                        prendere la Metropolitana linea <strong>M5 (Bignami-San Siro)</strong> scendere alla fermata <strong>Domodossola</strong>,&nbsp; percorrere 50 metri, prendere la <strong>linea 19 (24 Maggio- Roserio)</strong> o la <strong>Linea 1 (Greco-Castelli)</strong> per tre fermate e scendere a <strong>Piazza Firenze</strong>.</li>
                    <li className='py-4' ><i>Dall'<b>Aeroporto Malpensa</b></i>: <br />
                        prendere il “<strong>Malpensa Express</strong>,” scendere alla <strong>Stazione Cadorna</strong>, dirigersi all'uscita e percorrere il piazzale antistante per 50 metri, prendere la <strong>linea 1 (Greco-Castelli)</strong> e scendere alla fermata <strong>Piazza Firenze</strong>.
                    </li>
                    <li className='py-4' ><i>Dall' <b>Aeroporto di Linate</b></i>: <br />
                        prendere il <strong>bus nr. 73</strong> presso l'uscita degli arrivi nazionali fino alla fermatadi <strong>Piazza San Babila</strong>, percorrere 100 metri, prendere la <strong>linea M1 Sesto FS- Rho Fiera</strong> per 5 fermate,scendere alla fermata <strong>Conciliazione</strong>, percorrere 150 metri, raggiungere la fermata conciliazione M1, prendere la <strong>linea19 (24 Maggio Roserio)</strong> per 10 fermate scendere alla fermata <strong>Piazza Firenze</strong>.
                    </li>
                </ul>
            </div>
        </>);
    }

    renderRoma() {

        return (<>
            <div >
                <p><strong>Roma - Sede Regionale Associazione Mnemosine - Via Properzio n. 6</strong> a 200 m circa dalle mura del Vaticano </p>
                <ul className="list-left m-3">
                    <li className='py-4' > Dalla stazione <strong>Termini</strong> prendere la metropolitana <strong>A</strong> (direzione Battistini), scendere alla fermata&nbsp; <strong>OTTAVIANO-S.PIETRO</strong>, dirigersi all'uscita su <strong>via Ottaviano</strong> percorrendola fino&nbsp; a <strong>Piazza Risorgimento</strong>, imboccare sulla sinistra <strong>via Cola Di Rienzo</strong> e dopo circa 100 metri svoltare a destra su <strong>Via Properzio</strong>.
                    </li>
                    <li className='py-4' ><strong>Dall'Aeroporto di Fiumicino “Leonardo da Vinci”:</strong> <br />
                        <ol>
                            <li>Prendere&nbsp;&nbsp; il treno “ <strong>Leonardo Express</strong> “ scendere &nbsp;alla <strong>Stazione Termini</strong>, prendere la metropolitana&nbsp;<strong>A</strong>&nbsp;(direzione <strong>Battistini</strong> ), scendere alla fermata&nbsp;&nbsp;<strong>OTTAVIANO-S.PIETRO</strong>, dirigersi all'uscita su&nbsp;<strong>via Ottaviano</strong>&nbsp;percorrendola fino&nbsp; a&nbsp;<strong>Piazza Risorgimento</strong>, imboccare sulla sinistra&nbsp;<strong>via Cola Di Rienzo</strong>&nbsp;e dopo circa 100 metri svoltare a destra su&nbsp;<strong>Via Properzio</strong>. </li>
                            <li>E' possibile raggiungerci inoltre usufruendo del servizio <strong>SIT BUS SHUTTLE</strong> da Fiumicino Aeroporto fino alla fermata <strong>Vaticano</strong> sita in via <strong>Crescenzio</strong> (circa 200 metri dai nostri uffici). <u>http://www.sitbusshuttle.com/fiumicino/</u></li>
                        </ol>
                    </li>
                </ul>
            </div>
        </>);
    }

    renderFirenze() {

        return (<>
            <div >
                <p><strong>Firenze:</strong> Piazza Baldinucci, 2/R - 50129</p>
                <ul className="list-left m-3">
                    <li className='py-4' >
                        <p>Dalla <strong>Stazione Ferroviaria Santa Maria Novella</strong>: </p>
                        <p><b>In Autobus - </b>dalla stazione raggiungere la <strong>fermata “STAZIONE LARGO ALINARI”</strong> (via Piazza della Stazione) <br />
                            <strong>linea 14 C</strong> direzione “<strong>CAREGGI</strong>” o <strong>linea 14 B</strong> direzione “<strong>NICCOLO' DA TOLENTIN</strong>O” e scendere alla <strong>fermata “BALDINUCCI”</strong> (via del Romito); percorrere circa 50 m. dalla fermata per raggiungere <strong>Piazza Filippo Baldinucci</strong>;
                        </p>
                        <p>oppure </p>
                        <p>dalla stazione raggiungere la <strong>fermata “STAZIONE PALAZZO DEI CONGRESSI”</strong> (via Piazza della Stazione) <br />
                            <strong>linea 2</strong> direzione “<strong>CALENZANO</strong>”&nbsp; e scendere alla <strong>fermata “BALDINUCCI”</strong> (via del Romito); percorrere circa 50 m. &nbsp;dalla fermata per raggiungere <strong>Piazza Filippo Baldinucci</strong>.
                        </p>
                        <p><b><i>A Piedi - </i></b>rimanendo all'interno della Stazione costeggiare il <strong>binario 16</strong> fino all'uscita su <strong>Viale Filippo Strozzi</strong>, proseguire per circa 150 m. e prendere la <strong>rampa di Via Guido Spadolini</strong> seguendo la strada, in prossimità del ponte girare a sinistra in <strong>Via del Romito</strong>, percorrendola per circa 100 m. si raggiunge <strong>Piazza filippo Baldinucci</strong>.</p>
                    </li>
                    <li className='py-4' >Dall' <strong>Aeroporto di Firenze-Peretola “Amerigo Vespucci”</strong>: <br />
                        <p><b>Collegamento urbano </b>Ataf servizio “Vola in Bus”</p>
                        <p>dall'uscita dell'aeroporto raggiungere la <strong>fermata “AEROPORTO VESPUCCI”</strong> che in circa 20 min. arriva in <strong>stazione Santa Maria Novella</strong> e scendere alla <strong>fermata “STAZIONE GALLERIA</strong>” o <strong>fermata “AUTOSTAZIONE BUS ITALIA”</strong>, da qui a piedi raggiungere <strong>Piazza della Stazione</strong>. </p>
                        <p><b>Collegamento extraurbano</b>Lazzi-Cap</p>
                        <p>dall'uscita dell'aeroporto raggiungere la fermata che si trova adiacente al sedime aeroportuale,che in circa 20 min. arriva a <strong>Firenze Stazione Santa Maria Novella</strong>.
                        </p>
                    </li>
                </ul>
            </div>
        </>);
    }

    renderBologna() {

        return (<>
            <div>
                <p><b>Bologna: </b>Viale della Repubblica 3/A - 40127</p>
                <ul className="list-left m-3">
                    <li className='py-4' >
                        <p>Dalla<strong> Stazione Ferroviaria Bologna Centrale:</strong> </p>
                        <p><b>In autobus - </b>Dalla fermata <i>Stazione Centrale</i> linea <b>21</b> (di fronte la Stazione)&nbsp; scendere alla <strong>fermata</strong> <i>Mercato San</i> <i>Donato</i>; percorrere pochi metri e raggiungere <strong>Viale della Repubblica</strong>.
                        </p>
                        <p> Dal centro storico raggiungere la <strong>fermata <i>San Pietro</i></strong> (su via Indipendenza), linea <b>20</b> e scendere alla <strong>fermata <i>Mercato San Donato</i></strong>; percorrere pochi metri e raggiungere <strong>Viale della Repubblica</strong>.
                        </p>
                    </li>
                    <li className='py-4' >
                        <p>Dall'<strong>aeroporto di Bologna Guglielmo Marconi:</strong> <br />
                            <b>In autobus - </b>Linea <b>Aerobus</b> (<i>fermata Aeroporto</i>) e scendere <strong>fermata</strong> <i>San Pietro</i> (via Indipendenza); dalla fermata San Pietro linea <b>20</b> e scendere <strong>fermata</strong> <strong><i>Mercato San Donato</i></strong>; percorrere pochi metri e raggiungere <strong>Viale della Repubblica</strong>.</p>
                    </li>
                </ul>
            </div>
        </>);
    }

    renderPadova() {

        return (<>
            <div>
                <p><b>Padova:</b> <strong>Via Andrea Costa n° 19</strong> - <strong>35124</strong></p>
                <ul className="list-left m-3">
                    <li className='py-4'><u>Dall'<strong><em>Aeroporto Marco Polo di Venezia</em>:</strong></u>
                        <ul><li><strong>autobus<em>, </em></strong>con un servizio di linea <a href="http://www.fsbusitalia.it/">FSBusitalia</a> sino all'autostazione di Padova;</li>
                            <li><strong>autobus + treno</strong><strong><em>, </em></strong>raggiungere la stazione di Venezia-Mestre con la linea autobus <a href="http://www.actv.it/">ACTV</a> o la navetta ATVO; quindi prendere un treno per Padova.</li>
                        </ul>
                    </li>
                    <li className='py-4'><u>Dall'<strong><em>Aeroporto di Treviso</em></strong><em>:</em></u><em> <br />
                    </em>Servizio di linea <strong>Autobus Sita</strong> con frequenti corse giornaliere della durata di 60 minuti c.a. e con fermata al capolinea <strong>Stazione di Padova</strong>.</li>
                    <li className='py-4'><u>Dalla<strong> <em>Stazione Ferroviaria di Padova</em></strong>:</u> <br />
                        Uscendo dalla stazione ferroviaria svoltare a destra, attraversare la linea ferrata e raggiungere la vicina fermata del <strong>tram</strong>. <br />
                        Prendere la <strong>linea</strong> <strong>direzione</strong> <strong>Guizza Capolinea Sud</strong> e scendere alla <strong>fermata Santa Croce </strong>(9 fermate, 14 minuti c.a.). Quindi procedere a piedi (3 minuti, 260 m) per via Giordano Bruno, alla rotonda prendere la prima uscita per <strong>via Andrea Costa</strong> e proseguire sulla sinistra fino al n° civico 19.</li>
                </ul>
            </div>
        </>);
    }

    renderMargherita() {

        return (<>
            <div >
                <p><b>Santa Margherita di Belice (AG):</b> Largo Monfalcone n.15 - 92018 Santa Margherita di Belice (AG)</p>
                <ul className="list-left m-3">
                    <li className='py-4'><p><em><b>Da Palermo</b></em>:</p>
                        <p>Autostrada A29 direzione Mazara del Vallo, uscire per Castelvetrano - S.S. 115 direzione Sciacca - uscita Santa Margherita di Belìce.</p>
                    </li>
                    <li className='py-4'>
                        <p><strong><i>Da Palermo Aeroporto “Falcone Borsellino”</i></strong><em> Km 130</em></p>
                        <p>Autostrada A29 Palermo-Mazara del Vallo uscita svincolo Castelvetrano, poi superstrada Castelvetrano-Sciacca uscita Santa Margherita di Belìce.</p>
                    </li>
                    <li className='py-4'>
                        <p><em><b>Da Messina:</b></em></p>
                        <p>Autostrada A18 direzione Catania, A19 in direzione Agrigento - S.S. 115 uscita Santa Margherita di Belìce.</p>
                    </li>
                    <li className='py-4'>
                        <p><strong><i>Da Agrigento</i></strong><em> Km 95:</em></p>
                        <p>Superstrada Agrigento-Sciacca-Castelvetrano, uscita Santa Margherita di Belìce.</p>
                    </li>
                </ul>
            </div>

        </>);
    }

    renderInfo(city) {

        let info = '';
        let title = '';

        switch (city) {
            case 'milano':
                info = this.renderMilano();
                title='Milano';
                break;

            case 'roma':
                info = this.renderRoma();
                title='Roma';
                break;

            case 'firenze':
                info = this.renderFirenze();
                title='Firenze';
                break;

            case 'bologna':
                info = this.renderBologna();
                title='Bologna';
                break;

            case 'padova':
                info = this.renderPadova();
                title='Padova';
                break;

            case 'santa-margherita-di-belice':
                info = this.renderMargherita();
                title='Santa Margherita di Belice (AG)';
                break;

            default:
                info = '';
                break;
        }


        return {info, title};
    }

    render() {

        const { city } = this.props.match.params;

        const {info, title } = this.renderInfo(city);

        return (<>
            <Title>Come Raggiungere la sede di {title}</Title>
            <MSection>{info}</MSection>
        </>);
    }
}

export default withRouter(PageContactInfo);