import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { uriHelper } from 'libs/helpers/uriHelpers';
import { Title } from "components/misc";

class CardPaymentNotify extends Component {

    state = {
        params: '',
        error: true
    }

    componentDidMount() {

        const params = uriHelper.parseQueryString();
        console.log(params);
        if (!params) {
            this.setState({ params, error: true });
        } else {
            this.setState({ params, error: false });
        }
    }

    render() {

        const { params, error } = this.state;
        let src = '';
        if (!error) {
            const aaURL = params.aa.toString().slice(-2);
            src = process.env.REACT_APP_CRM_URL.replace('[yearTag]', aaURL)
                + 'moodleElearningCreditCard/orderStatus';

            const { orderNumber, pAction, message, tableName } = params;

            src += '/orderNumber/' + orderNumber;
            src += '/tableName/' + tableName;
            src += '/pAction/' + pAction;
            src += '/message/' + message;
        }

        return (<>
            <Title>Stato Pagamento</Title>
            <div>
                <embed src={src} width="99%" height="700" />
            </div>
        </>);
    }
}

export default withRouter(CardPaymentNotify);