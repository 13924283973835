import React, { Component } from "react";
import { ROUTES } from "const";
import { withRouter, NavLink } from "react-router-dom";
import { Icon } from "design-react-kit";
import {
    BoxDanger,    
    MnemoLoading,
    MSection,
    SupportoTecnico,
    Title
} from "components/misc";

import { courseService, networkErrorHelper } from "libs";
import { MTable } from "components/table";

class CourseCFUSelfDeclaration extends Component {

    state = {
        loading: true,
        loadingError: false,
        rCode: '',
        courseModules: []
    };

    tableConfig = {
        columns: [
            {
                id: 'title',
                Header: 'Insegnamento',
                accessor: 'title',
            },           
            {
                id: 'result',
                Header: 'Esito',
                accessor: 'result',
            },
            {
                id: 'vote',
                Header: 'Voto',
                accessor: 'vote',
            },
            {
                id: 'cfu',
                Header: 'CFU',
                accessor: 'cfu',
            },
            {
                id: 'ssd',
                Header: 'SSD',
                accessor: 'ssd',
            },
            {
                id: 'date',
                Header: 'Data',
                accessor: 'date',
            },
            {
                id: 'scope',
                Header: 'Ambito',
                accessor: 'scope',
            },
            {
                id: 'ateneo',
                Header: 'Codice Ateneo',
                accessor: 'ateneo',
            },
        ],
    }

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        courseService.myCourseModulesCFU(rCode)
            .then(({ data }) => {
                this.setState({
                    rCode,
                    courseModules: data.payload,
                    loading: false
                });
            })
            .catch(error => {
                console.log(error);
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                    this.setState({ loadingError: true });
                } 

                this.setState({ loading: false });
            });
    }  

    //--- ---
    render() {

        const { loading, loadingError, rCode, courseModules } = this.state;

        console.log(this.state);
        if (loadingError) return (<BoxDanger className="m-5">Si è verificato un errore durante l'elaborazione della richiesta,
            se il problema persiste puoi  <SupportoTecnico /></BoxDanger>);

        return (<>
            {(loading) && (<MnemoLoading />)}
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
            <Title>Insegnamenti 24CFU</Title>
            <img className="unicamillus-banner" alt="Unicamillus" src="https://www.formazionedocenti.it/files/images/Unicamillus_logo.png" />
            {(courseModules && courseModules.length > 0) ? (<>
                <MSection className="m-3">
                <h5>Insegnamenti conseguiti ai sensi del DM 616/17 del 10 Agosto 2017 (24 CFU)</h5>
                <hr/>
                    <MTable tableData={courseModules} tableConfig={this.tableConfig} />
                </MSection>
            </>) : (<>
                <MSection className="m-3">
                <h5>Non risultano conseguiti insegnamenti </h5>
                <hr/>                   
                </MSection>
            </>)}
        </>)
    }
}

export default withRouter(CourseCFUSelfDeclaration);
