
import React from 'react'
import { useTable, useSortBy } from 'react-table';
import { Icon, Button } from 'design-react-kit';
import { isMobile } from 'react-device-detect';

import './MTable.css';

/**
 * 
 * @param {based on libraryreact-table, see: https://react-table.tanstack.com/} 
 *  
 */
export const MTable = ({ tableConfig, tableData, title = '' }) => {

    /**  
     * TODO: funzionalità da aggiungere
     *  1. gestire paginazione  
     *  2. formattazione dei valori: esempio le date
    */
    let tableColumns = tableConfig.columns;

    // add columns with actions (if provided)
    if (tableConfig.actions) {

        const colAction = tableConfig.actions.map((row) => {

            return {
                id: row.id,
                accessor: row.accessor,
                className: row.className,
                Cell: ({ value }) => (
                    <>
                        <span className="d-none d-sm-flex flex-row-reverse">
                            {row.icon ?
                                (<span title={row.label}
                                    className="action"
                                    onClick={(e) => row.onClick(e, value)}>
                                    <Icon icon={row.icon} />
                                </span >) :
                                (<Button
                                    color={row.color}
                                    onClick={(e) => row.onClick(e, value)}>
                                    {row.text}
                                </Button>)
                            }
                        </span>
                        <span className="d-sm-none">
                            {row.icon ?
                                (<span title={row.label}
                                    className="action"
                                    onClick={(e) => row.onClick(e, value)}>
                                    <Icon icon={row.icon} />
                                </span >) :
                                (<span title={row.label} width="30px"
                                    className="action icon-link"
                                    onClick={(e) => row.onClick(e, value)}>
                                    <Icon color='primary' icon='it-arrow-right-circle' />
                                </span >)

                            }
                        </span>
                    </>
                )
            };
        });

        tableColumns = tableConfig.columns.concat(colAction);
    }

    // verify if there are hidden columns 
    const hiddenCoulmns = tableConfig.columns.filter(v => v.isVisible === false);
    const myInitialState = {};
    if (hiddenCoulmns) {
        myInitialState.hiddenColumns = hiddenCoulmns.map(e => e.id);
    }

    const columns = React.useMemo(() => tableColumns, []);
    const data = React.useMemo(() => tableData, []);
    const initialState = React.useMemo(() => myInitialState, []);

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data, initialState }, useSortBy);

    // Render the UI for your table
    return (
        <>
            <div>
                {title && <><h6>{title}</h6><hr /></>}
                <table className="MTable table  table-striped table-hover"
                    {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <Icon icon="it-arrow-down-triangle" />
                                                    : <Icon icon="it-arrow-up-triangle" />
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr id={row.original.id} onClick={(e) => onRowClick(e, row.original, tableConfig)} {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps(
                                            [
                                                {
                                                    className: cell.column.className
                                                }
                                            ]
                                        )}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
},

    onRowClick = (e, row, tableConfig) => {        
        if (isMobile) {
            if (tableConfig.onRowClick) {
                tableConfig.onRowClick(e, row.id);
            }
        }
    }
