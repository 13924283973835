import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { formHelper } from 'libs';
import { Button } from 'design-react-kit';

export class MCE extends Component {

    onSaveContent = () =>{
       
        if (formHelper.isFunction(this.props.onChange)) {
            //console.log( this.state.content)
            this.props.onChange(this.props.name, this.state.content);
        }

    }

    onEditorChange = (newText) => {
       
        this.setState({content: newText});        
    };

    render() {
       
        const {
            id, name, value,
            label, valid,
            invalid, infoText
        } = this.props;

        const readOnly = this.props.disabled || this.props.readOnly;

        let className = (valid) ? 'is-valid' : '';
        className += (invalid) ? 'is-invalid' : '';
        className += (readOnly) ? 'readOnly' : '';

        // map properites
        const myProps = {
            id, name,
        };

        return (
            <>
                <div >
                    <Editor className={className} {...myProps}
                        initialValue={value}
                        apiKey="c4i03l2u4a8t3j013ag9lqbm1etxs8nhy6jglfmswrlzyg53"
                        disabled={readOnly}                        
                        onEditorChange={(newText) => this.onEditorChange(newText)}                                               
                        init={{
                            height: 600,
                            menubar: false,
                            paste_data_images: true,                           
                            plugins: [
                                'save advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'cancel | undo redo paste | formatselect | ' +
                                'bold italic underline backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | image  table media | fullscreen code preview |  ' +
                                'link unlink openlink | help',
                        }}
                    />
                    {!readOnly && (<>
                        <Button color="primary bg-dark" onClick={this.onSaveContent} >Applica Modifiche</Button></>)}
                    {invalid && (<label className="text-danger" htmlFor={myProps.id} >
                        {infoText}
                    </label>)}

                </div>
            </>
        );
    }
}