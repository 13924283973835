import React, { Component } from "react";
import { BoxDanger, MnemoLoading, SupportoTecnico, Title } from "components/misc";

export class TitleDeliveryESB extends Component {

    state = {
        loading: true,
        loadingError: false,
    };

    componentDidMount() {
        this.setState({
            loading: false
        });
    }

    render() {

        const { loading, loadingError } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);

        if (loadingError) {
            return (<BoxDanger className="mt-4">
                Si è verificato un errore durante il caricamento della pagina, se il problema persiste puoi
                <br /><SupportoTecnico />
            </BoxDanger>);
        }
        
        let src = 'https://mne21.mnemosine-elearning.it/segreteria/frontendTitleDeliveryESB/';
   
        return (
            <>

                <Title>Prenotazione ritiro titolo Inglese</Title>
                <div>
                    <embed src={src} width="99%" height='1500' />
                </div>

            </>)
    }

}
