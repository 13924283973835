import React, { Component } from "react";
import QuestionCheck from "./QuestionCheck";
import QuestionRadio from "./QuestionRadio";
import QuestionText from "./QuestionText";
import QuestionOutcome from "./QuestionOutcome";

import ReactHtmlParser from 'react-html-parser';

export class QuizSection extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {

        const item = this.props.content;
        const { posOrder, idQuiz, metadata } = this.props;

        const quizType = (type, answers, idQuestion, idQuiz) =>{
            switch(type) {
                case 'check':
                  return <QuestionCheck type={type} idQuestion={idQuestion} answers={answers} idQuiz={idQuiz}/>;
                case 'radio':
                  return <QuestionRadio type={type} idQuestion={idQuestion} answers={answers} idQuiz={idQuiz}/>;
                case 'text':
                  return <QuestionText type={type} idQuestion={idQuestion} answers={answers} idQuiz={idQuiz}/>;
                default:
                    return ''
             }
        }//quizType


        
        return (
            <> 
                <section className="my-4">
                    <div className="container">
                        <div id={`q-${item.id}`} className="card card-bg card-big p-4 border-left border-primary">
                            <div >Domanda {(posOrder+1)}</div>
                            <h6>{ReactHtmlParser(item.label)}</h6>
                            <div className="card-footer p-0 pt-2 mt-2">
                                {quizType(item.type, item.answers, item.id, idQuiz)}
                            </div>

                            {(typeof metadata !== 'undefined') && <QuestionOutcome question={item} answers={item.answers} metadata={metadata}/>}

                            {(item.type === 'check' && typeof metadata === 'undefined') && <i className="mt-2">segnare {item.maxChoose} risposte.</i>}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}