import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import { learningService, networkErrorHelper, dateHelper } from "libs";
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'const';
import { QuizSection } from 'components/quiz/QuizSection';
import { BoxDanger, SupportoTecnico } from "components/misc";
import { MnemoLoading } from "components/misc";

class QuizFeedbackFinal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            idQuizAttempt: this.props.match.params.idQuizAttempt,
            attempt: 0,
            idQuiz: 0,
            idResource: 0,
            dateEnd: null,
            loading: true,
            loadingError: false,
            rightAnswer: 0,
            wrongAnswer: 0,
            questions: null,
            metadata: {
                repeat: false,
                attempts: 0
            }
        };
    }

    componentDidMount() {
        this.loadRemote();
    }

    loadRemote() {
        learningService.getQuizAttempt(this.state.idQuizAttempt).then(result => {
            //console.log(result.data.payload.quizAttempt)

            this.setState({
                idQuiz: result.data.payload.quizAttempt.idQuiz,
                idResource: result.data.payload.quizAttempt.idResource,
                title: result.data.payload.quizAttempt.title,
                attempt: result.data.payload.quizAttempt.attempt,
                dateEnd: dateHelper.toITDateTime(result.data.payload.quizAttempt.dateEnd),
                success: result.data.payload.quizAttempt.success,
                loading: false,
                questions: result.data.payload.quizAttempt.questions,
                metadata: { ...result.data.payload.quizAttempt.metadata }
            });

            //--- ---

            let rightAnswer = 0;
            let wrongAnswer = 0;
            this.state.questions.forEach(question => {

                //--- use it to manage counting outcome
                let tmpIDQuestion = 0;
                
                question.answers.forEach(function (answer) {

                    if(question.type !== 'check'){
                        if(answer.userAnswer && parseInt(answer.grade) > 0) {
                            rightAnswer++;
                        }
                        if (answer.userAnswer && parseInt(answer.grade) === 0) {
                            wrongAnswer++;
                        }
                    }

                    //--- increment rightAnswer / wrongAnswer by side ---
                    if(question.type === 'check'){
                        if(tmpIDQuestion !== answer.idQuestion){
                            tmpIDQuestion = answer.idQuestion;
                            if(question.maxChoose === question.answers.filter(item => item.userAnswer && parseInt(item.grade) > 0).length){
                                rightAnswer++;
                            }else{
                                wrongAnswer++;
                            }
                        }
                    }
                });
            });

            this.setState({
                rightAnswer: rightAnswer,
                wrongAnswer: wrongAnswer
            });

            //--- ---
        })
            .catch(error => {
                if (networkErrorHelper.is404(error)) {
                    console.log(error);                   
                    //networkErrorHelper.notify(error);
                }
                
                this.setState({
                    loading: false,
                    loadingError: true
                });
            });
    }//loadRemote()

    render() {

        const { loading, loadingError, title, success, attempt, idQuiz, idResource, dateEnd, metadata, questions, rightAnswer, wrongAnswer } = this.state;

        if (loading) return (<MnemoLoading></MnemoLoading>);

        if (loadingError) return (<BoxDanger className="mt-4">Il risultato per questa prova non è più disponibile.
            Se il problema persiste la preghiamo di <SupportoTecnico/></BoxDanger>);

        /** --- if onlyAccepted --- */

        if (metadata.feedbackFinal.hasOwnProperty('onlyAccepted') && !metadata.feedbackFinal.detail && !metadata.feedbackFinal.brief){
            if (metadata.feedbackFinal.onlyAccepted){
                return (
                    <div>
                        <div className="checkmark mt-4 text-center">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 161.2 161.2" enableBackground="new 0 0 161.2 161.2" xmlSpace="preserve">
                                <path className="path" fill="none" stroke="#008758" strokeMiterlimit={10} d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4
                                    c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5
                                    c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z" />
                                <circle className="path" fill="none" stroke="#008758" strokeWidth={4} strokeMiterlimit={10} cx="80.6" cy="80.6" r="62.1" />
                                <polyline className="path" fill="none" stroke="#008758" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="113,52.8	74.1,108.4 48.2,86.4 " />
                                <circle className="spin" fill="none" stroke="#008758" strokeWidth={4} strokeMiterlimit={10} strokeDasharray="12.2175,12.2175" cx="80.6" cy="80.6" r="73.9" />
                            </svg>
                            <div className=" text-center mt-2"><h6>{`${title} inviata correttamente.`}</h6></div>
                        </div>
                                    
                        <section className="m-0 mb-3">
                            <div className="form-row">
                                <div className="col-md-12 text-center">                                    
                                    <NavLink to={`${ROUTES.C24_EXAMWRITTEN}/${this.props.match.params.rCode}`} className="btn btn-primary mx-1 bg-dark m-1">Torna alle prove</NavLink>
                                </div>
                            </div>
                        </section>
                    </div>);
            }
        }        

        if ((!metadata.feedbackFinal.detail && !metadata.feedbackFinal.brief) || (metadata.feedbackFinal.detail && metadata.feedbackFinal.brief)){
            return (<BoxDanger className="mt-5">Questa prova non prevede un risultato finale.</BoxDanger>);
        }

        return (
            <>
                {/** --- if brief --- */}

                {(metadata.feedbackFinal.brief && !metadata.feedbackFinal.detail) && (
                    <div>
                        <div className="checkmark mt-4 text-center">
                            {(success) ? (<>
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 161.2 161.2" enableBackground="new 0 0 161.2 161.2" xmlSpace="preserve">
                                    <path className="path" fill="none" stroke="#008758" strokeMiterlimit={10} d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4
                                        c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5
                                        c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z" />
                                    <circle className="path" fill="none" stroke="#008758" strokeWidth={4} strokeMiterlimit={10} cx="80.6" cy="80.6" r="62.1" />
                                    <polyline className="path" fill="none" stroke="#008758" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="113,52.8	74.1,108.4 48.2,86.4 " />
                                    <circle className="spin" fill="none" stroke="#008758" strokeWidth={4} strokeMiterlimit={10} strokeDasharray="12.2175,12.2175" cx="80.6" cy="80.6" r="73.9" />
                                </svg>
                                <div className=" text-center mt-2"><h6>{`${title} correttamente superata ed inviata correttamente.`}</h6></div>
                            </>) :
                                (<>
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 161.2 161.2" enableBackground="new 0 0 161.2 161.2" xmlSpace="preserve">
                                        <path className="path" fill="none" stroke="#d9364f" strokeMiterlimit={10} d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4
                                        c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5
                                        c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z" />
                                        <circle className="path" fill="none" stroke="#d9364f" strokeWidth={4} strokeMiterlimit={10} cx="80.6" cy="80.6" r="62.1" />
                                        <polyline className="path" fill="none" stroke="#d9364f" strokeWidth={6} strokeLinecap="round" strokeMiterlimit={10} points="105,50	55,115 " />
                                        <polyline className="path" fill="none" stroke="#d9364f" strokeWidth={6} strokeLinecap="round" strokeMiterlimit={10} points="52.5,50	108,115 " />
                                        <circle className="spin" fill="none" stroke="#d9364f" strokeWidth={4} strokeMiterlimit={10} strokeDasharray="12.2175,12.2175" cx="80.6" cy="80.6" r="73.9" />
                                    </svg>
                                    <div className=" text-center mt-3"><h6>{`${title} non superata.  Si consiglia di rivedere le lezioni presenti all'interno delle Unita' Didattiche.`}</h6></div>

                                    <hr />
                                </>)}
                        </div>
                                    
                        <section className="m-0 mb-3">
                            <div className="form-row">
                                <div className="col-md-12 text-center">
                                    {
                                        ((metadata.repeat && parseInt(attempt) < parseInt(metadata.attempts)) || (parseInt(metadata.attempts) === 0) || parseInt(attempt) < parseInt(metadata.attempts)) && 
                                        (
                                            <>
                                                <NavLink
                                                    to={`${ROUTES.LEARNING_QUIZ_MAIN}/${idResource}/${idQuiz}/${this.props.match.params.rCode}`}
                                                    className="btn btn-primary mx-1 bg-dark m-1">
                                                    Ripeti la Prova
                                                </NavLink>
                                            </>
                                        )
                                    }
                                    
                                    <NavLink to={`${ROUTES.LEARNING_MAIN}/${this.props.match.params.rCode}`} className="btn btn-primary mx-1 bg-dark m-1">Torna al Corso</NavLink>
                                </div>
                            </div>
                        </section>
                    </div>
                )}

                {/** --- if detail --- */}

                {(!metadata.feedbackFinal.brief && metadata.feedbackFinal.detail) && (
                    <div>
                        <div className="container mt-4 mb-4">
                            <section className="m-0">
                                <div className="form-row">
                                    <div className="col-md-12 text-right">
                                        {
                                            ((metadata.repeat && parseInt(attempt) < parseInt(metadata.attempts)) || (parseInt(metadata.attempts) === 0) || parseInt(attempt) < parseInt(metadata.attempts)) && 
                                            (
                                                <>
                                                    <NavLink 
                                                        to={`${ROUTES.LEARNING_QUIZ_MAIN}/${idResource}/${idQuiz}/${this.props.match.params.rCode}`} 
                                                        className="btn btn-primary mx-1 bg-dark">
                                                        Ripeti la Prova
                                                    </NavLink>
                                                </>
                                            )
                                        }
                                        
                                        <NavLink to={`${ROUTES.LEARNING_MAIN}/${this.props.match.params.rCode}`} className="btn btn-primary mx-1 bg-dark">Torna al Corso</NavLink>
                                    </div>
                                </div>
                            </section>

                            <hr />

                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        {/* there are warnings about this scope that should be wrapped in TH not td */}
                                        <th scope="col" colSpan="2" className="m-0 p-2">
                                            <h4 className="m-0 p-0">Riepilogo Prova: {title}</h4><hr/>
                                            <h6 className="m-0 p-0 mt-2 text-secondary">Data: {dateEnd}</h6>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th  scope="row" className="m-0 p-2">Numero di Domande</th>
                                        <td className="m-0 p-2 text-center">{questions.length}</td>
                                    </tr>
                                    <tr >
                                        <th scope="row" className="m-0 p-2">Risposte Corrette</th>
                                        <td className="m-0 p-2 text-center">{rightAnswer}</td>
                                    </tr>
                                    <tr>
                                        <th  scope="row" className="m-0 p-2">Risposte Errate</th>
                                        <td className="m-0 p-2 text-center">{wrongAnswer}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <hr />

                            <section id="fullQuizReport">
                                <div className="bg-light py-5">
                                    <div className="px-4 container">
                                        <div className="row">
                                            <div className="col">
                                                {questions.map((row, i) => (
                                                    <QuizSection key={`quizItem-${i}`} metadata={metadata} content={row} idQuiz={idQuiz} posOrder={i}></QuizSection>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default withRouter(QuizFeedbackFinal);