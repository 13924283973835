import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { courseService, networkErrorHelper } from "libs";
import { ROUTES, TAG_AREA } from "const";
import { MSelect } from "components/forms";
import { dateHelper } from "libs/helpers";
import ReactHtmlParser from "react-html-parser";

import { BoxSuccess, MnemoLoading, MSection } from "components/misc";

import { COURSES_LIST, COURSE_SECONDARY_LIST } from "json/DataCourseList";

import { ButtonPrimary } from "components/ui/buttons/primary/ButtonPrimary";

import {
  Container,
  Icon,
  Chip,
  ChipLabel,
  Row,
  Button,
  Col,
  Card,
  CardBody,
  CardFooter,
} from "design-react-kit";
import { Helmet } from "react-helmet";

class CourseBrowser extends Component {
  state = {
    currentType: null,
    currentTagArea: null,

    currentTagSchool: null,
    currentQualification: "",
    payloadTypes: [],

    tagArea: null,
    tagSchool: null,
    loading: true,
    isTyped: false,

    courses: [],
    courseFilter: false,
    courseFiltered: [],
  };

  componentDidMount() {
    // verify if a url param exists
    const { courseType } = this.props.match.params;
    if (courseType) {
      this.setState({
        currentType: courseType,
        courseFilter: true,
        isTyped: true,
      });
    }

    this.loadTags(courseType);
    this.loadCourses(courseType);
  }

  loadTags(courseType) {
    courseService
      .lisTags(courseType)
      .then(({ data }) => {
        const types = data.payload.types.map(({ code, title }) => ({
          label: title,
          value: code,
        }));
        let currentQualification = "";

        let payloadTypes = [];
        if (courseType === "undefined") {
          payloadTypes.unshift({ label: "Tutti corsi", value: "" });
        }

        payloadTypes.push(...types);

        const tagSchool = [];
        data.payload.tagSchool.forEach((element) => {
          const items = element.tagSchool;
          if (items) {
            const tags = items.split(",");
            tags.forEach((tag) => {
              if (!tagSchool.includes(tag)) {
                tagSchool.push(tag);
              }
            });
          }
        });

        const tagArea = [];
        data.payload.tagArea.forEach((element) => {
          const items = element.tagArea;
          if (items) {
            const tags = items.split(",");
            tags.forEach((tag) => {
              if (!tagArea.includes(tag)) {
                tagArea.push(tag);
              }
            });
          }
        });

        // add "trasversali" area
        if (tagArea.length > 0 && !tagArea.includes("TR")) tagArea.push("TR");

        this.setState({
          payloadTypes: { options: payloadTypes },
          tagSchool,
          tagArea,
          currentQualification,
        });
      })
      .catch((error) => {
        console.log(error);
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }
      });
  }

  loadCourses(courseType) {
    courseService
      .listAll(courseType)
      .then(({ data }) => {
        const { courses } = data.payload;

        if (courses) {
          this.setState({ courses, loading: false });

          if (this.state.courseFiltered) {
            const {
              currentType,
              currentTagArea,
              currentTagSchool,
              currentQualification,
            } = this.state;
            this.filterCourses(
              currentType,
              currentTagArea,
              currentTagSchool,
              currentQualification
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }

        this.setState({ loading: false });
      });
  }

  changeType = (_, currentType) => {
    const { currentTagArea, currentTagSchool, currentQualification } =
      this.state;
    this.filterCourses(
      currentType,
      currentTagArea,
      currentTagSchool,
      currentQualification
    );
  };

  changeQualification = (newQualification) => {
    const {
      currentType,
      currentTagArea,
      currentTagSchool,
      currentQualification,
    } = this.state;
    const qualification =
      currentQualification === newQualification ? "" : newQualification;
    this.filterCourses(
      currentType,
      currentTagArea,
      currentTagSchool,
      qualification
    );
  };

  changeTagSchool = (newTagSchool) => {
    const { currentType, currentQualification, currentTagSchool } = this.state;
    const tagSchool = currentTagSchool === newTagSchool ? "" : newTagSchool;

    // reset currentTagArea and currentTagSchool
    this.filterCourses(currentType, "", tagSchool, currentQualification, "");
  };

  changeTagArea = (newTagArea) => {
    const {
      currentType,
      currentTagSchool,
      currentQualification,
      currentTagArea,
    } = this.state;
    const tagArea = currentTagArea === newTagArea ? "" : newTagArea;

    this.filterCourses(
      currentType,
      tagArea,
      currentTagSchool,
      currentQualification
    );
  };

  filterCourses(
    currentType,
    currentTagArea,
    currentTagSchool,
    currentQualification
  ) {
    // ---- init values -----
    let courseFilter = false;
    let courseFiltered = this.state.courses;
    if (
      currentType ||
      currentTagArea ||
      currentTagSchool ||
      currentQualification
    ) {
      courseFilter = true;

      // -----  apply filters -----
      if (currentType) {
        courseFiltered = courseFiltered.filter((item) =>
          item.courseType.includes(currentType)
        );
      }

      if (currentQualification) {
        if (currentQualification === "laurea") {
          courseFiltered = courseFiltered.filter((item) => item.laurea);
        } else if (currentQualification === "diploma") {
          courseFiltered = courseFiltered.filter(
            (item) => item.diploma && !item.laurea
          );
        } else {
          courseFiltered = courseFiltered.filter(
            (item) => !(item.diploma || item.laurea)
          );
        }
      }

      if (currentTagSchool) {
        if (currentTagSchool === "primaria") {
          courseFiltered = courseFiltered.filter(
            (item) =>
              !item.tagSchool ||
              item.tagSchool.includes("primaria") ||
              item.tagSchool.includes("infanzia")
          );
        } else if (currentTagSchool === "secondaria") {
          courseFiltered = courseFiltered.filter(
            (item) => !item.tagSchool || item.tagSchool.includes("secondaria")
          );
        }
      }

      if (currentTagArea) {
        if (currentTagArea === "TR") {
          courseFiltered = courseFiltered.filter(
            (item) => !item.tagArea || item.tagArea.includes("TR")
          );
        } else {
          // area disciplinare specifica
          // oppure senza are disciplinare e senza scuola
          // oppure senza are disciplinare e scuola secondaria
          courseFiltered = courseFiltered.filter(
            (item) =>
              (item.tagArea && item.tagArea.includes(currentTagArea)) ||
              (!item.tagArea && !item.tagSchool) ||
              (!item.tagArea && item.tagSchool.includes("secondaria"))
          );
        }
      }
    }

    this.setState({
      courseFilter,
      courseFiltered,
      currentType,
      currentTagArea,
      currentTagSchool,
      currentQualification,
    });
  }

  openCourse(code) {
    window.open(`${ROUTES.COURSE_LANDINGPAGE}\\${code}`, "_blank");
  }

  getReqTitle() {
    let reqTitle = "";
    if (this.state.courses) {
      const course = this.state.courses[0];
      if (course) {
        if (course.laurea) {
          reqTitle =
            course.courseType === "MD" || course.courseType === "MDU"
              ? "Laurea Specialistica/Vecchio Ordinamento"
              : "Laurea";
        } else if (course.diploma) {
          reqTitle = "Diploma";
        } else {
          reqTitle = "Nessuno";
        }
      }
    }

    return reqTitle;
  }

  renderFilterSection() {
    const {
      currentTagArea,
      currentTagSchool,
      currentQualification,
      tagArea,
      payloadTypes,
      currentType,
      tagSchool,
      isTyped,
    } = this.state;

    if (
      (tagArea && tagArea.length > 0) ||
      (tagSchool && tagSchool.length > 0)
    ) {
      const reqTitle = this.getReqTitle();

      return (
        <>
          <MSection className="mb-3">
            <h6>Filtra i corsi per</h6>
            {currentType === "MU" && (
              <footer className="blockquote-footer">
                I Master proposti dai filtri di questa pagina sono solo
                consigliati!
              </footer>
            )}
            <hr />
            {!isTyped && (
              <>
                <Row>
                  <Col sm={4}>
                    <span className="text-dark">
                      <b>Tipo di corso: </b>
                    </span>
                  </Col>
                  <Col sm={8}>
                    <MSelect
                      payload={payloadTypes}
                      value={currentType}
                      onChange={this.changeType}
                    ></MSelect>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col sm={4}>
                    <span className="text-dark">
                      <b>Titolo di Studio richiesto: </b>
                    </span>
                  </Col>
                  <Col sm={8}>
                    {isTyped ? (
                      <>
                        <span>{reqTitle}</span>
                      </>
                    ) : (
                      <>
                        <span
                          className="course-checkbox"
                          onClick={(e) => this.changeQualification("none")}
                        >
                          {currentQualification === "none" ? (
                            <Icon icon="it-check" className="icon-sm mr-2" />
                          ) : (
                            <Icon
                              icon="it-fullscreen"
                              className="icon-sm mr-2"
                            />
                          )}
                          Nessun titolo
                        </span>
                        <span
                          className="course-checkbox"
                          onClick={(e) => this.changeQualification("diploma")}
                        >
                          {currentQualification === "diploma" ? (
                            <Icon icon="it-check" className="icon-sm mr-2" />
                          ) : (
                            <Icon
                              icon="it-fullscreen"
                              className="icon-sm mr-2"
                            />
                          )}
                          Diploma
                        </span>
                        <span
                          className="course-checkbox"
                          onClick={(e) => this.changeQualification("laurea")}
                        >
                          {currentQualification === "laurea" ? (
                            <Icon icon="it-check" className="icon-sm mr-2" />
                          ) : (
                            <Icon
                              icon="it-fullscreen"
                              className="icon-sm mr-2"
                            />
                          )}
                          Laurea
                        </span>
                      </>
                    )}
                  </Col>
                </Row>
                <hr />
              </>
            )}

            {tagSchool && tagSchool.length > 0 && (
              <>
                <Row>
                  <Col sm={4}>
                    <p className="text-dark ml-4">
                      <b>Scuola</b>
                    </p>
                    {currentTagSchool && currentTagSchool !== "" && (
                      <span
                        className="btn btn-outline-primary ml-4 mb-2"
                        onClick={(e) => this.changeTagSchool("")}
                      >
                        Cancella Filtro
                      </span>
                    )}
                  </Col>
                  <Col sm={8}>
                    <span
                      className="course-checkbox"
                      onClick={(e) => this.changeTagSchool("primaria")}
                    >
                      {currentTagSchool === "primaria" ? (
                        <Icon icon="it-check" className="icon-sm mr-2" />
                      ) : (
                        <Icon icon="it-fullscreen" className="icon-sm mr-2" />
                      )}
                      Primaria/Infanzia
                    </span>
                    <span
                      className="course-checkbox"
                      onClick={(e) => this.changeTagSchool("secondaria")}
                    >
                      {currentTagSchool === "secondaria" ? (
                        <Icon icon="it-check" className="icon-sm mr-2" />
                      ) : (
                        <Icon icon="it-fullscreen" className="icon-sm mr-2" />
                      )}
                      Secondaria
                    </span>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              {tagArea &&
                tagArea.length > 0 &&
                currentTagSchool !== "primaria" && (
                  <>
                    <Col size={12}>
                      <hr />
                      <p className="text-dark ml-4">
                        <b>Area disciplinare</b>
                      </p>
                      <Row>
                        <Col sm={4}>
                          {currentTagArea && currentTagArea !== "" && (
                            <span
                              className="btn btn-outline-primary ml-4"
                              onClick={(e) => this.changeTagArea("")}
                            >
                              Cancella Filtro
                            </span>
                          )}
                        </Col>
                        <Col sm={8}>
                          {tagArea.map((tag, i) => (
                            <span
                              className="course-checkbox"
                              key={`area-${i}`}
                              onClick={(e) => this.changeTagArea(tag)}
                            >
                              {currentTagArea === tag ? (
                                <Icon
                                  icon="it-check"
                                  className="icon-sm mr-2"
                                />
                              ) : (
                                <Icon
                                  icon="it-fullscreen"
                                  className="icon-sm mr-2"
                                />
                              )}
                              {TAG_AREA[tag]}
                            </span>
                          ))}
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
            </Row>
          </MSection>
        </>
      );
    }
  }

  renderHeader() {
    const { currentType, payloadTypes, courses, isTyped } = this.state;

    return (
      <>
        {isTyped && (
          <>
            <div className="image-banner">
              <img
                className="w-100"
                src={`/img/courses/banner_${currentType.toLowerCase()}.webp`}
                alt={currentType}
              />
            </div>
            {/* <div className="m-5"> */}
            <div className="m-3 mt-5 m-lg-5 m-sm-3">
              {payloadTypes.options && payloadTypes.options.length > 0 && (
                <h3>{payloadTypes.options[0].label}</h3>
              )}
              {courses.length > 0 && (
                <p>{ReactHtmlParser(courses[0].summary)}</p>
              )}
            </div>
          </>
        )}
      </>
    );
  }

  renderCourseList() {
    const { courses, courseFiltered, courseFilter } = this.state;
    let toShow = courseFilter ? courseFiltered : courses;

    return (
      <>
        {toShow.length > 0 ? (
          <>
            <Row>
              {toShow.map((course, i) => (
                <Col md={6} lg={4} key={`course-${i}`}>
                  <a
                    className="no-pointer"
                    href={`${ROUTES.COURSE_LANDINGPAGE}\\${course.code}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <article className="card-wrapper card-space pointer">
                      <Card noWrapper className="card-bg card-img shadow">
                        <CardBody>
                          <div className="box-300">
                            <p className="width-full text-center text-green m-0">
                              <b>{course.label}</b>
                            </p>
                            <p className="text-green">
                              {ReactHtmlParser(course.title)}
                            </p>
                            <hr />
                            <Icon
                              icon="it-chart-line"
                              className="icon-sm mr-2"
                            />
                            {course.subTitle} <br />
                            <Icon icon="it-clock" className="icon-sm mr-2" />
                            <b>Scadenza</b>:{" "}
                            {dateHelper.toITDate(course.enrollDeadline)} <br />
                            {course.price && (
                              <>
                                <Icon icon="it-card" className="icon-sm mr-2" />
                                <b>Prezzo</b>: {course.price}
                                <br />
                              </>
                            )}
                            {course.tagSchool && (
                              <Chip className="m-1">
                                <ChipLabel>Scuola {course.tagSchool}</ChipLabel>
                              </Chip>
                            )}
                            {course.tagArea && (
                              <>
                                {course.tagArea.split(",").map((tag, j) => (
                                  <Chip
                                    key={`tagArea-${j}`}
                                    className="m-1"
                                    color="primary"
                                  >
                                    <ChipLabel>{TAG_AREA[tag]}</ChipLabel>
                                  </Chip>
                                ))}
                              </>
                            )}
                          </div>
                          <CardFooter>
                            <Button
                              className="float-right"
                              color="primary"
                              outline
                            >
                              Iscriviti
                            </Button>
                          </CardFooter>
                        </CardBody>
                      </Card>
                    </article>
                  </a>
                </Col>
              ))}
            </Row>
          </>
        ) : (
          <BoxSuccess>
            La ricerca non ho prodotto alcun risultato. Cambia i filtri di
            ricerca.
          </BoxSuccess>
        )}
      </>
    );
  }

  render() {
    const { loading, payloadTypes } = this.state;
    if (loading) return <MnemoLoading></MnemoLoading>;

    const { courseType } = this.props.match.params;

    //const filterSection = this.renderFilterSection();
    const courseListSection = this.renderCourseList();
    const filterSidebar = this.renderFilterSection();
    const header = this.renderHeader();

    let title = "Corsi Mnemosine";
    let image = "/img/courses/banner_mu.webp";
    if (courseType) {
      title =
        payloadTypes.options && payloadTypes.options.length > 0
          ? payloadTypes.options[0].label
          : "Corsi Mnemosine";
      image =
        "/img/courses/banner_" + this.state.currentType.toLowerCase() + ".webp";
    }

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta property="og:image" content={image} />
        </Helmet>
        <section className="my-4">
          <Container>
            {header}
            {courseType ? (
              <>
                {filterSidebar}
                {courseListSection}
              </>
            ) : (
              <>
                <section className="programmes-single">
                  {/*<a href="/corso-online-formazione/PFU5CFU" className="programmes-single__content">*/}
                    <div className="programmes-single__wrapper">
                      <h2 className="programmes-single__title">
                        Percorso di Formazione di 5 CFU DM 108/2022 - <span className="text-red text-hightlights span-price"> € 150</span>
                      </h2>
                      <h6 className="text-highlights">
                        Riservato ai Docenti vincitori del "Concorso straordinario Bis"{" "}
                        <span className="small">
                          - esame in presenza in <strong>100 città</strong> (ripetibile gratuitamente) come previsto dall’art. 18 comma 6 del DM 108/22.
                          Corso erogato dall’Università UniCamillus L. R. ed autorizzato con Decreto del 28/11/2017 pubblicato in Gazzetta Ufficiale n. 3 del 04/01/2018.<br/>
                        </span>
                        <span className="small">
                          Il corso, erogato su tutto il territorio nazionale, non avrà avvio prima del mese di gennaio 2023 e si concluderà, come previsto dalla norma, non oltre il 15 giugno 2023.<br/>
                      </span>
                      <span className="small">
                        Il costo massimo di iscrizione (tasse incluse) non supererà € 150 per ciascun/a docente che vi prenderà parte (prezzo calmierato secondo quanto stabilito dal decreto).
                      </span>
                      </h6>
                      {/* <h6 className="text-red text-highlights">da € 390</h6> */}
                      {/*<div className="programmes-single__button">
                        <ButtonPrimary
                          color="blue"
                          label="Maggiori informazioni"
                          href="/corso-online-formazione/PFU5CFU"
                        />
            </div>*/}
              {/* <a
              href="https://www.usrlazio.it/index.php?s=1052&wid=12038"
              target="_blank"
              ><b>Vedi Nota</b></a> */}
              <a
              href="/adesioni-5cfu"
              ><b>Se Interessato/a, aderisci alla Newsletter sul corso e ti forniremo tutte le informazioni</b></a>
                    </div>
                    {/* <div className="programmes-single__background">
                    <img src="/img/courses/new/5cfu.jpeg" />
                  </div> */}
                  {/*</a>*/}
                </section>
                <section className="programmes">
                  <div className="swiper-container">
                    <div className="swiper-wrapper">
                      <Row>
                        {COURSES_LIST.map((course, i) => (
                          <Col xs={12} sm={6} md={4} lg={4} key={`course-${i}`}>
                            <div className="swiper-slide">
                              <div className="programme-card">
                                <a
                                  className="programme-card__link"
                                  href={course.link}
                                >
                                  <div className="programme-card__header">
                                    <img
                                      className="programme-card__img"
                                      alt={course.title}
                                      src={course.image}
                                    />
                                  </div>
                                  <div className="programme-card__body">
                                    <h4 className="programme-card__title">
                                      {course.title}
                                      {course.subTitle && (
                                        <>
                                          <br />
                                          {/* <span className="small">
                                            {course.subTitle}
                                          </span> */}
                                          <span
                                            className="small"
                                            dangerouslySetInnerHTML={{
                                              __html: course.subTitle,
                                            }}
                                          />
                                        </>
                                      )}
                                    </h4>
                                    {course.highlights && (
                                      <>
                                        <h6
                                          className="text-red  text-highlights"
                                          dangerouslySetInnerHTML={{
                                            __html: course.highlights,
                                          }}
                                        />
                                      </>
                                    )}
                                  </div>
                                </a>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                </section>
                <section className="programmes">
                  <h2 className="programmes-title">Altri Corsi</h2>
                  <div className="swiper-container">
                    <div className="swiper-wrapper">
                      <Row>
                        {COURSE_SECONDARY_LIST.map((course, i) => (
                          <Col xs={12} sm={6} md={4} lg={3} key={`course-${i}`}>
                            <div className="swiper-slide">
                              <div className="programme-card">
                                <a
                                  className="programme-card__link"
                                  href={course.link}
                                >
                                  <div className="programme-card__header">
                                    <img
                                      className="programme-card__img"
                                      alt={course.title}
                                      src={course.image}
                                    />
                                  </div>
                                  <div className="programme-card__body">
                                    <h4 className="programme-card__title">
                                      {course.title}
                                      {course.subTitle && (
                                        <>
                                          <br />
                                          <span className="small">
                                            {course.subTitle}
                                          </span>
                                        </>
                                      )}
                                    </h4>
                                    {course.highlights && (
                                      <>
                                        <h6 className="text-red">
                                          {course.highlights}
                                        </h6>
                                      </>
                                    )}
                                  </div>
                                </a>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                </section>
              </>
            )}
          </Container>
        </section>
      </>
    );
  }
}

export default withRouter(CourseBrowser);
