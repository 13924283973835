export const ROUTES = {
    
    // public
    ROOT: '/',
    LOGIN: '/login',
    LOGIN_OLD: '/login-to-telematic-university',
    PAGE: '/page',
    REGISTER: '/register',
    PASSWORD_RESET: '/password-reset',
    PASSWORD_RESET_TOKEN: '/password-reset-token',
    PASSWORD_RESET_UNTOKEN: '/password-reset-untoken',
    PRIVACY_POLICY: '/page/privacy-policy',
    INFORMATIVA: '/page/informativa-dati-personali',
    PAGE_SUPPORT: '/supporto-tecnico',
    WORK_WITH_US: '/page/lavora-con-noi',
    
    PRIVACY_POLICY_REV2022: '/page/privacy-policy',
    COOKIE_POLICY_REV2022: '/page/cookie-policy',
    PRINCIPI_ETICI_REV2022: '/page/principi-etici',
    INFORMATIVA_REV2022: '/page/informativa-dati-personali',
    
    PAGE_WHOARE_OLD: '/page/chi-siamo',
    PAGE_WHOARE_OLD2: '/associazione-mnemosine',
    PAGE_WHOARE: '/chi-siamo',

    COURSE_BROWSE_OLD: '/corsi-online',
    COURSE_BROWSE: '/corsi-mnemosine',
    COURSE_SSD_BROWSE_OLD: '/trova-master-ssd',
    COURSE_SSD_BROWSE: '/corsi-mnemosine/master-universitari',
    COURSE_LANDINGPAGE: '/corso-online',
    COURSE_FULLLAYOUTPAGE: '/corso-online-formazione',
    COURSE_LANDINGPAGE_IFRAME: '/iscrizione-iframe',

    // bog
    BLOG_HOME: '/mnemosine-news',
    BLOG_ARTICLE: '/mnemosine',

    // public promo
    PROMO_AMICI: '/promo-amici',
    PROMO_FAMIGLIA: '/promo-famiglia',
    PROMO_ISTITUTO: '/promo-istituto',
    PROMO_CORSO_GRATUITO: '/progetto-corso-gratuito',
    PROMO_TABLET: '/tablet-omaggio',
    PROMO_50: '/promo-50',

    //public Regolamento
    PAGE_RULE: '/regolamento',

    //public impegno e solidarietà
    PAGE_SOLIDARITY: '/solidarieta',
    PAGE_LEONI_SICANI: '/associazione-a-s-d-leoni-sicani-onlus',
    PAGE_UNICEF: '/unicef-istruzione-per-i-bambini-colpiti-dalla-crisi-in-siria',

    //public 24cfu
    PAGE_CONCESSIONS: '/contratto-collettivo-nazionale-integrativo',
    PAGE_BENEFITSC24: '/vantaggi-corsi-24cfu',
    PAGE_BENEFITSM24: '/vantaggi-master-24cfu',
    PAGE_REFERENCELEGISLATION: '/24-cfu-accesso',
    PAGE_REQUESTCERTIFICATION: '/riconoscimenti-cfu',

    //public clil 
    PAGE_BENEFITSCLIL: '/clil-agevolazioni',

    //public ATA 
    PAGE_ACCESSTITLEATA: '/titoli-accesso-ai-profili-ata',

    //public Bonus carta docente
    PAGE_TEACHERCARD: '/come-generare-il-bonus-scuola-per-i-corsi-mnemosine',

    //public Dottorato
    PAGE_DOCTORATE: '/dottorati-di-ricerca',

    //public Gsuite details
    PAGE_GSUITEDETAILS: '/dettaglio-corso-google',

    //public 24cfu details
    PAGE_24CFUDETAILS: '/U24CFU',
    
    //public 5cfu insight
    PAGE_5CFUINSIGHT: '/approfondimenti-5cfu',
    PAGE_DECRETOMINISTERIALE108: '/decreto-ministeriale-n-108',



    //public page
    PAGE_NEWSCANCEL: '/newscancel',

    // my
    DASHBOARD: '/dashboard',
    MY_PROFILE: '/my/profile',
    MY_ENROLLMENT: '/my/enrollment',
    MY_ENROLLMENT_PENDING: '/my/enrollment-pending',
    MY_ENROLLMENT_PREREQUISITES: '/my/enrollment-prerequisites',
    PAYMENT_RESULT_OK: '/bankpayment-result/ok',
    PAYMENT_RESULT_KO: '/bankpayment-result/ko',
    PAYMENT_RESULT_NOTIFY: '/bankpayment-notify',
    PAYMENT_GOMP_RESULT_NOTIFY: '/pagopa-notify',
    MY_QUALIFICATION: '/my/qualifications',
    MY_DOCUMENT: '/my/document',
    MY_INVOICEDATA: '/my/invoice-data',

    // course
    COURSE_DASHBOARD: '/course/dashboard',
    COURSE_LIST: '/course/list',
    COURSE_TICKETS: '/course/tickets',
    COURSE_PAYMENTS: '/course/payments',
    COURSE_INVOICES: '/course/invoices',
    COURSE_TICKET_DETAIL: '/course/ticket',
    COURSE_PAYMENT_CD: '/course/payment/carta_del_docente',
    COURSE_TITLE_SELF_DECLARATION : '/course/title-self-declaratio',   

    COURSE_EXAM_WRITTEN_RESERVATION: '/course/exam-written-reservation',
    COURSE_EXAMFINAL_ONSITE_RESERVATION: '/course/exam-written-onsite-reservation',
    COURSE_EXAM_LIVE_RESERVATION: '/course/exam-live-reservation',
    COURSE_EXAM_DONE: '/course/exam-done',    
    COURSE_DOCS: '/course/docs',
    COURSE_ISTRUCTION: '/course/istruction',
    COURSE_TUTOR: '/course/tutor',
    COURSE_PROVIDER: '/course/provider',
    COURSE_TITLE_REQUEST: '/course/title-request',
    PERFEZIONAMENTO_EXAM_WRITTEN: '/course/perfezionamento-exam-written',
    PERFEZIONAMENTO_EXAM_LIVE: '/course/perfezionamento-exam-live',

    // learning
    LEARNING_MAIN: '/learning/courseMain',
    LEARNING_LESSON: '/learning/lesson',
    LEARNING_FINAL_PAPER: '/learning/final-paper',
    LEARNING_QUIZ_MAIN: '/learning/quizMain',
    LEARNING_QUIZ_FEEDBACK: '/learning/quiz-feedback-final',

    // MU
    MU_COURSE_PAYMENTS: '/course/mu-payments',
    MU_COURSE_DOCS: '/course/mu-docs',
    MU_SELF_DECLARATION: '/course/mu-self-declaration',
    MU_COURSE_TITLE_REQUEST : '/course/mu-title-request',
    MU_C24_CERT_REQUEST: '/course/mu-c24-cert-request',

    MU_PAYMENT_REQUEST : '/course/mu-paymentother-request',

    // M24
    M24_LESSON: '/learning/m24-lesson',

    // C24     
    C24_EXAMWRITTEN_RESERVATION: '/course/c24-examwritten-reservartion',
    C24_EXAMWRITTEN_ONSITE_RESERVATION: '/course/c24-examwritten-onsite-reservartion',
    C24_EXAMLIVE_RESERVATION: '/course/c24-examlive-reservartion',
    C24_EXAMWRITTEN: '/course/c24-exam-written',
    C24_CERT_REQUEST: '/course/c24-cert-request',

    C24_EXAMLIVE: '/course/c24-examlive',

    COURSE_CFU_SELF_DECLARATION : '/course/cfu-self-declaration',

    //GOOGLE_MEET: '/meet-google',

    // ICDL
    ICDL_LESSON: '/learning/icdl-lesson',
    ICDL_CERTIFICATION: '/course/icdl-certification',
    ICDL_EXAM_RESERVATION: '/course/icdl-exam-reservartion',

    COURSE_MANIFESTO: '/course/manifesto',

    // CV
    CV_DOCS: '/course/cv-docs',

    // ESB
    ESB_LESSON: '/learning/esb-lesson',
    ESB_EXAM_RESERVATION: '/course/esb-exam-reservartion',

    // tools 
    MOBILE_CONFIRM: '/tools/mobile-confirm',

    // services
    QUALIFICATION_EVALUATION: '/valutazione-titoli',
    QUALIFICATION_MAP: '/corrispondenza-titoli',
    TITLE_DELIVERY: '/prenotazione-ritiro-titoli',
    TITLE_DELIVERY_ESB: '/prenotazione-ritiro-titoli-inglese',

    // agenda
    AGENDA: '/agenda',

    // career
    CAREER: '/career',
    CONFIRM: '/confirm/:key',

    // admin
    COURSE_MANAGER: '/course-manager',
    COURSE_TYPE_MANAGER: '/course-type-manager',
    COURSE_FORM: '/course-form',
    COURSE_TYPE_FORM: '/course-type-form',
    LESSON_FORM: '/lesson-form',
    QUIZ_FORM: '/quiz-form',
    EXAMPLES: '/examples',
    USER_LIST: '/users',
    COURSE_REPOQUIZ_MANAGER: '/repoquiz',
    REPOQUIZ_FORM: '/repoquiz-form',
    PROMOTIONAL_MANAGER: '/promotional-manager',
    PROMOTIONAL_FORM: '/promotional-form',
    USER_SYNC_IN: '/user/syncin',
    SITECONFIG_MANAGER: '/siteconfig-manager',
    SITECONFIG_FORM: '/siteconfig-form',

    PAGE_CONTACT: '/contatti',
    PAGE_CONTACT_REQUEST: '/richiesta-contatto',
    PAGE_CONTACT_INFO: '/raggiungere-sede',
    
    BLOG_CATEGORY_MANAGER: '/cms/blog-category',
    BLOG_CATEGORY_FORM: '/cms/blog-category-form',
    BLOG_ARTICLE_MANAGER: '/cms/blog-article',
    BLOG_ARTICLE_FORM: '/cms/blog-article-form',

    LIBRARY_CATEGORY_MANAGER: '/cms/library-category',
    LIBRARY_CATEGORY_FORM: '/cms/library-category-form',
    LIBRARY_BOOK_MANAGER: '/cms/library-book',
    LIBRARY_BOOK_FORM: '/cms/library-book-form',
    LIBRARY_AUTHOR_MANAGER: '/cms/library-author',
    LIBRARY_AUTHOR_FORM: '/cms/library-author-form',

    PAGE_MANAGER: '/cms/page-list',
    PAGE_FORM: '/cms/page-form',
    PAGE_BIBLIOTECA: "/biblioteca",
    PAGE_CORSIDILAUREA: "/corsi-di-laurea",
    PAGE_SEDI_ESAMI: "/sedi-esami",

    QUALIFICATION_DATA_ANS_MANAGER: '/cms/ans-manager',

  PAGE_SEDI_ESAMI: "/sedi-esami",

  FORM_5CFU: "/adesioni-5cfu",

};

export const STRINGS = {
  TITLE: "Corsi Mnemosine",
  COMPANY: "Associazione Mnemosine",
};

export const CACHE_KEYS = {
  SESSION: "session",
};

export const USER_QUALIFICATIONS = {
  DIPLOMA: "diploma",
  LAUREA: "laurea",
  IMMATRICOLAZIONE: "immatricolazione",
};

export const ADDRESS_TYPES = {
  BORN: "bornAddressId",
  DELIVERY: "deliveryAddressId",
  RESIDENCE: "addressId",
};

export const ADDRESS_TYPES_ACCESSORS = {
  [ADDRESS_TYPES.RESIDENCE]: "residenceAddress",
  [ADDRESS_TYPES.BORN]: "bornAddress",
  [ADDRESS_TYPES.DELIVERY]: "deliveryAddress",
};

export const GENDERS = [
  { value: null, label: "-" },
  { value: "m", label: "M" },
  { value: "f", label: "F" },
];

export const DOC_TYPES = [
  { value: null, label: "Tipo di documento" },
  { value: "identityCard", label: "Carta d'identità" },
  { value: "drivingLicence", label: "Patente di guida" },
  { value: "passport", label: "Passaporto" },
];

export const DOC_TYPES_MAP = {
  identityCard: "Carta d'identità",
  drivingLicence: "Patente di guida",
  passport: "Passaporto",
};

export const VIEWS = {
  LEARNING: "Learning",
  MY: "My",
  COURSE: "Course",
  CAREER: "Career",
  AGENDA: "Agenda",
  PAGE: "Page",
};

export const STATUS_CODES = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTH: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE: 422,
  INTERNAL_ERROR: 500,
};

export const ENROLLMENT_STEPS = {
  START: "start",
  FILL: "fill",
  SIGN: "sign",
  PAY: "pay",
  END: "end",
  VIRTUALSTAMP: "virtualstamp"
};

export const TITLEREQUEST_STEPS = {
  START: "start",
  SIGN: "sign",
  PAY: "pay",
  DOWNLOAD: "download",
};

export const COMPANY = {
  SRL: "srl",
  ASSOCIAZIONE: "associazione",
  UNICAMILLUS: "unicamillus",
};

export const PAYMENT_TYPES = {
  BONIFICO: "Bonifico",
  CARTA_DI_CREDITO: "Carta di credito",
  CARTA_DEL_DOCENTE: "Carta del docente",
  PAGOPA: "PagoPA",
};

export const PAYMENT_STATUS = {
  STARTED: "started",
  APPROVED: "approved",
  SELFAPPROVED: "selfApproved",
  TOAPPROVE: "toApprove",
  ANNULLED: "annulled",
  REJECTED: "rejected",
};

export const PAYMENT_STATUS_LABEL = {
  started: "da completare",
  approved: "approvato",
  selfApproved: "approvato",
  toApprove: "da approvare",
  annulled: "annullato",
  rejected: "rifiutato",
};

export const WORKINGSTATUS = [
  { value: null, label: "-" },
  { value: "precario", label: "Precario" },
  { value: "non docente", label: "non Docente" },
  { value: "tempo indeterminato", label: "tempo Indeterminato" },
];

export const TAG_AREA = {
  AR: "Artistica",
  SG: "Storico Geografica",
  SC: "Scientifica",
  TE: "Tecnico Pratica",
  GE: "Giuridico Economica",
  EC: "Economica",
  EM: "Educazione Motoria",
  UM: "Umanistica",
  PS: "Psicomotoria",
  LI: "Linguistica",
  AM: "Artistico Musicale",
  TR: "Trasversale",
};

export const SITE_CONFIG = {
  EXAM_WRITTEN_HOUR: "EXAM_WRITTEN_HOUR",
};

export const COURSE_STATUS = {
  ACTIVE: "active",
  COMPLETED: 'completed',
  RETIRED: 'retired',
  DELETED: 'deleted'
};


export const PRESET_METADATA_QUIZ = [
  {
    label: "Test Intermedio",
    preset: `{
            "repeat": true, 
            "attempts": 0, 
            "mandatory": true,
            "storable": false,
            "constraints": {
                "duration": 0,
                "isPaymentOK": false
            },
            "feedbackFinal": {
                "brief": false, 
                "detail": true,
                "onlyAccepted": false
            }, 
            "feedbackAnswer": true
        }`,
  },
  {
    label: "Test Intermedio da Deposito Domande",
    preset: `{
            "repeat": true, 
            "attempts": 0, 
            "mandatory": true,
            "storable": false,
            "repository": [
                {
                    "name": "[inserisci_il_nome_della_repo]",
                    "questions": '[insersci_quante_domande_(int)]'
                }
            ],
            "constraints": {
                "duration": 0,
                "isPaymentOK": false
            },
            "feedbackFinal": {
                "brief": false, 
                "detail": true,
                "onlyAccepted": false
            }, 
            "feedbackAnswer": true
        }`,
  },
  {
    label: "Prova Finale",
    preset: `{
            "repeat": false, 
            "attempts": 0, 
            "mandatory": false,
            "storable": true,
            "constraints": {
                "duration": 0,
                "isPaymentOK": false
            },
            "feedbackFinal": {
                "brief": true, 
                "detail": false,
                "onlyAccepted": false
            }, 
            "feedbackAnswer": false
        }`,
  },
  {
    label: "Prove di Profitto da Deposito Domande",
    preset: `{
            "repeat": false,
            "attempts": 1,
            "duration": 20,
            "mandatory": false,
            "storable": true,
            "loseFocus": true,
            "code": "[inserisci_il_codice_insegnamento]",
            "repository": [
                {
                    "name": "[inserisci_il_nome_della_repo]",
                    "questions": '[insersci_quante_domande_(int)]'
                },
                {
                    "name": "[inserisci_il_nome_della_repo]",
                    "questions": '[insersci_quante_domande_(int)]'
                }
            ],
            "constraints": {
                "duration": 0,
                "isPaymentOK": true
            },
            "feedbackFinal": {
                "brief": false,
                "detail": false,
                "onlyAccepted": true
            },
            "feedbackAnswer": false
        }`,
  },
];
