import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MnemoLoading, BoxDanger, Title, SupportoTecnico } from "components/misc";
import { pageService, networkErrorHelper } from "libs";
import ReactHtmlParser from 'react-html-parser';
import Axios from 'axios';
import { Helmet } from 'react-helmet';

class Page extends Component {

    state = {
        loading: true,
        loadingError: false,
        title: '',
        content: '',
        summary: '',
        slug: 0
    };

    componentDidMount() {

        const { slug } = this.props.match.params;
        this.loadRemote(slug);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        const { slug } = nextProps.match.params;
        if (this.state.slug !== slug) {
            this.loadRemote(slug);
        }
    }

    loadRemote(slug) {
        pageService
            .page(slug)
            .then(({ data }) => {

                const { title, summary, slug, version, content } = data.payload.page;

                if (content && content.length > 0) {

                    // read content from DB
                    this.setState({
                        title, summary, slug,
                        loading: false,
                        content
                    });
                } else {

                    // read content from file
                    const path = '/assets/page/' + slug + '_' + version + '.html';
                    Axios.get(path).then((content) => {
                        this.setState({
                            title, summary, slug,
                            loading: false,
                            content: content.data
                        });
                    })
                        .catch(error => {
                            this.setState({ loadingError: true, loading: false });
                        });
                }
            })
            .catch(error => {

                // if not found error redirect to 404 page
                if (networkErrorHelper.is404(error)) {
                    this.props.history.push(`/404`);
                } else {
                    networkErrorHelper.notify(error);
                    this.setState({ loadingError: true, loading: false });
                }
            });
    }

    render() {

        const { loading, loadingError, title, content, summary } = this.state;

        return (
            <>
                {loading ? (<MnemoLoading />) : (
                    <>
                        {loadingError ? (<BoxDanger>Si è verificato un errore durante
                            il caricamento della pagina, se il problema persiste <SupportoTecnico /></BoxDanger>) : (
                            <>
                                <Helmet>
                                    <title>{title}</title>
                                    <meta property="og:title" content={title} />
                                </Helmet>
                                <Title>{title}</Title>
                                {summary &&
                                    (<div className="m-4" >
                                        {ReactHtmlParser(summary)}
                                    </div>)}
                                <div className="m-4" >
                                    {ReactHtmlParser(content)}
                                </div>
                            </>
                        )}
                    </>)}
            </>
        );
    }
}

export default withRouter(Page);