import React, { Component } from 'react';

export class MLabel extends Component {
    
    /**
     * PROPS: 
     * id       : identifier 
     * label    : title text (brief description of fields)
     * value    : the value, doesn't used
     * infoText : addtional text used when the input in invalid
     */
    render() {

        // get properties
        const { label, infoText } = this.props;
                
        const className = (this.props.className) ? this.props.className : '';
        
        return (
            <div className={className}>
                <h6>{label}</h6>
                <p>{infoText}</p>
            </div>
        );
    }

}