import React, { Component } from "react";

import { Title, MnemoLoading } from "components/misc";
import { Button, Container, Row, Col, Card, CardBody } from 'design-react-kit';
import { adminService } from '../../../libs/api';
import { networkErrorHelper } from "../../../libs";
import { MInput, MTextArea } from "components/forms";


export class SyncIn extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            from: '2021-06-22 00:00:00',
            to: '2022-09-30 23:59:59',
            results:'',
            isLoading: false,
            syncUserId: 0,
            syncLimit: 1000000,
        }
    }

    /** syncIn */
    syncIn = () =>{
        this.setState({
            results: '',
            isLoading: true
        });

        const payload = {
            from: this.state.from,
            to: this.state.to
        }

        adminService.studentUpdateSyncIn(payload).then((data) => {
            this.setState({
                results: JSON.stringify(data.data.payload.results, undefined, 4),
                isLoading: false
            });
        }).catch(error => {
            this.setState({ isLoading: false });
            networkErrorHelper.notify(error);
        })
    }

    /** update synced */
    updateSynced = () =>{
        this.setState({ isLoading: true });

        let filter = {
            syncUserId: this.state.syncUserId,
            syncLimit: this.state.syncLimit,
        }

        adminService.userUpdateSyncIn(filter).then((data) => {
            this.setState({
                isLoading: false,
                syncUserId: 0,
                syncLimit: 10,
            });

        }).catch(error => {
            this.setState({ isLoading: false });
            networkErrorHelper.notify(error);
        })
    }

    onChange = (field, value) => {
        console.log(field, value);
        this.setState({ [field]: value });
    };

    //---

    render() {

        return (
            <>
            {(this.state.isLoading) ? (<MnemoLoading />) : (<section className="my-4">
                <Container>                       
                    <Card tag="div" className="card-bg card-big rounded border-bottom-card mb-4">
                    <CardBody>
                        <Title>Importa modifiche dal software segreteria</Title>
                        <hr/>
                        <p>Importa i valori dalla tabella StudentUpdate del DB mnemosine-admin ma non applica tali modifiche ai dati formazinedocenti. </p>
                        <p><b>NOTA. gli aggiornamenti già importati vengono ignorati</b></p>
                        <hr/>
                        <div className="mt-5">
                            <Row>
                                <Col md={6}><MInput id="from" name="from" field="from" label="data inizio modifiche" onChange={this.onChange} value={this.state.from}/></Col>
                                <Col md={6}><MInput id="to" name="to" field="to" label="data fine modifiche" onChange={this.onChange} value={this.state.to}/></Col>
                            </Row>
                        </div>
                        <Button className="m-3" onClick={this.syncIn} >Avvia importazione</Button>
                        <Row>
                            <Col md={12}><MTextArea value={this.state.results} rows={8}/></Col>
                        </Row>
                    </CardBody>
                    </Card>

                    <Card tag="div" className="card-bg card-big rounded border-bottom-card mb-4">
                        <CardBody>                        
                            <Title>Aggiorna i dati di formazionedocenti</Title><hr/>
                            <p>Dall'aggiornamento vengono esclusi i titoli di studio, e campi email e mobile</p>
                            <div className="mt-5">
                                <Row>
                                    <Col md={6}><MInput id="syncUserId" name="syncUserId" field="syncUserId" label="userId" onChange={this.onChange}/></Col>
                                    <Col md={6}><MInput id="syncLimit" name="syncLimit" field="syncLimit" label="syncLimit" onChange={this.onChange}/></Col>
                                </Row>
                            </div>
                            <Button className="m-3" onClick={this.updateSynced} >Applica modifiche al DB</Button>
                        </CardBody>
                    </Card>
                </Container>
            </section>)}
            </>
        );
    }
}