import React from "react";

import { Title, MnemoCardLink, BoxSuccess } from "components/misc";
import { Helmet } from "react-helmet";

import { Row, Col, Button } from "design-react-kit";
import { MInput, MSelect, MTextArea, ValidatedForm } from "components/forms";
import { pageService, payloadBuilder, utilsService } from "libs";

export class PageCorsiDiLaurea extends ValidatedForm {
  //--- ---

  PAYLOADS = {
    courseLType: [
      { label: "selezionare", value: "" },
      {
        label: "Corso di Laurea Magistrale in Medicina e Chirurgia",
        value: "Corso di Laurea Magistrale in Medicina e Chirurgia",
      },
      {
        label: "Corso di Laurea Triennale in Infermieristica in LINGUA INGLESE",
        value: "Corso di Laurea Triennale in Infermieristica in LINGUA INGLESE",
      },
      {
        label: "Corso di Laurea Triennale in Fisioterapia in LINGUA INGLESE",
        value: "Corso di Laurea Triennale in Fisioterapia in LINGUA INGLESE",
      },
      {
        label: "Corso di Laurea Magistrale in Odontoiatria e Protesi Dentaria",
        value: "Corso di Laurea Magistrale in Odontoiatria e Protesi Dentaria",
      },
      {
        label: "Corso di Laurea Triennale in Ostetricia in LINGUA ITALIANA",
        value: "Corso di Laurea Triennale in Ostetricia in LINGUA ITALIANA",
      },
      {
        label:
          "Corso di Laurea Triennale in Tecniche di Laboratorio Biomedico in LINGUA ITALIANA",
        value:
          "Corso di Laurea Triennale in Tecniche di Laboratorio Biomedico in LINGUA ITALIANA",
      },
      {
        label:
          "Corso di Laurea Triennale in Tecniche di Radiologia Medica per immagini e Radioterapia in LINGUA INGLESE",
        value:
          "Corso di Laurea Triennale in Tecniche di Radiologia Medica per immagini e Radioterapia in LINGUA INGLESE",
      },
    ],
  };

  FIELDS_GROUP = [
    [
      {
        id: "name",
        name: "name",
        field: "name",
        label: "Nome",
        type: "text",
        component: MInput,
        className: "col-md-6",
      },
      {
        id: "surname",
        name: "surname",
        field: "surname",
        label: "Cognome",
        type: "text",
        component: MInput,
        className: "col-md-6",
      },
    ],
    [
      {
        id: "mobilePhone",
        name: "mobilePhone",
        field: "mobilePhone",
        label: "telefono",
        type: "text",
        component: MInput,
        className: "col-md-6",
      },
      {
        id: "email",
        name: "email",
        field: "email",
        label: "Email",
        type: "email",
        component: MInput,
        className: "col-md-6",
      },
    ],
    [
      {
        id: "courseLType",
        name: "courseLType",
        field: "courseLType",
        label: "Indica la Laurea di tuo interesse",
        type: "select",
        component: MSelect,
        payload: { options: this.PAYLOADS.courseLType },
        className: "col-md-12",
      },
    ],
    [
      {
        id: "textmessage",
        name: "textmessage",
        field: "textmessage",
        label: "Richiesta",
        type: "textarea",
        component: MTextArea,
        className: "col-md-12",
      },
    ],
  ];

  validation = {
    name: (value) => value.length > 2,
    surname: (value) => value.length > 3,
    textmessage: (value) => value.length > 3,
    mobilePhone: (value) =>
      /^((00|\+)\d{2}[. ]??)??\d{3}[. ]??\d{6,8}$/.test(value),
    courseLType: (value) => value.length > 3,
    email: (value) => /\S+@\S+\.\S+/.test(value),
  };

  errors = {
    name: "Inserire un Nome valido",
    surname: "Inserire un Cognome valido",
    email: `L'Email non è valida`,
  };

  emptyFields = {
    name: "",
    surname: "",
    email: "",
    mobilePhone: "",
    textmessage: "",
    courseLType: "",
  };

  state = {
    formActive: true,
    disabled: false,
    sendState: false,
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
  };

  constructor(props) {
    super(props);

    // bind event handler to invoke it from chiild component
    this.onChange = this.onChange.bind(this);
  }

  validateField(field, value) {
    const isValid = this.validation[field](value, this.state);
    return {
      value: value,
      isValid,
    };
  }

  onChange(field, value) {
    value = this.validateField(field, value);
    this.setState({ [field]: value });
  }
  //--- ---

  onSubmit = () => {
    let isValid = this.checkValidation();

    if (isValid) {
      // validate and submit inner validatedForm component
      this.innerValidatedForms.forEach((component) => {
        if (!component.onSubmit()) {
          isValid = false;
        }
      });
    }

    // if all components are valid submit this
    if (isValid) {
      this.setState({
        formActive: false,
        disabled: true,
      });

      const payload = payloadBuilder(this.state);
      utilsService
        .sendContactFormUnicam(payload)
        .then((result) => {
          if (result.data.payload.ret) {
            this.setState({
              formActive: true,
              disabled: false,
              sendState: true,
              ...this.emptyFields,
              defaultValues: { ...this.emptyFields },
            });
          } //true

          setTimeout(() => {
            this.setState({
              sendState: false,
            });
          }, 5000);
        })
        .catch((errors) => {
          this.setState({
            formActive: true,
            disabled: false,
          });
          console.log(errors);
        });
    }

    return isValid;
  };

  render() {
    let { formActive, defaultValues, disabled } = this.state;
    const { formValid, code } = this.renderFields(
      this.FIELDS_GROUP,
      defaultValues,
      formActive
    );

    const listCorsiLaurea = [
      {
        title: "Corso di Laurea Magistrale in Medicina e Chirurgia",
        actionLabel: "Maggiorni Informazioni",
        tag: "medicina-chirurgia-click",
        actionLink:
          "https://www.unicamillus.org/it/corsi-di-laurea-magistrale-in-medicina-e-chirurgia/",
      },
      {
        title: "Corso di Laurea Triennale in Infermieristica in LINGUA INGLESE",
        actionLabel: "Maggiorni Informazioni",
        tag: "infermieristica-click",
        actionLink:
          "https://www.unicamillus.org/it/corsi-di-laurea-triennale-nelle-professioni-sanitarie/corso-di-laurea-in-infermieristica/",
      },
      {
        title: "Corso di Laurea Triennale in Fisioterapia in LINGUA INGLESE",
        actionLabel: "Maggiorni Informazioni",
        tag: "fisioterapia-click",
        actionLink:
          "https://www.unicamillus.org/it/corsi-di-laurea-triennale-nelle-professioni-sanitarie/corso-di-laurea-in-fisioterapia/",
      },
      {
        title: "Corso di Laurea Magistrale in Odontoiatria e Protesi Dentaria",
        actionLabel: "Maggiorni Informazioni",
        tag: "odontoiatria-click",
        actionLink:
          "https://www.unicamillus.org/it/corso-di-laurea-magistrale-in-odontoiatria-e-protesi-dentaria/",
      },
      {
        title: "Corso di Laurea Triennale in Ostetricia in LINGUA ITALIANA",
        actionLabel: "Maggiorni Informazioni",
        tag: "ostetricia-click",
        actionLink:
          "https://www.unicamillus.org/it/corsi-di-laurea-triennale-nelle-professioni-sanitarie/corso-di-laurea-in-ostetricia/",
      },
      {
        title:
          "Corso di Laurea Triennale in Tecniche di Laboratorio Biomedico in LINGUA ITALIANA",
        actionLabel: "Maggiorni Informazioni",
        tag: "tecniche-laboratorio-biomedico-click",
        actionLink:
          "https://www.unicamillus.org/it/corsi-di-laurea-triennale-nelle-professioni-sanitarie/corso-di-laurea-in-tecniche-di-laboratorio-biomedico/",
      },
      {
        title:
          "Corso di Laurea Triennale in Tecniche di Radiologia Medica, per Immagini e Radioterapia in LINGUA INGLESE",
        actionLabel: "Maggiorni Informazioni",
        tag: "tecniche-radiologia-medica-click",
        actionLink:
          "https://www.unicamillus.org/it/corsi-di-laurea-triennale-nelle-professioni-sanitarie/corso-di-laurea-in-tecniche-di-radiologia-medica-per-immagini-e-radioterapia/",
      },
      {
        title: "Corso di Laurea Magistrale in Scienze della Nutrizione Umana",
        actionLabel: "Maggiorni Informazioni",
        tag: "scienze-nutrizione-click",
        actionLink: "https://www.scienzadellanutrizione.it/",
      },
    ];
    return (
      <>
        <Helmet>
          <title>Corsi di Laurea</title>
          <meta propterty="og:title" content="Corsi di Laurea" />
        </Helmet>
        <div className="image-banner">
          <img
            className="w-100"
            src={`/img/courses/corsi-di-laurea.jpg`}
            alt="Corsi di Laurea Mnemosine"
          />
        </div>
        <Title>Lauree VERE per un futuro lavorativo REALE!</Title>
        <section className="m-5 clearfix">
          <p>
            A volte ci chiediamo quale Laurea potrà consentire un lavoro sicuro.
            <br />
            Secondo ilsole24ore.it i "Nell’a.a. 2021/2022 sono oltre 5mila le
            Lauree attivate dalle università italiane".
            <br />
            Ma quante di queste realmente offriranno un futuro lavorativo
            valido?
            <br />
            Secondo AlmaLaurea "Le <strong>Professioni Sanitarie</strong> si
            confermano ancora una volta il{" "}
            <strong>
              gruppo disciplinare più solido a livello occupazionale
            </strong>
            " e riporta alcuni dati riguardanti l'inserimento nel mondo del
            lavoro entro 12 mesi dal conseguimento della Laurea:
          </p>
          <ul>
            <li>
              <strong>Medicina e Chirurgia</strong>: 100%
            </li>
            <li>
              <strong>Infermiere</strong>: 83,8%
            </li>
            <li>
              <strong>Fisioterapista</strong>: 80,1%
            </li>
            <li>
              <strong>Educatore professionale</strong>: 83,1%
            </li>
            <li>
              <strong>Logopedista</strong>: 82,5%
            </li>
            <li>
              <strong>Igienista Dentale</strong>: 86,1%
            </li>
            <li>
              <strong>Tecnico Audioprotesista</strong>: 79,2%
            </li>
          </ul>
          <p>
            Ecco i{" "}
            <strong>
              Corsi di Laurea in Medicina, Odontoiatria e Professioni Sanitarie
            </strong>{" "}
            proposti da Mnemosine:
          </p>
          <br />

          <Row>
            {listCorsiLaurea.map((course, index) => (
              <div className="col-md-6 col-lg-4">
                <MnemoCardLink {...course} />
              </div>
            ))}
          </Row>

          <Row className="mt-4">
            <h3>
              Per ulteriori Informazioni compila ed invia seguente format
              &#40;campi obbligatori&#41;:
            </h3>
          </Row>
          <Row className="mt-3">
            <Col>
              <form className="user" id="contactformunicam">
                {code}
              </form>

              {this.state.sendState && (
                <BoxSuccess className="mt-3">
                  Email inviata correttamente
                </BoxSuccess>
              )}

              <hr />

              <div className="m-0 text-right">
                <Button
                  disabled={disabled}
                  color="primary bg-dark"
                  className="mx-2 mt-6"
                  onClick={() => this.onSubmit()}
                >
                  INVIA
                </Button>
                <Button
                  color="primary"
                  outline
                  className="mx-2 mt-6"
                  onClick={() => this.resetForm()}
                >
                  Annulla
                </Button>
              </div>
            </Col>
          </Row>
        </section>
      </>
    );
  }
}
