import React, { Component } from 'react';

import { MSection, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { Helmet } from 'react-helmet';


export class PromoFamily extends Component {

    render() {
        const title = 'PROMO FAMIGLIA';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title >{title}</Title>
                            <hr />
                            <p>
                            Se siete almeno 2 Corsisti facenti parte del medesimo nucleo familiare (anche non conviventi) Sconto immediato di euro 70 cadauno per l'iscrizione 
                            ad uno dei seguenti corsi: Corsi 24 CFU, Master di I livello, Master di II livello, 
                            Diplomi di Perfezionamento post laurea o post diploma, Diplomi di Perfezionamento Biennale Post-Laurea (solo prima annualità) e Corsi di Perfezionamento.</p>
                            <p>L'agevolazione va applicata all'ultima rata in caso di pagamento rateizzato o dal totale previsto in caso di pagamento in un'unica soluzione.</p>
                            <p>Non è previsto alcun rimborso in caso di errato pagamento dell'intera quota.</p>
                            <p>La presente iniziativa non è cumulabile con altre iniziative commerciali e nel campo codice riservato presente durante la procedura di iscrizione 
                                online dovrà essere riportato da tutti i partecipanti il presente codice PROMOFAMIGLIA.</p>
                            <p>L'elenco dei partecipanti alla promo famiglia dovrà essere inviato all'indirizzo di posta elettronica <a href="mailto:comunicazioni@formazionedocenti.it"> comunicazioni@formazionedocenti.it </a>
                            contestualmente all'iscrizione online da almeno uno dei partecipanti riportati in elenco, pena l'esclusione dalla promozione.</p>
                            <div className='text-center'>
                                <img
                                    className="mx-auto"
                                    src="/img/pages/promo_famiglia.png"
                                    alt="Mnemosine"
                                />
                                <p><a href="/files/promozioni/promo_famiglia.docx"
                                    className="link-dark">Scarica il modulo Elenco Partecipanti “Promo Famiglia”</a></p>
                            </div>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}