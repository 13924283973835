import React from "react";
import { USER_QUALIFICATIONS } from "const";

import {
  FormEnabler,
  ValidatedForm,
  MInput,
  MSelect,
  MToggle,
} from "components/forms";

import {
  payloadBuilder,
  invalidFieldsMapper,
  networkErrorHelper,
  isEmptyObject,
  userService,
  dateHelper,
  qualificationService,
} from "libs";

import { Card, CardBody, Button } from "design-react-kit";

export class UserQualificationSchoolForm extends ValidatedForm {
  constructor(props) {
    super(props);

    this.state = {
      ...this.emptyFields,
      defaultValues: { ...this.emptyFields },
      formActive: true,
      forceRender: 0,
    };

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  //--- ---

  ERROR_MESSAGES = {
    aa: "Anno Scolastico non valido (AAAA/AAAA)",
    istitution: "Istituto non valido",
    city: "Città non valida",
    vote: "Voto non valido",
    description: "Indirizzo Titolo conseguito non valido",
    dateAchievement: "Data conseguimento non valida",
  };

  emptyFields = {
    aa: "",
    istitution: "",
    city: "",
    description: "",
    vote: "",
    dateAchievement: "",
    type: USER_QUALIFICATIONS.DIPLOMA,
    voteNotNumber: false,
    schoolCode: "",
  };

  validation = {
    istitution: (value) => /^[a-zA-Z0-9.,-/"àèé'ìòù/()\s"]+$/i.test(value),
    city: (value) => /^[a-zA-Z0-9'"àèé'ìòù/\s"]+$/i.test(value),
    description: (value) => value !== null && value.length > 3,
    aa: (value) => this.validateAA(value),
    vote: (value) => this.validateVote(value, this.state.voteNotNumber.value),
    voteNotNumber: (value) => this.resetVoteField(),
    dateAchievement: (value, aa, defaultValuesAA) =>
      this.validateDateRange(
        value,
        this.state.aa.value,
        this.state.defaultValues.aa
      ),
  };

  //--- start: more Validate func ---
  resetVoteField = () => {
    this.setState((state) => {
      state.vote = "";
    });
    
    return true;
  }; //resetVoteField

  validateVote = (value, voteNotNumber) => {
    if(value === ''){
        return false;
    }

    if (!voteNotNumber === true) {
      let defRule = /^[1-9]\d{0,2}?$/.test(value);
      return defRule;
    } else {
      return true;
    }
  }; //validateVote

  validateAA = (value) => {
    let aa = /^(\d{4})\/(\d{4})$/.test(value);

    if (!aa) {
      return false;
    } else {
      aa = value.split("/");
      if (parseInt(aa[1]) === parseInt(aa[0]) + 1) {
        return true;
      } else {
        return false;
      }
    }
  }; //validateAA

  validateDateRange = (value, aa, defaultValuesAA) => {
    let result = true;

    // validate dateAchievement
    const dateAchievement = dateHelper.noFuture(value);
    if (!dateAchievement) result = false;

    // validate AA on dateAchievement
    if (aa === undefined) {
      aa = defaultValuesAA;
    }
    if (defaultValuesAA !== "" && defaultValuesAA === aa) {
      aa = defaultValuesAA;
    }

    if (aa !== "" && value !== "") {
      let aaEnd = aa.split("/")[1];
      let dateAchievementYear = value.split("-")[0];

      if (aaEnd !== dateAchievementYear) result = false;
    }

    return result;
  }; //validateDateRange
  //--- end: more Validation func ---

  popolateSchoolTypeCode = [{ label: "Seleziona", value: -1 }];

  FIELDS_GROUP = [
    [
      {
        id: "istitution",
        field: "istitution",
        label: "Istituto",
        component: MInput,
        type: "text",
        className: "col-md-7",
        readOnly: true,
      },
      {
        id: "city",
        field: "city",
        label: "Città",
        component: MInput,
        type: "text",
        className: "col-md-5",
        readOnly: true,
      },
    ],
    [
      {
        id: "description",
        field: "description",
        label: "Titolo conseguito",
        component: MSelect,
        //onChange: (_, value) => alert(_, value),
        className: "col-md-12",
        payload: { options: this.popolateSchoolTypeCode },
      },
    ],
    [
      {
        id: "aa",
        field: "aa",
        label: "Anno scolastico",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
      {
        id: "dateAchievement",
        field: "dateAchievement",
        label: "Data conseguimento",
        component: MInput,
        type: "date",
        className: "col-md-6",
      },
    ],
    [
      {
        id: "vote",
        field: "vote",
        label: "Voto",
        component: MInput,
        type: "text",
        className: "col-md-5",
      },
      {
        id: "voteNotNumber",
        field: "voteNotNumber",
        label: "Voto non numerico",
        component: MToggle,
        className: "col-md-4",
      },
    ],
  ];

  //--- ---

  componentDidMount() {
    if (typeof this.props.school !== "undefined") {
      this.setState((state) => {
        state.defaultValues = { ...this.props.school };
        return true;
      });
    }

    if (!isEmptyObject(this.props.schoolData)) {
      this.setState((state) => {
        state.defaultValues.istitution = this.props.schoolData.istitution;
        state.defaultValues.city = this.props.schoolData.city;
        return true;
      });
    }

    //--- load schoolTypeCode and popolate select ---
    qualificationService
      .getSchoolTypeCode()
      .then((result) => {
        const { payload } = result.data;
        const list = payload.schoolTypeCode.map(
          ({ gompid, description, istitute }) => ({
            label: description,
            value: description,
          })
        );
        this.popolateSchoolTypeCode.push(...list);

        this.setState({ forceRender: Math.floor(Math.random() * 100) });
      })
      .catch((errors) => {
        console.log(errors);
        networkErrorHelper.notify(errors);
      });
  } //componentDidMount

  nextSchool = () => {
    const payload = payloadBuilder(this.state);
    this.props.popolateDataFromChildSchoolForm(payload);
  }; //nextSchool

  onCancel = () => {
    this.props.showFormSchool(false);
  }; //onCancel

  saveRemote = () => {
    const payload = payloadBuilder(this.state);

    //--- normalize dataset to update ---
    delete payload.createdAt;
    delete payload.updatedAt;

    if (payload.id > 0) {
      userService
        .updateQualification(payload)
        .then((result) => {
          this.onCancel();
        })
        .catch((errors) => {
          if (networkErrorHelper.is422(errors)) {
            const newState = invalidFieldsMapper(
              errors,
              this.state,
              Object.keys(this.validation)
            );
            this.setState({ ...newState });
          } else {
            networkErrorHelper.notify(errors);
          }
        });
    } else {
      userService
        .insertQualification(payload)
        .then((result) => {
          this.onCancel();
        })
        .catch((errors) => {
          if (networkErrorHelper.is422(errors)) {
            const newState = invalidFieldsMapper(
              errors,
              this.state,
              Object.keys(this.validation)
            );
            this.setState({ ...newState });
          } else {
            networkErrorHelper.notify(errors);
          }
        });
    }
  }; //saveRemote

  //--- ---
  render() {
    const { formActive, defaultValues } = this.state;
    const { formValid, code } = this.renderFields(
      this.FIELDS_GROUP,
      defaultValues,
      formActive,
      true
    );

    return (
      <Card tag="div" className="card-big">
        <CardBody tag="div" className="card-body-inAccordion">
          <Button
            color="primary bg-dark"
            onClick={() => {
              this.nextSchool();
            }}
          >
            Inserisci Istituto
          </Button>

          <form className="user mt-5">{code}</form>

          <div className="m-0 text-right">
            <FormEnabler
              onSubmit={this.onSubmit}
              onCancel={this.onCancel}
              onToggle={this.toggleForm}
              isFormActive={formActive}
              disableSave={!formValid}
            />
          </div>
        </CardBody>
      </Card>
    );
  }
}
