import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { ROUTES } from 'const';
import { learningService } from "libs";

import {
    Accordion,
    AccordionHeader,
    AccordionBody,
    Row, Col,
    Card, Icon
} from 'design-react-kit';

export class CourseSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapseOpen: false,
            sectionInfo: this.props.sectionInfo
        }
    }

    componentDidMount() {
        this.loadParentFirstLink();
    }

    loadParentFirstLink = () => {
        Object.entries(this.state.sectionInfo).forEach(([key, value]) => {
            if (key === 'lesson') {
                this.state.sectionInfo['lesson'].forEach((item, x) => {
                    item.url = ROUTES.LEARNING_LESSON + '/' + item.id + '/' + this.props.rCode;

                    if (item.resource.type === 'marker') {
                        learningService.loadIndexLesson(item.id, 2).then((result) => {
                            return result.data.payload.lessons[0].id;
                        }).then(async (res) => {
                            const idModule = this.state.sectionInfo['id'];
                            item.url = ROUTES.LEARNING_LESSON + '/' + (await Promise.resolve(res)) + '/' + this.props.rCode + '/' + idModule;
                        }).catch(error =>{
                            console.log(error)
                        })
                    }//marker

                    if (item.resource.type === 'quiz') {
                        learningService.quizByIDResource(item.resource.id).then(({ data }) => {
                            return data.payload.id;
                        }).then(async (res) => {
                            item.url = ROUTES.LEARNING_QUIZ_MAIN + '/' + item.resource.id + '/' + (await Promise.resolve(res)) + '/' + this.props.rCode
                        }).catch(error =>{
                            console.log(error)
                        })
                    }//quiz

                    if (item.resource.type === 'finalPaper') {
                        item.url = ROUTES.LEARNING_FINAL_PAPER + '/' + this.props.rCode + '/' + item.resource.content
                    }//finalPaper
                })
            }
        })
    }//loadParentFirstLink

    toggle = () => {
        this.setState({
            collapseOpen: !this.state.collapseOpen
        })
    }

    //--- ---

    render() {

        const { sectionInfo, collapseOpen } = this.state;
        if (!sectionInfo) return false

        return (
            <>
                <Row>
                    <Col>
                        <article className="card-wrapper card-space">
                            <Card noWrapper className="card-bg rounded shadow">

                                <Accordion>
                                    <AccordionHeader active={collapseOpen} onToggle={() => this.toggle()}>
                                        <span className="text-dark">{sectionInfo.title}<br />
                                            {sectionInfo.summary}</span>

                                        {(sectionInfo.maintenance !== '') && (<div className="mt-3">{sectionInfo.maintenance}</div>)}
                                    </AccordionHeader>
                                    {(sectionInfo.maintenance === null || sectionInfo.maintenance === '') && <AccordionBody active={collapseOpen}>
                                        <div className="link-list-wrapper">
                                            <ul className="link-list">
                                                {sectionInfo.lesson.map((item, x) => (
                                                    <li key={x} className="p-0 m-0">
                                                        <NavLink className="right-icon list-item" to={(!item.url) ? (ROUTES.LEARNING_LESSON + '/' + item.id + '/' + this.props.rCode) : item.url}>
                                                            <span><h6>{ReactHtmlParser(item.label)}</h6></span>
                                                            <i className="it-chevron-right right" />
                                                            <Icon
                                                                className="icon-right"
                                                                color="primary"
                                                                icon="it-chevron-right"
                                                                padding={false}
                                                                size=""
                                                                style={{ ariaHidden: true }}
                                                            />

                                                            {(item.resource.summary) && <><br /><span>{item.resource.summary}</span></>}
                                                            <hr />
                                                        </NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </AccordionBody>}
                                </Accordion>
                            </Card>
                        </article>
                    </Col>
                </Row>
            </>
        );
    }
}
