import React, { Component } from "react";

import { BoxSuccess, BoxDanger } from "components/misc";
import ReactHtmlParser from 'react-html-parser';

class QuestionOutcome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            question: this.props.question,
            answers: this.props.answers,
            status: null,
            message: ''
        };
    }


    componentDidMount() {
        this.setOutcome();
    }


    setOutcome = () => {
        //--- ReInit ---
        this.setState({
            status: false,
            message: 'risposta non fornita'
        });
        

        //--- use it to manage message grouping feedback
        let tmpIDQuestion = 0;
        let messageGroup  = '';
        
        this.state.answers.forEach(item => {

            if (item.userAnswer && parseInt(item.grade) > 0) {
                this.setState({
                    status: true,
                    message: 'risposta Corretta'
                });
            }
            if (item.userAnswer && parseInt(item.grade) === 0) {

                //--- manage message grouping feedback ---
                if(tmpIDQuestion !== item.idQuestion){
                    tmpIDQuestion = item.idQuestion;
                    messageGroup = '';
                }
                if(item.feedback !== '' && item.feedback !== '{}' && item.feedback !== null){
                    messageGroup += item.feedback+'</br>';
                }else{
                    messageGroup = 'Risposta errata.';
                }

                this.setState({
                    status: false,
                    message: ReactHtmlParser(messageGroup)
                });
            }

            //--- manage exception where question type is checkbox and answer not all right ---
            if(this.state.question.type === 'check' && this.state.question.maxChoose !== this.state.answers.filter(item => item.userAnswer && parseInt(item.grade) > 0).length){
                this.setState({
                    status: false,
                    message: 'Risposta errata.'
                });
            }
        });
    };//setOutcome


    render() {

        if (!this.state.answers && !this.props.metadata.feedbackAnswer) return false;

        return (
            <div className="m-0 p-0 mt-3">
                {
                    (this.props.metadata.feedbackAnswer && this.state.status !== null) && 
                        (((this.state.status !== null && this.state.status)) ?
                        (<BoxSuccess>{this.state.message}</BoxSuccess>) :
                        (<BoxDanger>{this.state.message}</BoxDanger>))
                }
            </div>
        );
    }
}

export default QuestionOutcome;