import Cookies from "universal-cookie";
import { getNextYearDate, getTodayDate, getTomorrow } from "./daysHelper";

const cookies = new Cookies();
const statParam = "utm_source";

export function statCookie() {
  let cookieValues = [];

  let cookieExpire = getNextYearDate();
  let today = getTodayDate();

  // query string url
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);
  const originParam = urlParams.get(statParam);

  //se il parametro è presente in url
  if (originParam) {
    //recupero se esisteono i cookie già settati

    if (cookies.get(statParam)) {
      //console.log(cookies.get(statParam));
      // mi prendo l'array cookie
      for (let i = 0; i < cookies.get(statParam).length; i++) {
        cookieValues.push(cookies.get(statParam)[i]);
      }

      //verifico se i cookies contengono già l'origine
      if (
        cookies.get(statParam).filter(function (e) {
          return e.value === originParam;
        }).length > 0
      ) {
        return;
      } else {
        // se esiste il name ma non la key aggiungo all'array e setto il nuovo cookie con la nuova chiave
        // in questo modo considero origin diverse
        cookieValues.push({
          name: statParam,
          value: originParam,
          date: today,
          expire: cookieExpire,
          url: window.location.href,
        });
        cookies.set(statParam, cookieValues, {
          path: "/",
          expires: cookieExpire,
        });
      }
    } else {
      // se il cookie non esiste aggiungo all'array e setto il nuovo cookie
      cookieValues.push({
        name: statParam,
        value: originParam,
        date: today,
        expire: cookieExpire,
        url: window.location.href,
      });
      cookies.set(statParam, cookieValues, {
        path: "/",
        expires: cookieExpire,
      });
    }
  }
}

export function getStatCookie() {
  if (cookies.get(statParam)) {
    return cookies.get(statParam);
  }
  return null;
}

export function setAdvModalCookie() {
  let today = getTodayDate();
  let expire = getTomorrow();
  // cookies.set("advModal", true, {expires: expire});
  cookies.set("advModal", true);
}

export function getAdvModalCookie() {
  if (cookies.get("advModal")) {
    return cookies.get("advModal");
  }
  return null;
}

export function setBannerTopCookie() {
  let today = getTodayDate();
  let expire = getTomorrow();
  // cookies.set("bannerTop", true, {expires: expire});
  cookies.set("bannerTop", true);
}

export function getBannerTopCookie() {
  if (cookies.get("bannerTop")) {
    return cookies.get("bannerTop");
  }
  return null;
}
