import { proxyBE, proxyApiMnemo, mnemoService } from './common';

export const pageService = {

    page(slug) {
        return proxyBE.get(`pages/${slug}`);
    },    

    validatereCaptcha(gToken) {
        return proxyBE.get(`pages/newsletter/subscribe/${gToken}`);
    },

    subscribeNewsletterTo(email){
        const payload = mnemoService.buildPayload('subscribe', 'email', email);
        return proxyApiMnemo.post('newsletter',payload);
    },

    saveContactRequest(payload) {
        return proxyBE.post(`pages/form5cfu`, payload);
    }
}