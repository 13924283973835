import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';

import {
    networkErrorHelper,
    adminService,
    isEmptyObject
} from "libs";

import {
    Card,
    Accordion,
    AccordionHeader,
    AccordionBody,
    Button,
    Row, Col
} from 'design-react-kit';

import {
    BoxDanger,
    BoxSuccess,
    ModalHandler,
    MnemoLoading
} from 'components/misc';

import {
    MInput,
    MTextArea,
    MToggle
} from "components/forms";

import { AdminCourseFormSection } from 'components/forms/admin/AdminCourseFormSection';
import { CourseManagerListLesson } from 'views/admin/course/CourseManagerListLesson';

export class CourseManagerListSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            hideSection: false,
            dataSections: null,
            defaultAccordionState: {},
            formSectionMain: {
                showForm: false,
                reload: null
            },
            formSectionNested: {
                showForm: false,
                onClosed: this.onClosedNested,
                reload: this.reload,
                reloadNested: this.reloadNested
            },
            idSection: null,
            rndKey: 0,
            courseCodeSubmit: null,
            rawCoureData: null,
            clonerData: {
                courseIdFrom: '',
                courseCodeTo: '',
                courseAATo: '',
                cloneContent: false
            },
            cloneSuccess: false
        };
    }

    componentDidMount() {
        this.loadSection(this.props.idcourse);
    }

    //--- ---

    loadSection(idcourse) {
        adminService.courseSection(idcourse)
            .then(({ data }) => {
                this.setState({
                    hideSection: true,
                    dataSections: data.payload.section,
                });
            })
            .catch(error => {
                //networkErrorHelper.notify(error);
                console.error(error);
            });
    }//loadSection

    //--- ---

    addNewSection = (idcourse) => {
        this.setState({
            formSectionMain: {
                showForm: !this.state.formSectionMain.showForm,
                idCourse: idcourse,
                onClosed: this.onClosed,
                reload: this.reload
            }
        })
    }//addNewSection

    //--- start clone course ---
    /** */
    cloneCourse = (e, idModule) => {
        const form2Clone = [
            {
                name: "code",
                field: "code",
                label: 'Nuovo Codice Corso:',
                type: "text",
                className: "mt-3 mb-1"
            },
            {
                name: "aa",
                field: "aa",
                label: 'Anno Accademico:',
                type: "text",
                className: "mt-3 mb-1"
            },
            {
                id: "cloneContent",
                name: "cloneContent",
                field: "cloneContent",
                label: "Clona Materiale Didattico:",
                value: false,
                disabled: false,
                className: "mt-4 mb-1"
            }

        ]

        const code = <>
            <p>Questa procedura clona tutti i dati di questo Corso, creando un Nuovo Corso, identificato da un nuovo codice corso.
                Se il Corso di destinazione già esiste devi prima eliminarlo.
                Vuoi procedere?</p>
            <Row className="row col-md-12 mt-3">
                <Col><MToggle {...form2Clone[2]} onChange={this.clonerData} /></Col>
            </Row>
            <Row className="row col-md-12 mt-3">
                <Col><MInput {...form2Clone[0]} onChange={this.clonerData} /></Col>
                <Col><MInput {...form2Clone[1]} onChange={this.clonerData} /></Col>
            </Row>
            {/*<div className="small text-info">Codice corso di destinazione.</div>*/}
        </>;
        ModalHandler.show({ idModule: idModule }, 'Clona Corso', code, this.modalAbort, this.modalConfirmed);
    }//cloneCourse

    modalConfirmed = () => {
        const validation = {
            courseIdFrom: value => /^\d*$/.test(value) && value > 0,
            courseCodeTo: value => value.length > 2,
            courseAATo: value => value.length === 4,
            cloneContent: value => typeof value === "boolean",
        };

        //console.log (this.state.clonerData);

        for (let [key, value] of Object.entries(this.state.clonerData)) {            
            if (!validation[key](value) && typeof value !== "boolean") {              
                ModalHandler.show(null, 'Clona Corso', 'Operazione annullata. Parametro '+ key + ' non valido', null, null );
                console.log(key, value);
                return false;
            }
        }

        this.setState({ loading: true });

        adminService.cloneFullCourse(this.state.clonerData).then((result) => {
            if (result.data.payload.result.body) {
                if (parseInt(result.data.payload.result.body.id) > 0) {
                    this.setState({
                        loading: false,
                        cloneSuccess: true
                    })

                    setTimeout(() => {
                        this.setState({ cloneSuccess: false })
                    }, 6000);
                }
            }
        }).catch(errors => {
            networkErrorHelper.notify(errors);
            this.setState({ loading: false });
        });
    }//modalConfirmed


    modalAbort = () => {
        this.setState({
            clonerData: {
                courseIdFrom: '',
                courseCodeTo: '',
                courseAATo: '',
                cloneContent: false
            }
        })
    }//modalAbort

    clonerData = (_, value) => {
        if ((_ === 'code')) {
            this.setState({
                clonerData: {
                    ...this.state.clonerData,
                    courseIdFrom: this.props.idcourse,
                    courseCodeTo: value
                }
            })
        }

        if ((_ === 'aa')) {
            this.setState({
                clonerData: {
                    ...this.state.clonerData,
                    courseIdFrom: this.props.idcourse,
                    courseAATo: value
                }
            })
        }

        if ((_ === 'cloneContent')) {
            this.setState({
                clonerData: {

                    ...this.state.clonerData,
                    courseIdFrom: this.props.idcourse,
                    cloneContent: value
                }
            })
        }
    }//cloneForm
    //--- end clone course ---

    /** */
    importFromMoodle = () => {
        //courseCodeSubmit
        const courseCodeAttr = {
            label: 'digita codice Corso',
            className: "mt-1 mb-3",
        }
        const code = <>
            <MInput {...courseCodeAttr} onChange={this.courseCodeSubmit} />
            <div className="small text-info text-justify"><b>ATTENZIONE:</b> importando un Corso saranno eliminate definitivamente tutte le attuali informazioni.
                Vuoi davvero importare un corso e sovrascrivere le informazioni correnti?</div>
        </>;

        ModalHandler.show(null, 'Importazione Corso', code, null, this.importConfirmed);
    }//importFromMoodle

    /** */
    courseCodeSubmit = (_, value) => {
        this.setState({
            courseCodeSubmit: value
        })
    }//courseCodeSubmit

    /** */
    importConfirmed = () => {
        this.setState({ loading: true })

        adminService.loadCourseFromMoodle(this.state.courseCodeSubmit).then(({ data }) => {
            this.setState({
                rawCoureData: data.payload,
                loading: false
            })
        })
            .catch(error => {
                console.error(error);
                if (networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
                this.setState({
                    loading: false
                })
            });
    }

    //--- ---

    accordionToggle = (idRow, idSection, idCourse) => {

        this.setState(prevState => {
            let defaultAccordionState = { ...prevState.defaultAccordionState }; //creating copy of state variable
            defaultAccordionState[`collapse${idSection}`] = !this.state.defaultAccordionState[`collapse${idSection}`]; //update the name property, assign a new value
            return { defaultAccordionState }; //return new full object
        })

        this.setState({
            formSectionNested: {
                showForm: !this.state.formSectionNested.showForm,
                idCourse: idCourse,
                idSection: idSection,
                onClosed: this.onClosedNested,
                reload: this.reload,
                reloadNested: this.reloadNested
            },
            idSection: idSection,
            rndKey: Math.floor((Math.random() * 1000) + 1)
        })
    }//accordionToggle

    //--- use to comunicate from/to child component ---
    onClosed = () => {
        this.setState({
            formSectionMain: {
                showForm: !this.state.formSectionMain.showForm
            }
        })
    }

    onClosedNested = () => {
        this.setState({
            formSectionNested: {
                showForm: !this.state.formSectionNested.showForm
            }
        })
    }

    //--- use to reload section list from child component ---
    reload = (idcourse) => {
        this.onClosed();
        this.loadSection(idcourse);
    }
    reloadNested = (idcourse) => {
        this.onClosedNested();
        this.loadSection(idcourse);
    }


    //--- start functions to delete procedure ---
    deleteRecord(e, value) {
        e.stopPropagation();
        ModalHandler.show(value, 'Eliminazione del dato', 'vuoi davvero eliminare?', null, this.deleteConfirmed);
    }

    deleteConfirmed = (value) => {
        let payload = {
            id: value
        };

        adminService.deleteSection(payload.id).then(({ data }) => {
            this.loadSection(this.props.idcourse);
        })
            .catch(error => {
                console.error(error);
                if (networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });
    }
    //--- end functions to delete procedure ---

    cancelImport = () => {
        this.setState({
            rawCoureData: null
        })
    }//cancelImport

    confirmImport = () => {
        this.setState({ loading: true });

        setTimeout(() => {
            const payload = this.state.rawCoureData;
            payload.idCourse = this.props.idcourse;

            adminService.importCourse(payload)
                .then(({ data }) => {
                    if (data.payload.result) {
                        this.cancelImport();
                        this.loadSection(data.payload.idCourse)
                        this.setState({ loading: false });
                    }
                })
                .catch(error => {
                    this.setState({ loading: false });
                    networkErrorHelper.notify(error);
                    console.error(error);
                });
        }, 250);
    }//confirmImport

    renderNoSection() {
        return (
            <>
                {this.state.loading && (<MnemoLoading></MnemoLoading>)}

                {(this.state.formSectionMain.showForm) ?
                    (
                        <>
                            <AdminCourseFormSection
                                key="formSection-0"
                                formSectionData={this.state.formSectionMain}
                                idSection={parseInt(this.state.idSection)}
                                formActive={true}
                                forceShow={true}
                                onReload={this.reload}
                            />
                        </>
                    ) : (
                        <>
                            <div>
                                <Button className="my-4" color="primary" outline
                                    onClick={() => this.addNewSection(this.props.idcourse)}>
                                    Aggiungi sezione
                                </Button>

                                {/*<Button className="my-4 mx-1" color="primary bg-dark" tag="button"
                                    onClick={() => this.importFromMoodle()}>
                                    Importa da Moodle
                                </Button>*/}
                            </div>

                            {(this.state.rawCoureData) && (<div className="mb-4 codePretty">
                                <MTextArea id="rawCoureData" value={JSON.stringify(this.state.rawCoureData, undefined, 4)} rows='24' />
                                <div className="mb-3 text-right">
                                    <Button className="btn mt-2" color="btn btn-primary bg-dark" onClick={() => { this.confirmImport() }}>CONFERMA</Button>
                                    <Button className="mt-2 mx-1" color="link" outline onClick={() => this.cancelImport()}>Annulla</Button>
                                </div>
                            </div>)}

                            <BoxDanger>Non ci sono ancora sezioni in questo corso.</BoxDanger>
                        </>
                    )
                }
            </>
        )
    }

    renderSections() {

        let myData = Object.keys(this.state.dataSections).map(key => {
            return this.state.dataSections[key];
        }).filter(item => (item.title !== null && item.title !== ''));

        return (

            <>
                {this.state.loading && (<MnemoLoading></MnemoLoading>)}

                {(this.state.formSectionMain.showForm) ?
                    (
                        <>
                            <AdminCourseFormSection
                                key="formSection-1"
                                formSectionData={this.state.formSectionMain}
                                idSection={parseInt(this.state.idSection)}
                                formActive={true}
                                forceShow={true}
                                onReload={this.reload}
                                forceReset={true}
                            />
                        </>
                    ) :
                    (
                        <>
                            <div>
                                <Button className="my-4 mx-1" color="primary" outline tag="button"
                                    onClick={() => this.addNewSection(this.props.idcourse)}>
                                    Nuova sezione Corso
                                </Button>

                                {/*<Button className="my-4 mx-1" color="primary bg-dark" tag="button"
                                    onClick={() => this.importFromMoodle()}>
                                    Importa da Moodle
                                </Button>*/}

                                {(myData.length > 0) && (<Button className="my-4 mx-1" color="primary bg-dark" tag="button"
                                    onClick={(e) => this.cloneCourse(e, this.props.idcourse)}>
                                    Clona Corso
                                </Button>)}

                                {(this.state.cloneSuccess) && <BoxSuccess>Il corso è stato Clonato Correttamente.</BoxSuccess>}

                                {(this.state.rawCoureData) && (<div className="mb-4 codePretty">
                                    <MTextArea id="rawCoureData" label="Anteprima Corso" value={JSON.stringify(this.state.rawCoureData, undefined, 4)} rows={12} />
                                    <div className="mb-3 text-right">
                                        <Button className="btn mt-2" color="btn btn-primary bg-dark" onClick={() => { this.confirmImport() }}>CONFERMA</Button>
                                        <Button className="mt-2 mx-1" color="link" onClick={() => this.cancelImport()}>Annulla</Button>
                                    </div>
                                </div>)}
                            </div>

                            <Accordion key={`row-${this.state.dataSections.length}`}>
                                {

                                    myData.map((item, x) => (
                                        <div key={`div-${x}`}>
                                            <AccordionHeader
                                                key={`acc-h-${x}`}
                                                className="collapse-header-inAccordion"
                                                active={this.state.defaultAccordionState[`collapse${x}`]}
                                                onToggle={() => this.accordionToggle(x, item.id, item.idParent)}
                                            >
                                                {ReactHtmlParser(item.title)}
                                            </AccordionHeader>

                                            <AccordionBody
                                                key={`ab-${this.state.rndKey}`}
                                                active={(parseInt(this.props.idsection) === item.id) ? !this.state.defaultAccordionState[`collapse${item.id}`] : this.state.defaultAccordionState[`collapse${item.id}`]}
                                            >
                                                <Row>
                                                    <Col sm={12} lg={7}>
                                                        <AdminCourseFormSection
                                                            key={`acfs-${this.state.rndKey}`}
                                                            formSectionData={this.state.formSectionNested}
                                                            idSection={item.id}
                                                            forceShow={true}
                                                            formActive={false}
                                                            accordionToggle={() => this.accordionToggle(x, item.id, item.idParent)}
                                                            deleteRecord={(e) => this.deleteRecord(e, item.id)}
                                                        />
                                                    </Col>
                                                    <Col sm={12} lg={5} className="header-lessons-card-wrapper">
                                                        <CourseManagerListLesson key={`cmll-${this.state.rndKey}`} idSection={item.id} idCourse={this.props.idcourse} currentLevel={item.level} />
                                                    </Col>
                                                </Row>
                                            </AccordionBody>
                                        </div>
                                    ))
                                }
                            </Accordion>
                        </>
                    )}
            </>)
    }

    render() {

        /*if (parseInt(this.props.idcourse) <= 0 || this.state.loading) {
            return (<></>);
        }*/

        let data = '<div></div>';

        if (this.state.hideSection && !isEmptyObject(this.state.dataSections)) {
            data = this.renderSections();
        } else {
            data = this.renderNoSection();
        }

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card mt-4 p-4">
                    {data}
                </Card>
            </>
        )
    }
}