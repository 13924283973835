import React, { Component } from 'react';
import {
    Accordion, AccordionHeader,
    AccordionBody
} from 'design-react-kit';
import { MSection, Title } from 'components/misc';

export class ContactBox extends Component {

    defaultState = {
        collapseOpen1: false,
        collapseOpen2: false,
        collapseOpen3: false,
    }

    state = {
        ...this.defaultState,
    }

    componentDidMount() {

    }

    toggle = id => {
        this.setState({
            ...this.defaultState,
            [`collapseOpen${id}`]: !this.state[`collapseOpen${id}`]
        })
    }

    render() {

        const { collapseOpen1, collapseOpen2, collapseOpen3 } = this.state;
        const aaURL = process.env.REACT_APP_AA_YEAR.slice(-2);
        const adminUrl = 'https://mne' + aaURL + '.mnemosine-elearning.it/segreteria/frontEndContactRequest/';

        return (<>
            <MSection className="my-4">
                <Title>CONTATTACI PER INFORMAZIONI</Title>
                <Accordion>
                    <AccordionHeader active={collapseOpen1} onToggle={() => this.toggle(1)}>
                        <span className="text-green" >Email</span>
                    </AccordionHeader>
                    <AccordionBody active={collapseOpen1}>
                        <p>Invia un email a <a href="mailto:info@formazionedocenti.it">info@formazionedocenti.it</a></p>
                        <p>ATTENZIONE : il Sistema non consente di rispondere alle email provenienti da indirizzi  …@PEC.it e …@postecert.it
                        </p>
                    </AccordionBody>

                    <AccordionHeader active={collapseOpen2} onToggle={() => this.toggle(2)}>
                        <span className="text-green" >Contatti telefonici</span>
                    </AccordionHeader>
                    <AccordionBody active={collapseOpen2}>
                        <p>Recapiti telefonici dei nostri uffici:</p>
                        <ul>
                            <li><a href="tel:0234593068">02 34593068</a></li>
                            <li><a href="tel:0670495308">06 70495308</a></li>
                            <li><a href="tel:055485899">055 485899</a></li>
                            <li><a href="tel:0516335062">051 6335062</a></li>
                            <li><a href="tel:049680148">049 680148</a></li>
                            <li><a href="tel:092533231">0925 33231</a></li>
                            <li><a href="tel:092531499">0925 31499</a></li>
                            <li><a href="tel:3296553069">329 6553069</a></li>
                        </ul>
                        <p>Recapiti telefonici del supporto tecnico:</p>
                        <ul>
                            <li><a href="tel:092532779">0925 32779</a></li>
                            <li><a href="tel:3664140604">366 4140 604</a></li>
                        </ul>
                    </AccordionBody>

                    <AccordionHeader active={collapseOpen3} onToggle={() => this.toggle(3)}>
                        <span className="text-green" >Richiesta di contatto</span>
                    </AccordionHeader>
                    <AccordionBody active={collapseOpen3}>
                        <p>Inserisci i tuoi dati e il momento in cui vuoi essere contattato. Sarai richiamato da un nostro operatore nella fascia oraria da te indicata.</p>
                        <div>
                            <iframe title="richiesta di contatto" className="embed-responsive-item" src={adminUrl} width="100%" height="500" frameBorder="0" scrolling="no"></iframe>
                        </div>
                    </AccordionBody>
                </Accordion>
            </MSection>
        </>)
    }
}