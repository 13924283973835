import React, { Component } from "react";

import {
  setBannerTopCookie,
  getBannerTopCookie,
} from "libs/helpers/cookieHelper";

import { Icon } from "design-react-kit";

export class BannerTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    this.setState({ isOpen: getBannerTopCookie() });
  }

  toggleBanner() {
    this.setState({ isOpen: true });
    setBannerTopCookie();
  }

  render() {
    const open = this.state.isOpen;
    if (!open) {
      return (
        <div className="header__bannertop">
          <div className="header__bannertop__wrapper">
            <div className="header__bannertop__content">
              <b>
                Black Friday 100€ di sconto utilizzando il Codice Riservato
                BLACKFRIDAY22
              </b>
              <div
                className="header__bannertop__close"
                onClick={() => this.toggleBanner()}
              >
                <Icon className="align-bottom" icon="it-close" size="sm" />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
