import React, { Component } from 'react';
import { formHelper } from 'libs/helpers';
import './MSelect.css';

export class MSelect extends Component {

    /**
     * PROPS: 
     * id       : identifier 
     * label    : example text (brief description of fields)     
     * infoText : addtional text used when the input in invalid
     * disabled : insert for readonly 
     * readOnly : insert for readonly 
     * valid    : set  when the input is valid
     * invalid  : set  when the input is invalid
     * payload  : json of list of options (label and value)
     */
    render() {

        const {
            id, name,  label,  onChange, invalid, valid=null, infoText,
            value = null, defaultValue = undefined,
            payload = {}, disabled = false, readOnly = false
        } = this.props;

        const { options = [] } = payload;       
        const onChangeFunction = formHelper.isFunction(onChange) ? onChange : formHelper.emptyFunction;
     
        let className = (valid) ? 'is-valid': '';
        className += (invalid) ? 'is-invalid': '';
        const withName = this.props.children ? 'w-85' : '';

        return (
            <div className="form-group">
                <div className="bootstrap-select-wrapper">
                    <label className="active" htmlFor={id} >{label}</label>
                    {this.props.children}
                    <div className={withName}>                   
                        <select
                            title="Scegli una opzione"     
                            className={className}
                            id={id}
                            name={name}
                            tabIndex="-98"
                            disabled={disabled}
                            readOnly={readOnly}                           
                            onChange={event => { onChangeFunction(name, event.target.value) }}
                            value={value || defaultValue}
                        >
                            {options.map(({ value, label }, i) => <option key={i} value={value}>{label}</option>)}
                        </select>                                                
                        {invalid && (<small className="form-text text-danger">{infoText}</small>)}
                    </div>                  
                </div>
            </div>
        );
    }
}