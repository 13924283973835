import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import { learningService, networkErrorHelper, cache } from "libs";
import { ROUTES } from 'const';

import { QuizSection } from 'components/quiz/QuizSection';
import ReactHtmlParser from 'react-html-parser';

import dayjs from 'dayjs';
import { MnemoLoading, BoxDanger, Countdown, ModalHandler, Title } from "components/misc";
import { Button, Row, Col, Icon } from "design-react-kit";

class QuizMain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            quiz: null,
            idResource: this.props.match.params.id,
            idQuiz: this.props.match.params.idqa,
            message: null,
            loseFocus: false,
            isLoading: false,
            countdown: {
                params: {
                    onEnd: this.onEndCountdown,
                    duration: 0
                }
            }
        };
    }

    componentDidMount() {

        document.oncontextmenu = document.body.oncontextmenu = function() {return false;}

        //--- check if need to resume an onair attempt ---
        let idQuizLocal = this.state.idQuiz;
        if (cache.get(`quiz-${this.state.idQuiz}`) !== null) {
            idQuizLocal = cache.get(`quiz-${this.state.idQuiz}`).idq;
        }

        learningService.getQuiz(this.state.idResource, idQuizLocal).then(function (result) {

            if (!result.data.payload.quiz.message) {
                if (result.data.payload.quiz.loseFocus) {
                    ModalHandler.show((result.data.payload), 'Importante.', `Questa prova prevede che non venga lasciata la pagina
                     di esecuzione della prova. Lasciando la pagina automaticamente la prova sarà conclusa nello stato in cui si
                      trova in quel momento. Se hai compreso "Conferma".`, null, acceptRuleLoseFocus, 'Conferma', '', true, false);
                      //rejectRuleLoseFocus
                } else {
                    resQuiz(result.data.payload);
                }
            }
        })
            .catch(error => {
                if (networkErrorHelper.is422(error)) {
                    this.setState({
                        quiz: { 'quiz': { 'message': error.response.data.errors } }
                    });
                    this.deleteLocalStorage(localStorage, this.state.idQuiz);
                }
            });

        /** */
        const acceptRuleLoseFocus = (payload) => {
            window.addEventListener('blur', doBlur);
            resQuiz(payload);
        }//acceptRuleLoseFocus

        /** */
        const doBlur = () => {
            this.sendQuiz();
        }//doBlur

        /** 
        const rejectRuleLoseFocus = (payload) => {
            learningService.deleteAttemp(payload.quiz).then((result) => {
                window.location.href = ROUTES.LEARNING_MAIN + '/' + this.props.match.params.rCode;
            })
        }//rejectRuleLoseFocus
*/
        /** */
        const resQuiz = (result) => {
            /** save to local memory quiz data */
            cache.set(`quiz-${result.quiz.idQuiz}`, {
                onair: true,
                idq: result.quiz.idQuiz,
                idqa: result.quiz.id
            });

            //--- calculate valid duration about dateStart Quiz Attempt ---
            const expiredDate = dayjs(dayjs(result.quiz.dateStart).add((result.quiz.duration * 60), 'second'));
            const now = dayjs();
            const validDuration = expiredDate.diff(now, "minutes", true);            

            this.setState({
                quiz: Object.assign({}, result),
                countdown: {
                    params: {
                        onEnd: this.onEndCountdown,
                        duration: validDuration
                    }
                }
            });
        }//resQuiz
    }//componentDidMount


    /** */
    onEndCountdown = () => {
        this.sendQuiz();
    }//onEndCountdown

    /** */
    openFeedbackFinal = (idQuizAttempt) => {
        /** rediret to lesson or course list page */
        window.location.href = ROUTES.LEARNING_QUIZ_FEEDBACK + '/' + idQuizAttempt + '/' + this.props.match.params.rCode;
    }//openFeedbackFinal

    /** */
    deleteLocalStorage = (localStorage, idQuiz) => {
        //--- remove all localStorage data quiz ---
        Object.entries(localStorage).map(
            item => item[0]
        ).filter(
            item => item.substring(0, ("quiz-" + idQuiz).length) === "quiz-" + idQuiz
        ).map(
            item => localStorage.removeItem(item)
        )
    }//deleteLocalStorage

    /** */
    confirmSendQuiz = () =>{       
        ModalHandler.show(null, 'Conferma Prova', `Stai per confermare la prova. Se confermi la prova verrà 
        memorizzata e non potrai più modificare le risposte.`, null, this.sendQuiz);
    }//confirmSendQuiz

    /** */
    deleteCurrenteAttempt = () =>{        
        ModalHandler.show(null, 'Importante.', `Il tentativo corrente sta per essere annullato, 
        è possibile ripetere la prova successivamente.`, null, this.confirmDeleteCurrenteAttempt);
    }//deleteCurrenteAttempt

    confirmDeleteCurrenteAttempt = () =>{
        const idQuiz    = this.state.quiz.quiz.idQuiz;
        const questions = this.state.quiz.quiz.questions;

        cache.forget(`quiz-${idQuiz}`);
        questions.forEach((question)=>{
            cache.forget(`quiz-${idQuiz}-${question.id}`);
        })

        window.location.href = ROUTES.LEARNING_MAIN + '/' + this.props.match.params.rCode;
    }//confirmDeleteCurrenteAttempt


    /** */
    sendQuiz = () => {
        
        //--- get all quiz element state from localStorage and send to BE ---
        let lockSendQuiz = false;
        this.setState({
            isLoading: true,
            message: null
        });

        const idQuiz  = this.state.quiz.quiz.idQuiz;
        let questions = this.state.quiz.quiz.questions;

        if (cache.get(`quiz-${idQuiz}`) !== null) {

            //--- check if exists valid answears and check if mandatory ---
            if (this.state.quiz.quiz.mandatory) {
                let cntAnsw = 0;
                questions.forEach(row => {
                    if (cache.get(`quiz-${idQuiz}-${row.id}`)) {
                        cntAnsw++;
                    }
                })

                //---

                if(cntAnsw < questions.length) {
                    this.setState({
                        isLoading: false,
                        message: 'Devi rispondere a tutte le domande della Prova in Corso.'
                    })                
                    //window.scrollTo(0,0); //force scroll to top to show error message
                    setTimeout(() => {
                        this.setState({
                            message: null
                        })
                    }, 6000);

                    lockSendQuiz = true;
                }
            }//check if mandatory

            //--- ---

            questions.forEach(row => {
                let lS = cache.get(`quiz-${idQuiz}-${row.id}`);

                if (lS !== null && lS.length > 0) {

                    //--- manage error on type text ---
                    if((row.type).toLowerCase() === 'text' && lS[0].label === ''){
                        this.setState({
                            isLoading: false,
                            message: `Attenzione. La Domanda ${row.order} prevede una risposta aperta.`
                        })

                        lockSendQuiz = true;
                    }
                    
                    //--- manage error on type !text ---
                    if(lS.length !== row.maxChoose && (row.type).toLowerCase() !== 'text'){
                        this.setState({
                            isLoading: false,
                            message: `Attenzione. La Domanda ${row.order} prevede ${row.maxChoose} risposte.`
                        })
    
                        lockSendQuiz = true;
                    }

                    if(!lockSendQuiz){
                        row.answers.forEach(item => {
                            /*
                                iterate on real answers from localStorage.
                                add marker [true] property to main quiz->questions->answers
                                if idAnswer is in localStorage value
                            */
                            lS.forEach(lSItem => {
                                if (lSItem.idAnswer === item.id) {
                                    item.marker = true;
                                }
                                if (lSItem.label !== '' && typeof lSItem.idAnswer === 'undefined') {
                                    item.marker = true;
                                    item.label = lSItem.label;
                                }
                            })
                        })
                    }else{
                        setTimeout(() => {
                            this.setState({
                                message: null
                            })
                        }, 6000);
                    }
                }//localStorage answer
            });


            if(!lockSendQuiz){
                //--- send quiz data to backend ---
                learningService.sendQuiz(this.state.quiz).then((result) => {
                    //console.log(result.data.payload)
                    let idQuiz = result.data.payload.result.idQuiz;
                    let idQuizAttempt = result.data.payload.result.id;
                    //let idLesson = result.data.payload.result.idLesson;

                    this.deleteLocalStorage(localStorage, idQuiz);
                    this.openFeedbackFinal(idQuizAttempt);

                    this.setState({
                        isLoading: false
                    })

                    return result;
                })
            }
        } else {
            //TODO: mostare un errore quando non viene recuperato un quiz dal localstorage
        }
    }//sendQuiz


    //--- ---
    

    render() {

        if (!this.state.quiz) return false;
        const { questions, message, duration, title } = this.state.quiz.quiz;

        return (
            <>
                {(this.state.isLoading) && <MnemoLoading />}

                <div className="container mt-4 mb-4 noSelect">
                    <Title>{title}</Title>

                    {(this.state.message) && <BoxDanger><h6>{ReactHtmlParser(this.state.message)}</h6></BoxDanger>}
                    {(message) && <BoxDanger><h6>{ReactHtmlParser(message)}</h6></BoxDanger>}

                    {(!message) &&
                        <section id="fullQuiz">
                            <div className="bg-light py-5">
                                <div className="px-4 container">

                                    {(duration > 0) && (
                                        <div className="card" style={{ "position": "sticky", "top": "0", "zIndex": "100" }}>
                                            <div className="row p-3 pb-1 pt-0">
                                                <div className="col">Prova a Tempo. Allo scadere del tempo disponibile la prova sarà terminata automaticamente allo stato di esecuzione</div>
                                                <div className="col text-right">
                                                    <Countdown params={this.state.countdown.params} />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <Row>
                                        <Col>
                                            {questions.map((row, i) => (
                                                <QuizSection key={`quizItem-${i}`} content={row} idQuiz={this.state.idQuiz} posOrder={i}></QuizSection>
                                            ))}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            {
                                                (this.state.message) ? 
                                                <Button color="primary bg-dark" className="m-2" disabled>Correzione Corso <Icon icon="it-clock" color="white"/></Button> :
                                                <Button color="primary bg-dark" className="m-2" onClick={this.confirmSendQuiz}>Conferma Prova</Button>
                                            }
                                            {(this.state.quiz.quiz.attempts === 0) && <Button color="primary bg-dark" className="m-2" onClick={this.deleteCurrenteAttempt}>Annulla Prova</Button>}
                                        </Col>
                                    </Row>

                                    {(this.state.message) && <BoxDanger className="mt-3"><h6>{this.state.message}</h6></BoxDanger>}
                                    {(message) && <BoxDanger><h6>{message}</h6></BoxDanger>}

                                </div>
                            </div>
                        </section>
                    }

                </div>
            </>
        );
    }
}

export default withRouter(QuizMain);