import React, { Component } from "react";
import { cache } from '../../libs';

import {Icon} from 'design-react-kit';

import ReactHtmlParser from 'react-html-parser';

class QuestionRadio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            answers: this.props.answers,
            choice: [],
            idQuiz: this.props.idQuiz
        };
    }


    /** */
    //--- check if exists local value ---
    isChecked = (idQuiz, idQuestion, idAnswer, label) =>{
        let getValue = cache.get(`quiz-${idQuiz}-${idQuestion}`);
        let result   = false;
        
        if(getValue !== null){            
            let idx = getValue.findIndex(item => (item.idAnswer === idAnswer && item.idQuestion === idQuestion));            
            if (idx>-1){
                setTimeout(() => {
                    this.setMyState(idAnswer, idQuestion, label)                    
                }, 100);                
                result = true;
            }
        }

        return result;
    }//isChecked


    setMyState = (idAnswer, idQuestion, label) => {
        let data = {
            idAnswer: idAnswer,
            idQuestion: idQuestion,
            label: label
        }

        this.setState(state => {    
            state.choice.pop(); 
            state.choice.push(data);
        
            //--- manage localStorage answer ---
            if(this.state.choice.length===0){
                cache.forget(`quiz-${this.state.idQuiz}-${idQuestion}`,this.state.choice);
            }else{
                cache.set(`quiz-${this.state.idQuiz}-${idQuestion}`,this.state.choice);
            }
        });
    }//setMyState

    render() {

        if(!this.state.answers) return false;

        return (
            <div>         
                {this.state.answers.map((row, i) => (
                    <div key={`r${i+1}`} className="form-check">

                        {(typeof row.userAnswer === 'undefined') ? (<input 
                            id={`r${i+1}_${this.props.idQuestion}`}
                            name={`r${this.props.idQuestion}`}
                            type="radio"
                            defaultChecked={this.isChecked(this.state.idQuiz, this.props.idQuestion, row.id, row.label)}
                            onClick={(e) => { this.setMyState(row.id, this.props.idQuestion, row.label); }}
                        />) : (<input 
                                id={`r${i+1}_${this.props.idQuestion}`}
                                name={`r${this.props.idQuestion}`}
                                type="radio"
                                disabled={true}
                                defaultChecked={row.userAnswer}
                            />)
                        }                     
                        <label htmlFor={`r${i+1}_${this.props.idQuestion}`}>
                            {
                                (typeof row.userAnswer !== 'undefined') && 
                                ((parseInt(row.grade)>0) ?
                                    (<Icon className="m-0 p-0 mr-1 mb-1" color="success" icon="it-check-circle" />) :
                                    (<Icon className="m-0 p-0 mr-1 mb-1" color="danger" icon="it-error" />))
                            }

                            {ReactHtmlParser(row.label)}
                        </label>
                    </div>
                ))}
            </div>
        );
    }
}

export default QuestionRadio;