import React, {Component} from "react";
import { setAdvModalCookie, getAdvModalCookie } from "libs/helpers/cookieHelper";

import {
    Icon
  } from 'design-react-kit';
  
  import "./advModal.scss";

  export class AdvModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    componentDidMount() {
        this.setState({isOpen: getAdvModalCookie()})
    }

    toggleModal() {
        this.setState({isOpen: true})
        setAdvModalCookie();
    }

    render() {
        const modalVisible = true;
        const open = this.state.isOpen;
        if(!open && modalVisible) {
            return(
                <div className="advModal">
                    <div className="advModal__overlay"  onClick={() => this.toggleModal()}></div>
                    <div className="advModal__wrapper">
                        <div className="advModal__content">
                            <div className="advModal__close" onClick={() => this.toggleModal()}> <Icon className='align-bottom' icon='it-close-circle' color="white" /></div>
                            <img
                                src="/img/blackfriday/blackfriday.jpeg"
                                alt="logo mnemosine"
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return null;

        
    }
}