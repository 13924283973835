import React, { Component } from 'react';
import { blogService, dateHelper } from 'libs';
import { ROUTES } from 'const';

import './BlogHomeBox.scss';

import { CardNews } from './CardNews';

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper/core";

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import "swiper/components/pagination/pagination.min.css";
SwiperCore.use([Pagination]);

export class BlogHomeBox extends Component {

    state = {
        dimPage: 4,
        articles: [],
        loading: true,
        loadingError: false
    }

    componentDidMount() {
        this.loadArticles();
    }

    loadArticles() {

        blogService.lastArticles(1, this.state.dimPage)
            .then(({ data }) => {

                const { articles } = data.payload;
                this.setState({
                    articles,
                    loading: false,
                    loadingError: false
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false, loadingError: true });
            });
    }

    render() {

        const { loading, loadingError, articles } = this.state;

        if (loading || loadingError || articles.length === 0) return (<></>);

        return (<>

            <section className="blogHomeBox">
                <div className="blogHomeBox__wrapper">
                    <div className="blogHomeBox__title">
                        <h2 className="title">Mnemosine News</h2>
                    </div>
                    <Swiper
                        spaceBetween={20} 
                        breakpoints={{
                            0: {
                            slidesPerView: 1.2
                            },
                            768: {
                            slidesPerView: 2.5
                            },
                            1024: {
                                slidesPerView: 4,
                            }
                        }}
                    >
                        {articles.map((article, i) => {
                            return(
                                <SwiperSlide key={`article-${i}`}>
                                    <CardNews 
                                        image={article.image ? article.image : '/img/blog/corsimnemosine-notizie.jpg'}
                                        date={dateHelper.toITDate(article.date)}
                                        text={article.title}
                                        link={`${ROUTES.BLOG_ARTICLE}/${article.slug}`}
                                    />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </section>
        </>)
    }
}