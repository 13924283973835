import React, { Component } from "react";
import axios from "axios";
import { MSection, Title } from "components/misc";
import { Row, Col, Button } from "design-react-kit";
import { Helmet } from "react-helmet";
import { pageService, payloadBuilder, utilsService } from "libs";

import {
  FormEnabler,
  ValidatedForm,
  MInput,
  MSelect,
  MCheckbox,
} from "components/forms";

export class Form5CfuView extends ValidatedForm {
  constructor(props) {
    super(props);
    this.state = {
      ...this.emptyFields,
      defaultValues: { ...this.emptyFields },
      formActive: true,
      regions: [],
      isChecked: false,
      isSuccess: 0,
    };
  }

  PAYLOAD_COMPETITION_CLASS = {
    options: [
      { label: "Seleziona classe di concorso", value: "" },
      { label: "A-01", value: "A-01" },
      { label: "A-02", value: "A-02" },
      { label: "A-03", value: "A-03" },
      { label: "A-04", value: "A-04" },
      { label: "A-05", value: "A-05" },
      { label: "A-06", value: "A-06" },
      { label: "A-07", value: "A-07" },
      { label: "A-08", value: "A-08" },
      { label: "A-09", value: "A-09" },
      { label: "A-10", value: "A-10" },
      { label: "A-11", value: "A-11" },
      { label: "A-12", value: "A-12" },
      { label: "A-13", value: "A-13" },
      { label: "A-14", value: "A-14" },
      { label: "A-15", value: "A-15" },
      { label: "A-16", value: "A-16" },
      { label: "A-17", value: "A-17" },
      { label: "A-18", value: "A-18" },
      { label: "A-19", value: "A-19" },
      { label: "A-20", value: "A-20" },
      { label: "A-21", value: "A-21" },
      { label: "A-22", value: "A-22" },
      { label: "A-23", value: "A-23" },
      { label: "A-24", value: "A-24" },
      { label: "A-25", value: "A-25" },
      { label: "A-26", value: "A-26" },
      { label: "A-27", value: "A-27" },
      { label: "A-28", value: "A-28" },
      { label: "A-29", value: "A-29" },
      { label: "A-30", value: "A-30" },
      { label: "A-31", value: "A-31" },
      { label: "A-32", value: "A-32" },
      { label: "A-33", value: "A-33" },
      { label: "A-34", value: "A-34" },
      { label: "A-35", value: "A-35" },
      { label: "A-36", value: "A-36" },
      { label: "A-37", value: "A-37" },
      { label: "A-38", value: "A-38" },
      { label: "A-39", value: "A-39" },
      { label: "A-40", value: "A-40" },
      { label: "A-41", value: "A-41" },
      { label: "A-42", value: "A-42" },
      { label: "A-43", value: "A-43" },
      { label: "A-44", value: "A-44" },
      { label: "A-45", value: "A-45" },
      { label: "A-46", value: "A-46" },
      { label: "A-47", value: "A-47" },
      { label: "A-48", value: "A-48" },
      { label: "A-49", value: "A-49" },
      { label: "A-50", value: "A-50" },
      { label: "A-51", value: "A-51" },
      { label: "A-52", value: "A-52" },
      { label: "A-53", value: "A-53" },
      { label: "A-54", value: "A-54" },
      { label: "A-55", value: "A-55" },
      { label: "A-56", value: "A-56" },
      { label: "A-57", value: "A-57" },
      { label: "A-58", value: "A-58" },
      { label: "A-59", value: "A-59" },
      { label: "A-60", value: "A-60" },
      { label: "A-61", value: "A-61" },
      { label: "A-62", value: "A-62" },
      { label: "A-63", value: "A-63" },
      { label: "A-64", value: "A-64" },
      { label: "A-65", value: "A-65" },
      { label: "A-66", value: "A-66" },
      { label: "A-70", value: "A-70" },
      { label: "A-71", value: "A-71" },
      { label: "A-72", value: "A-72" },
      { label: "A-73", value: "A-73" },
      { label: "A-74", value: "A-74" },
      { label: "A-75", value: "A-75" },
      { label: "A-76", value: "A-76" },
      { label: "A-77", value: "A-77" },
      { label: "A-78", value: "A-78" },
      { label: "A-79", value: "A-79" },
      { label: "A-80", value: "A-80" },
      { label: "A-81", value: "A-81" },
      { label: "A-82", value: "A-82" },
      { label: "A-83", value: "A-83" },
      { label: "A-84", value: "A-84" },
      { label: "A-85", value: "A-85" },
      { label: "A-86", value: "A-86" },
      { label: "B-01", value: "B-01" },
      { label: "B-02", value: "B-02" },
      { label: "B-03", value: "B-03" },
      { label: "B-04", value: "B-04" },
      { label: "B-05", value: "B-05" },
      { label: "B-06", value: "B-06" },
      { label: "B-07", value: "B-07" },
      { label: "B-08", value: "B-08" },
      { label: "B-09", value: "B-09" },
      { label: "B-10", value: "B-10" },
      { label: "B-11", value: "B-11" },
      { label: "B-12", value: "B-12" },
      { label: "B-13", value: "B-13" },
      { label: "B-14", value: "B-14" },
      { label: "B-15", value: "B-15" },
      { label: "B-16", value: "B-16" },
      { label: "B-17", value: "B-17" },
      { label: "B-18", value: "B-18" },
      { label: "B-19", value: "B-19" },
      { label: "B-20", value: "B-20" },
      { label: "B-21", value: "B-21" },
      { label: "B-22", value: "B-22" },
      { label: "B-23", value: "B-23" },
      { label: "B-24", value: "B-24" },
      { label: "B-25", value: "B-25" },
      { label: "B-26", value: "B-26" },
      { label: "B-27", value: "B-27" },
      { label: "B-28", value: "B-28" },
      { label: "B-29", value: "B-29" },
      { label: "B-30", value: "B-30" },
      { label: "B-31", value: "B-31" },
      { label: "B-32", value: "B-32" },
      { label: "B-33", value: "B-33" },
    ],
  };

  PAYLOAD_REGION = {
    options: [
      {
        label: "Seleziona Regione",
        value: "",
      },
      {
        label: "Abruzzo",
        value: "Abruzzo",
      },
      {
        label: "Basilicata",
        value: "Basilicata",
      },
      {
        label: "Calabria",
        value: "Calabria",
      },
      {
        label: "Campania",
        value: "Campania",
      },
      {
        label: "Emilia-Romagna",
        value: "Emilia-Romagna",
      },
      {
        label: "Friuli-Venezia Giulia",
        value: "Friuli-Venezia Giulia",
      },
      {
        label: "Lazio",
        value: "Lazio",
      },
      {
        label: "Liguria",
        value: "Liguria",
      },
      {
        label: "Lombardia",
        value: "Lombardia",
      },
      {
        label: "Marche",
        value: "Marche",
      },
      {
        label: "Molise",
        value: "Molise",
      },
      {
        label: "Piemonte",
        value: "Piemonte",
      },
      {
        label: "Puglia",
        value: "Puglia",
      },
      {
        label: "Sardegna",
        value: "Sardegna",
      },
      {
        label: "Sicilia",
        value: "Sicilia",
      },
      {
        label: "Toscana",
        value: "Toscana",
      },
      {
        label: "Trentino-Alto Adige/Südtirol",
        value: "Trentino-Alto Adige/Südtirol",
      },
      {
        label: "Umbria",
        value: "Umbria",
      },
      {
        label: "Valle d'Aosta/Vallée d'Aoste",
        value: "Valle d'Aosta/Vallée d'Aoste",
      },
      {
        label: "Veneto",
        value: "Veneto",
      },
    ],
  };

  emptyFields = {
    name: "",
    surname: "",
    dob: "",
    email: "",
    mobile: "",
    competitionClass: "",
    region: "",
    accept: false,
    type: "5cfu",
  };

  FIELDS_GROUP = [
    [
      {
        id: "name",
        field: "name",
        label: "Nome",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
      {
        id: "surname",
        field: "surname",
        label: "Cognome",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
    ],
    [
      {
        id: "dob",
        field: "dob",
        label: "Data di nascita",
        component: MInput,
        type: "date",
        className: "col-md-6",
      },
    ],
    [
      {
        id: "email",
        field: "email",
        label: "Email",
        component: MInput,
        type: "email",
        className: "col-md-6",
      },
      {
        id: "mobile",
        field: "mobile",
        label: "Cellulare",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
    ],
    [
      {
        id: "competitionClass",
        name: "competitionClass",
        field: "competitionClass",
        label: "Classe di concorso",
        payload: { options: this.PAYLOAD_COMPETITION_CLASS.options },
        component: MSelect,
        className: "col-md-6",
      },
      {
        id: "region",
        field: "region",
        label: "Regione",
        payload: { options: this.PAYLOAD_REGION.options },
        component: MSelect,
        className: "col-md-6",
      },
    ],
    [
      {
        id: "accept",
        field: "accept",
        label:
          "Dichiaro di aver letto la Privacy Policy ai sensi del Regolamento UE 2016/679 (GDPR)",
        component: MCheckbox,
        className: "col-md-12",
      },
    ],
  ];

  ERROR_MESSAGES = {
    name: "Inserisci il tuo nome",
    surname: "Inserisci il tuo cognome",
    email: "Inserisci la tua email",
    dob: "Seleziona la tua data di nascita",
    competitionClass: "Seleziona la classe di concorso",
    region: "Seleziona la regione",
    accept: "Accetta la privacy policy per continuare",
    mobile: "Inserisci il tuo numero di telefono",
  };

  validation = {
    name: (value) => value !== "",
    surname: (value) => value !== "",
    competitionClass: (value) => value !== "",
    region: (value) => value !== "",
    email: (value) => /\S+@\S+\.\S+/.test(value),
    accept: (value) => value !== false,
    mobile: (value) => value !== "",
    dob: (value) => value !== "",
  };

  saveRemote = () => {
    let payload = payloadBuilder(this.state);

    pageService
      .saveContactRequest(payload)
      .then((result) => {
        this.setState({ isSuccess: 200 });
      })
      .catch((errors) => {
        console.log(errors.response.status);
        if (errors.response.status === 422) {
          this.setState({ isSuccess: 422 });
        }
      });
  };

  onCancel = () => {
    this.resetForm();
  };

  render() {
    let { formActive, defaultValues } = this.state;
    const { formValid, code } = this.renderFields(
      this.FIELDS_GROUP,
      defaultValues,
      formActive
    );

    const title =
      "Adesione alla Newsletter sul Corso erogato ai sensi dell’art. 18 del DM 108/22";


    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </Helmet>
        <MSection>
          <Row>
            <Col>
              <div className="text-center">
                <Title>{title}</Title>
              </div>
              {this.state.isSuccess === 0 ? (
                <>
                  <form className="user" id="5cfuform">
                    {code}
                  </form>
                  <div>
                    <p className="form-check-label mt-2">
                      Leggi la{" "}
                      <a
                        href="/page/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        privacy policy
                      </a>
                    </p>{" "}
                  </div>
                  <div className="mt-2">
                    <FormEnabler
                      onSubmit={this.onSubmit}
                      onCancel={this.onCancel}
                      onToggle={this.toggleForm}
                      isFormActive={formActive}
                      disableSave={!formValid}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
              {this.state.isSuccess === 200 ? (
                <div>
                  <p>
                    Gentile Insegnante, <br />
                    grazie per la sua adesione al Percorso formativo erogato da
                    UniCamillus – Università L. R. con Decreto del 28/11/2017
                    pubblicato in Gazzetta Ufficiale n. 3 del 04/01/2018. <br />
                    Nelle prossime settimane, non appena sarà perfezionato il
                    “Percorso di Formazione di 5 CFU - DM 108/2022”, la
                    contatteremo per completare l’iscrizione al Corso.
                  </p>{" "}
                </div>
              ) : (
                ""
              )}
              {this.state.isSuccess === 422 ? (
                <div>
                  <p>
                    Gentile Insegnante, <br />è già presente un'adesione con
                    questa email utilizzata
                  </p>{" "}
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </MSection>
      </>
    );
  }
}