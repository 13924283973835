import React from "react";
import { FormGroup, Label, Input } from "design-react-kit";
import { TITLEREQUEST_STEPS } from "const";
import { networkErrorHelper, titleRequestService } from "libs";
import { ErrorNotification } from "components/misc";

export class TitleRequestStart {

    title = 'INFO';
    iconName = 'it-pa';
    titleRequest = null;
    userProfile = null;
    importToPay = 50;
    user = null;

    setPayload(payload) {
        this.titleRequest = payload.titleRequest;
        this.userProfile = payload.userProfile;
        this.user = payload.user;
    }

    async onNextStep() {

        let canNext= false;
        if (this.importToPay>0){
            // send import to BE and update status
            canNext = await this.updateRemote();
        }else{
            // show alert
            ErrorNotification.render('E\' necessario specificare una opzione di pagamento   ', ' Selezione del pagamento');     
        }

        return canNext;
    }

    async updateRemote() {


        let retCode = false;

        this.titleRequest.currentStep = TITLEREQUEST_STEPS.SIGN;
        this.titleRequest.importToPay = this.importToPay;

        await titleRequestService.updateRequestStep(this.titleRequest)
            .then(() => {

                retCode = true;
            })
            .catch(errors => {
                console.log(errors);
                retCode= false;
                networkErrorHelper.notify(errors);
            });

        return retCode;
    };

    setImport = (importToPay) => {
        console.log(importToPay)
        this.importToPay = importToPay;
    }

    render() {

        return (<>
            <p>Tramite questa procedura è possibile richiedere il certificato finale di conseguimento titolo e pergamena.<br/>
                Tale richiesta  richiede il versamento di 32 euro di bolli virtuali e il pagamento della seguente somma in base alla propria scelta:</p>

            <FormGroup check>
                <Input name='importToPay' type='radio' id='importToPay50' defaultChecked onClick={() => this.setImport(50)} />
                <Label check htmlFor='importToPay50'>
                    <b>euro 50 (Cinquanta/00) con bonifico o carta di credito </b> <br />
                    nel caso in cui si impegna a ritirare la pergamena finale personalmente,
                    o tramite un proprio delegato, presso le sedi che saranno indicate nel calendario che invieremo entro la fine dell'a.s. 2022/2023 (emergenza sanitaria permettendo).
                </Label>
            </FormGroup>
            <hr />
            <FormGroup check>
                <Input name='importToPay' type='radio' id='importToPay70' onClick={() => this.setImport(70)} />
                <Label check htmlFor='importToPay70'>
                    <b>euro 70 (Settanta/00) con bonifico o carta di credito </b> <br />
                    per l'invio della Pergamena finale nei prossimi mesi (mezzo raccomandata 1/corriere express) al proprio indirizzo.
                </Label>
            </FormGroup>
            <hr />
            <p>Per iniziare la procedura clicca sul tasto <b>AVANTI</b></p>
        </>)
    }
}