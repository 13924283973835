import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';

export class LessonPage extends Component {

    render() {

        const resource = this.props.lesson

        return (
            <> 
                <div>{ReactHtmlParser(resource.content)}</div>
            </>
        );
    }
}