import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

import { LinkList, NavItem } from 'design-react-kit';
import { ROUTES } from "const";

export class FooterLessonIndex extends Component {

    getRightURL = (item) => {
        let url = `${ROUTES.LEARNING_LESSON}/${item.id}/${(this.props.rCode) ? this.props.rCode : ''}${(this.props.idModule) ? '/' + this.props.idModule : ''}`;

        if (item.childResourceType === 'quiz') {
            url = ROUTES.LEARNING_QUIZ_MAIN + '/' + item.childResourceId + '/' + item.idQuiz + '/' + this.props.rCode;
        }
        return url
    }//getRightURL

    render() {

        const lessonsIndex = this.props.lessonsIndex;

        return (
            <>
                <div className="it-navfooter-wrapper">
                    <div className="menu-wrapper">
                        <LinkList
                            header={<h3 className="no_toc">ARGOMENTI SEZIONE</h3>}
                            tag="div"
                            wrapperClassName="menu-link-list"
                        >
                            {lessonsIndex.map((item, i) => (
                                <NavItem key={`lessonsIndex-${i}`} tag="li">
                                    <NavLink key={`lessonsIndex-a-${i}`} className="nav-link"
                                        to={this.getRightURL(item)} >
                                        {item.label}
                                    </NavLink>
                                </NavItem>
                            ))}

                            {(this.props.rCode) && <NavItem key={`lessonsIndex-back2course`} tag="li">
                                <NavLink key={`lessonsIndex-a-back2course`} className="nav-link text-danger"
                                    to={`${ROUTES.LEARNING_MAIN}/${this.props.rCode}`} >
                                    torna all'indice del corso
                                </NavLink>
                            </NavItem>}
                        </LinkList>
                    </div>
                </div>
            </>);
    }
}