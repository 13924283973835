import React, { Component } from "react";
import { MnemoLoading, BoxDanger, Title, MSection, SupportoTecnico } from 'components/misc';

import { CourseHelper, enrollmentService, networkErrorHelper } from "libs";
import { withRouter } from "react-router-dom";
import { ENROLLMENT_STEPS, PAYMENT_TYPES } from 'const';
import { Container, Row} from 'design-react-kit';
import { MnemoStepper } from "components/misc/MnemoStepper";

import {
  EnrollStart,
  EnrollFill,
  EnrollSign,
  EnrollPay,
  EnrollEnd,
  EnrollVirtualStamp
} from "components/enrollment/";

import EnrollmentPrerequisites from "./EnrollmentPrerequisites";

const STEP_START = {
  stepName: ENROLLMENT_STEPS.START,
  handler: new EnrollStart(),
  progress: 0
};

const STEP_PAY = {
  stepName: ENROLLMENT_STEPS.PAY,
  handler: new EnrollPay(),
  progress: 1,
};

const STEP_FILL = {
  stepName: ENROLLMENT_STEPS.FILL,
  handler: new EnrollFill(),
  progress: 2,
};

const STEP_SIGN = {
  stepName: ENROLLMENT_STEPS.SIGN,
  handler: new EnrollSign(),
  progress: 3,
  nextButton: 'Completa Firma'
};

const STEP_VIRTUALSTAMP = {
  stepName: ENROLLMENT_STEPS.VIRTUALSTAMP,
  handler: new EnrollVirtualStamp(),
  progress: 4,
};


const STEP_END = {
  stepName: ENROLLMENT_STEPS.END,
  handler: new EnrollEnd(),
  progress: 4
}

class Enrollment extends Component {

  state = {
    loading: true,
    loadingError: false,
    enrollment: null,
    course: null,
    notices: [],
    prevDisabled: true,
    currentProgress: 0,
    steps: []
  };

  componentDidMount() {

    const { orderNumber } = this.props.match.params;
    this.loadRemote(orderNumber);
  }

  getSteps(course, enrollment) {

    const { fillform, code } = course;
    const { importToPay } = enrollment;
    const isDS_II = CourseHelper.isDSII(code);
    const isBU_II = CourseHelper.isBUII(code);
    const isUnicamillus = CourseHelper.isUnicamillus(code);

    let steps = [];
    steps.push(STEP_START);
    let progress = 1;

    if (importToPay > 0 && !(isDS_II  || isBU_II) && enrollment.paymentType !== PAYMENT_TYPES.PAGOPA) {
      steps.push(STEP_PAY);
      progress++;
    }

    if (fillform && fillform.length > 0) {
      STEP_FILL.progress = progress++;
      steps.push(STEP_FILL);
    }

    if ((isDS_II || isBU_II) && enrollment.paymentType !== PAYMENT_TYPES.PAGOPA) {
      STEP_PAY.progress = progress++;
      steps.push(STEP_PAY);
    }

    STEP_SIGN.progress = progress++;
    steps.push(STEP_SIGN);

    if ( enrollment.paymentType === PAYMENT_TYPES.PAGOPA){
      STEP_PAY.progress = progress++;
      steps.push(STEP_PAY);
    }

    if (isUnicamillus && enrollment.paymentType === PAYMENT_TYPES.CARTA_DEL_DOCENTE){
      STEP_VIRTUALSTAMP.progress = progress++;
      steps.push(STEP_VIRTUALSTAMP);
    }

    STEP_END.progress = progress++;
    steps.push(STEP_END);

    return steps;
  }

  loadRemote(orderNumber) {

    enrollmentService
      .myPendingDetail(orderNumber)
      .then(({ data }) => {

        // create steps
        const stepsFrom = this.getSteps(data.payload.course, data.payload.enrollment);

        // get currentProgress
        const items = stepsFrom.filter(item => item.stepName === data.payload.enrollment.currentStep);
        if (!(items && items.length > 0)) {

          this.setState({
            loadingError: true,
            loading: false,
            message: 'si è verificato un errore durante la lettura dei dati della domanda di iscrizione'
          });

        } else {

          // init the steps
          const steps = stepsFrom.map(item => {

            item.handler.setPayload(data.payload);
            return item;
          })

          const currentProgress = items[0].progress;

          this.setState({
            loadingError: false,
            loading: false,
            enrollment: data.payload.enrollment,
            course: data.payload.course,
            user: data.payload.user,
            userProfile: data.payload.userProfile,
            notices: data.payload.notices,
            currentProgress: currentProgress,
            steps
          });
        }
      })
      .catch(error => {

        if (networkErrorHelper.is422(error)) {
          const message = networkErrorHelper.getErrorMessage(error);
          this.setState({ loadingError: true, loading: false, message });
        } else {
          networkErrorHelper.notify(error);
          this.setState({ loadingError: true, loading: false });
        }
      });
  }

  render() {

    const { loading, loadingError, course } = this.state;

    return (
      <>
        {loading ? (<MnemoLoading></MnemoLoading>) : (
          <>
            <Container>
              {loadingError ?
                (<>
                  <Title>Domanda di Iscrizione</Title>
                  <Row className="my-4">
                    <BoxDanger>Si è verificato un errore durante
                      il caricamento della pagina, {this.state.message}, se il problema persiste puoi <SupportoTecnico />
                    </BoxDanger></Row></>)
                : (
                  <>
                    <Title subtitle={`${course.title} ( ${course.code})`} >Domanda di Iscrizione</Title>
                    {(this.state.notices.length > 0) ?
                      (<EnrollmentPrerequisites notices={this.state.notices} />
                      ) : (
                        <><MSection className="mb-4">
                          <MnemoStepper {...this.state} />
                        </MSection>
                        </>
                      )}
                  </>)}
            </Container>
          </>)}
      </>);
  }
}

export default withRouter(Enrollment);