import React from "react";
import { dateHelper } from "libs";
import { PAYMENT_STATUS_LABEL } from "const";

export class EnrollStart {

    title = 'INFO';
    iconName = 'it-pa';

    setPayload(payload) {
        this.enrollment = payload.enrollment;
        this.course = payload.course;
    }

    async onNextStep (){                           
        return true;
    }

    render() {

        const dateStart = dateHelper.toITDateTime(this.enrollment.dateStart);

        const paymentStatus = PAYMENT_STATUS_LABEL[this.enrollment.paymentStatus];
        return (<>
            <p>In seguito sono riportati i dati della tua domanda di iscrizione, per completare premere il tasto <b>AVANTI</b>:</p>
            <ul>
                <li><b>Numero:</b> {this.enrollment.orderNumber}</li>
                <li><b>Data inizio procedura:</b> {dateStart}</li>
                <li><b>Stato della domanda:</b> da completare</li>
                <li><b>Modalità di pagamento: </b> {this.enrollment.paymentType}</li>
                <li><b>Importo: </b> euro {this.enrollment.importToPay}</li>
                <li><b>Stato del pagamento:</b>  {paymentStatus}</li>
                <li><b>Firma: </b> da apporre</li>
            </ul>
            <p>Per completare la procedura premi sul tasto <b>AVANTI</b></p>
        </>);
    }
}