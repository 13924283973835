import React from "react";

import {
    FormEnabler,
    ValidatedForm,
    MInput,
    MTextArea,
    MCE
} from "components/forms";

import {
    payloadBuilder,
    invalidFieldsMapper,
    networkErrorHelper,
    notNullObjectBuilder,
    adminService,
    stringHelper
} from "libs";

import {
    Card,
    CardBody,
} from 'design-react-kit';

import { BoxSuccess } from "components/misc";

export class AdminPage extends ValidatedForm {
    
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: parseInt(this.props.idPage) > 0 ? false : true,
        };

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    //--- ---    

    emptyFields = {
        title: '',
        summary: '',
        content: '',
        slug: ''
    }

    //--- ---

    FIELDS_GROUP = [
        [
            {
                id: "title",
                field: "title",
                label: "titolo",
                component: MInput,
                type: "text",                
                onChange: (_, value) => this.onChangeMakeSlug(_, value), 
                className: "col-md-12"
            }
        ],
        [
            {
                id: "slug",
                field: "slug",
                label: "URL (slug)",
                component: MInput,
                type: "text",
                className: "col-md-10 mt-4"
            },
            {
                id: "version",
                field: "version",
                label: "Versione",
                component: MInput,
                type: "number",
                className: "col-md-2 mt-4"
            }
        ],
        [
            {
                id: "summary",
                field: "summary",
                label: "Descrizione",
                component: MTextArea,
                className: "col-md-12",
                infoText: ""
            },{
                id: "content",
                field: "content",
                label: "Page editor",
                component: MCE,
                className: "col-md-12 mt-4",
                infoText: "Specificare il testo"
            }
        ],
    ];

    ERROR_MESSAGES = {
        title: "Titolo non valido",
        slug: "Slug (URL) non valido"
    };

    validation = {
        title: value => value.length>0 && value.length <= 255,
        slug: value => value.length>0 && value.length <= 255,
    };
    
    //--- ---

    componentDidMount(){
        this.loadRemote();
    }    

    onChangeMakeSlug = (_, value) => {
        const ret = stringHelper.makeSlug(value);
        this.setState({
            slug: ret,
            defaultValues:{
                ...this.state.defaultValues,
                slug: ret
            }
        })
    }

    
    loadRemote(additionalState = {}) {
        //--- prevent loadRemote when not required ---
        if (parseInt(this.props.idPage) <= 0) {
            return false;
        }

        adminService.loadPage(parseInt(this.props.idPage))
            .then(({ data }) => {

                const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.payload.page
                );
                
                const newState = {
                    defaultValues,
                    ...additionalState,
                    loading:false
                };

                this.setState(newState);
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                console.log(error);
            });
    }//loadRemote


    onBackPage = () => {
        window.history.back();
    }//onBackPage


    saveRemote = () => {
        let payload = payloadBuilder(this.state);
        payload.selectedBlogCategory = this.state.selectedBlogCategory;
       
        if (parseInt(this.props.idPage) > 0) {

            adminService.updatePage(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });

        } else {

            adminService.insertPage(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });
        }
    }//saveRemote

    //--- ---

    render() {
        
        if (this.loading){
            return (<></>);
        }

        let { formActive, defaultValues } = this.state;

        if (parseInt(this.props.idPage) === 0) {
            formActive = true;
        }
        
        const { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <CardBody tag="div">
                        <h2>Nota bene:</h2>
                        <BoxSuccess>
                            {`Se il contenuto non viene specificato il sistema mostrerà un file con il nome dello slug e della versione. 
                            Ad esempio per lo slug "privacy-policy" e versione "1" verrà caricato il codice html contenuto nel file "/assets/page/privacy-policy_1.html"`}
                        </BoxSuccess>

                        <hr/>

                        <form className="user mt-5" id="courseform">
                            {code}
                        </form>

                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={parseInt(this.props.idPage) > 0 ? this.onCancel : this.onBackPage}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                        </div>
                    </CardBody>
                </Card>               
            </>
        );
    }
}
